import { Grid, MenuItem, TextField } from "@mui/material";
import Province from "../../../data/province.json";
import District from "../../../data/district.json";
import Subdistrict from "../../../data/subdistrict.json";
import { useEffect, useState } from "react";

const collator = new Intl.Collator("th", { sensitivity: "base" });
Province.sort((a, b) => collator.compare(a.name_th, b.name_th));
District.sort((a, b) => collator.compare(a.name_th, b.name_th));
Subdistrict.sort((a, b) => collator.compare(a.name_th, b.name_th));

interface Props {
  handleInputChange: (e: any) => void;
  hideProvice?: boolean;
  forceProvince?: string;
  forceDistrict?: string;
  name: {
    province: string;
    district: string;
    subdistrict: string;
  };
  xs?: number;
  lg?: number;
}

const AddressAutoFilter = (props: Props) => {
  const {
    xs = 12,
    lg = 4,
    name,
    hideProvice = false,
    forceProvince = "",
    forceDistrict = "",
    handleInputChange,
  } = props;
  const [provinces, setProvinces] = useState<any[]>(Province);
  const [district, setDistrict] = useState<any[]>([]);
  const [subdistrict, setSubdistrict] = useState<any[]>([]);

  useEffect(() => {
    if (hideProvice && forceProvince) {
      const p = Province.find((item: any) => item.name_th == forceProvince);
      if (p) {
        handleInputChange({
          target: {
            name: name.province,
            value: p,
          },
        });
        const a = District.filter(
          (item: any) => item.province_code === p?.code
        );
        setDistrict(a);
        setSubdistrict([]);
        if (forceDistrict) {
          handleDistrict({
            target: {
              name: forceDistrict,
            },
          });
        }
      }
    }
  }, [hideProvice, forceProvince, forceDistrict]);

  const handleProvince = (e: any) => {
    const { value } = e.target;
    const p = Province.find((item: any) => item.name_th == value);
    if (p) {
      handleInputChange(e);
      const a = District.filter((item: any) => item.province_code === p?.code);
      setDistrict(a);
      setSubdistrict([]);
    }
  };

  const handleDistrict = (e: any) => {
    const { value } = e.target;
    const d = district.find((item: any) => item.name_th == value);
    if (d) {
      handleInputChange(e);
      const a = Subdistrict.filter(
        (item: any) => item.district_code === d?.code
      );
      setSubdistrict(a);
    }
  };

  return (
    <>
      {!hideProvice && (
        <Grid item xs={xs} lg={lg}>
          <TextField
            fullWidth
            label={`จังหวัด`}
            onChange={handleProvince}
            name={name.province}
            select
          >
            {provinces.map((item: any) => (
              <MenuItem key={item.code} value={item.name_th}>
                {item.name_th}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}
      <Grid item xs={xs} lg={lg}>
        <TextField
          fullWidth
          label={`อำเภอ`}
          onChange={handleDistrict}
          name={name.district}
          // defaultValue={forceDistrict}
          select
        >
          {district.map((item: any) => {
            return (
              (forceDistrict === item.name_th || forceDistrict === "") && (
                <MenuItem key={`${item.code}-district`} value={item.name_th}>
                  {item.name_th}
                </MenuItem>
              )
            );
          })}
        </TextField>
      </Grid>
      <Grid item xs={xs} lg={lg}>
        <TextField
          fullWidth
          label={`ตำบล`}
          onChange={(e) => handleInputChange(e)}
          name={name.subdistrict}
          select
        >
          {subdistrict.map((item: any) => (
            <MenuItem key={`${item.code}-subdistrict`} value={item.name_th}>
              {item.name_th}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </>
  );
};

export default AddressAutoFilter;
