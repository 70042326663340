import { ReactNode } from "react";
import { Route } from "react-router-dom";
import UserPage from "../app/pages/UserPage";
import Department from "../app/pages/SettingPage/Department";
import Prefix from "../app/pages/SettingPage/Prefix";
import PersonalType from "../app/pages/SettingPage/PersonalType";
import WhiteBox from "../app/pages/SettingPage/WhiteBox";
import Village from "../app/pages/SettingPage/Village";
import Personal from "../app/pages/PersonalPage";
import PersonalDetail from "../app/pages/PersonalPage/Features/PersonalDetail";
import InformPage from "../app/pages/InformPage";
import InformDetail from "../app/pages/InformPage/Features/InformDetail";
import ReportPersonalType from "../app/pages/ReportPage/ReportPersonalType";
import ReportInform from "../app/pages/ReportPage/ReportInform";
import ReportVillage from "../app/pages/ReportPage/ReportVillage";
import ReportWhiteBox from "../app/pages/ReportPage/ReportWhiteBox";
import UserProfile from "../app/pages/UserPage/Features/UserManage/Profile";
import Logs from "../app/pages/SettingPage/Logs";

export type RouteType = {
  element: ReactNode;
  path: string;
};

export const appRoutes: RouteType[] = [
  {
    path: "/users/*",
    element: <UserPage />,
  },
  {
    path: "/profile",
    element: <UserProfile />,
  },
  {
    path: "/inform",
    element: <InformPage />,
  },
  {
    path: "/inform/:id",
    element: <InformDetail />,
  },
  {
    path: "/personal",
    element: <Personal />,
  },
  {
    path: "/personal/:id",
    element: <PersonalDetail />,
  },
  {
    path: "/setting/white-box",
    element: <WhiteBox />,
  },
  {
    path: "/setting/villages",
    element: <Village />,
  },
  {
    path: "/setting/departments",
    element: <Department />,
  },
  {
    path: "/setting/prefix",
    element: <Prefix />,
  },
  {
    path: "/setting/personalType",
    element: <PersonalType />,
  },
  {
    path: "/report/inform",
    element: <ReportInform />,
  },
  {
    path: "/report/personal",
    element: <ReportPersonalType />,
  },
  {
    path: "/report/village",
    element: <ReportVillage />,
  },
  {
    path: "/report/whitebox",
    element: <ReportWhiteBox />,
  },
  {
    path: "/setting/logs",
    element: <Logs />,
  },
];

const generateRoute = (routes: RouteType[]): ReactNode => {
  return routes.map((route, index) => (
    <Route index path={route.path} element={route.element} key={index} />
  ));
};

export const routes: ReactNode = generateRoute(appRoutes);
