import { Box, Card } from "@mui/material";
import TableLists from "./Features/TableLists";
import { useSearch } from "../../../hooks/useSearch";
import {
  fetchLogs,
  FetchLogsInterface,
  useFetchLogs,
} from "../../../hooks/useLogs";
import TablePaginate from "../../../components/TablePaginate";
import { useCallback, useEffect, useState } from "react";

const useLogsById = (id?: string) => {
  const [data, setData] = useState<FetchLogsInterface>({
    data: [],
    count: 0,
    page: 0,
    perPage: 15,
    loading: true,
  });

  const getInfo = useCallback(async () => {
    if (id === undefined) return;
    setData((prev: any) => ({ ...prev, loading: true }));
    try {
      const data = await fetchLogs({ id });
      setData({ ...data });
    } catch (e) {
      console.log(e);
    }
  }, [id]);

  useEffect(() => {
    getInfo();
  }, [id]);

  return data;
};

const LogView = ({ id, dialog }: { id: string; dialog: any }) => {
  const { handleRefresh, handleSetPage, handleSetPerPage } = useSearch();
  const data = useLogsById(id);
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Card>
          <TableLists
            count={data.count}
            items={data.data}
            loading={data.loading}
            onRefresh={handleRefresh}
            page={data.page}
          />
        </Card>
      </Box>
      <Box py={2}>
        <TablePaginate
          handleSetPage={handleSetPage}
          handleSetPerPage={handleSetPerPage}
          count={data.count}
          perPage={data.perPage}
          currentPage={data.page}
        />
      </Box>
    </>
  );
};

export default LogView;
