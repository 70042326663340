import { Grid, MenuItem, TextField } from "@mui/material";
import Province from "../../../data/province.json";
import District from "../../../data/district.json";
import Subdistrict from "../../../data/subdistrict.json";
import { useEffect, useState } from "react";

const collator = new Intl.Collator("th", { sensitivity: "base" });
Province.sort((a, b) => collator.compare(a.name_th, b.name_th));
District.sort((a, b) => collator.compare(a.name_th, b.name_th));
Subdistrict.sort((a, b) => collator.compare(a.name_th, b.name_th));

interface Props {
  formik: any;
  loading?: boolean;
  disabled?: boolean;
  viewOnly?: boolean;
  xs?: number;
  lg?: number;
  name: {
    province: string;
    district: string;
    subdistrict: string;
  };
  value?: {
    province: string | undefined;
    district: string | undefined;
    subdistrict: string | undefined;
  };
  touched?: {
    province: boolean | undefined;
    district: boolean | undefined;
    subdistrict: boolean | undefined;
  };
  errors?: {
    province: string | undefined;
    district: string | undefined;
    subdistrict: string | undefined;
  };
}

const AddressAuto = (props: Props) => {
  const {
    formik,
    loading,
    xs = 12,
    lg = 4,
    name,
    disabled = false,
    touched,
    errors,
    value,
    viewOnly = false,
  } = props;
  const [provinces, setProvinces] = useState<any[]>(Province);
  const [district, setDistrict] = useState<any[]>([]);
  const [subdistrict, setSubdistrict] = useState<any[]>([]);
  const [firstLoading, setFirstLoading] = useState<boolean>(true);

  useEffect(() => {
    if (value?.province) {
      handleProvince({ target: { value: value?.province } }, value?.district);
    }
  }, [value?.province]);

  useEffect(() => {
    if (firstLoading) {
      setFirstLoading(false);
      handleProvince({ target: { value: value?.province } });

      const d = District.find((item: any) => item.name_th == value?.district);
      if (d) {
        formik.setFieldValue(name.district, d?.name_th);
        const a = Subdistrict.filter(
          (item: any) => item.district_code === d?.code
        );
        setSubdistrict(a);
      }
    }
  }, [firstLoading]);

  const handleProvince = (e: any, selectDistrict: string = "") => {
    const { value } = e.target;
    const p = Province.find((item: any) => item.name_th == value);
    if (p) {
      formik.setFieldValue(name.province, p?.name_th);
      const a = District.filter((item: any) => item.province_code === p?.code);
      setDistrict(a);
      if (!selectDistrict) {
        setSubdistrict([]);
      } else {
        handleDistrict({ target: { value: selectDistrict } }, a);
      }
    }
  };

  const handleDistrict = (e: any, fdistrict: any[] = []) => {
    const { value } = e.target;
    let d = district.find((item: any) => item.name_th == value);

    if (!d && fdistrict) {
      d = fdistrict.find((item: any) => item.name_th == value);
    }

    if (d) {
      formik.setFieldValue(name.district, d?.name_th);
      const a = Subdistrict.filter(
        (item: any) => item.district_code === d?.code
      );
      setSubdistrict(a);
    }
  };

  return (
    <>
      <Grid item xs={xs} lg={lg}>
        <TextField
          className={viewOnly ? "text-label" : ""}
          InputProps={{
            readOnly: viewOnly,
          }}
          fullWidth
          disabled={disabled}
          value={value?.province}
          label={`จังหวัด`}
          onChange={handleProvince}
          name={name.province}
          error={touched?.province && Boolean(errors?.province)}
          helperText={touched?.province && errors?.province}
          select
        >
          {provinces.map((item: any) => (
            <MenuItem key={item.code} value={item.name_th}>
              {item.name_th}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={xs} lg={lg}>
        <TextField
          className={viewOnly ? "text-label" : ""}
          InputProps={{
            readOnly: viewOnly,
          }}
          fullWidth
          disabled={disabled}
          value={value?.district}
          label={`อำเภอ`}
          onChange={handleDistrict}
          name={name.district}
          error={touched?.district && Boolean(errors?.district)}
          helperText={touched?.district && errors?.district}
          select
        >
          {district.map((item: any) => (
            <MenuItem key={`${item.code}-district`} value={item.name_th}>
              {item.name_th}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={xs} lg={lg}>
        <TextField
          className={viewOnly ? "text-label" : ""}
          InputProps={{
            readOnly: viewOnly,
          }}
          fullWidth
          disabled={disabled}
          value={value?.subdistrict}
          label={`ตำบล`}
          onChange={formik.handleChange}
          name={name.subdistrict}
          error={touched?.subdistrict && Boolean(errors?.subdistrict)}
          helperText={touched?.subdistrict && errors?.subdistrict}
          select
        >
          {subdistrict.map((item: any) => (
            <MenuItem key={`${item.code}-subdistrict`} value={item.name_th}>
              {item.name_th}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </>
  );
};

export default AddressAuto;
