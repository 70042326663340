import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Modal } from "antd";
import toast from "react-hot-toast";
import Edit02Icon from "@untitled-ui/icons-react/build/esm/Edit02";

import { useDialog } from "../../../../hooks/useDialog";
import request from "../../../../../utils/request";
import { Scrollbar } from "../../../../components/SimpleBar";
import { TableProps } from "../../../../../interface";
import { FiCheck, FiList, FiTrash2 } from "react-icons/fi";
import { useContext, useState } from "react";
import CustomDialog from "../../../../components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import Form from "../../Conmponents/Modal/Form";
import { PersonalInterface } from "../../../../../types/personal";
import FormViolateType from "../../Conmponents/Modal/Form/FormViolateType";
import { Link } from "react-router-dom";
import ArrowRightIcon from "@untitled-ui/icons-react/build/esm/ArrowRight";
import { AbilityContext } from "../../../../components/Ability";
import LogsViewIconButton from "../../../../components/LogsViewIconButton";

const TableLists = (props: TableProps) => {
  const ability = useContext(AbilityContext);
  const {
    items = [],
    onRefresh = () => {},
    loading = false,
    editable = true,
  } = props;
  const [current, setCurrent] = useState<PersonalInterface>();

  const dialogUpdated = useDialog();
  const dialogDeleted = useDialog();
  const dialogQrcode = useDialog();
  const dialogViolateForm = useDialog();

  const onDeleted = async () => {
    if (typeof current !== `undefined`) {
      try {
        await request({
          url: `/api/personal/${current._id}`,
          method: `DELETE`,
        });
        toast.success(`ลบข้อมูลสำเร็จ`);
        dialogDeleted.handleClose();
        onRefresh();
      } catch (e: any) {
        Modal.error({
          title: "เกิดข้อผิดพลาด",
          content: e.response?.data?.message ?? `Internal Server Error`,
        });
      }
    }
  };

  const handleOpenModal = (item: PersonalInterface) => {
    setCurrent(item);
    dialogUpdated.handleOpen({ open: true });
  };

  const handleOpenModalDeleted = (item: PersonalInterface) => {
    setCurrent(item);
    dialogDeleted.handleOpen({ open: true });
  };

  const handleOpenFormViolate = (item: PersonalInterface) => {
    setCurrent(item);
    dialogViolateForm.handleOpen({ open: true });
  };

  return (
    <Box sx={{ position: "relative" }}>
      <CustomDialog
        size={"md"}
        title="แก้ไขข้อมูล"
        onClose={dialogUpdated.handleClose}
        open={dialogUpdated.open}
      >
        <Form dialog={dialogUpdated} data={current} onRefresh={onRefresh} />
      </CustomDialog>
      <CustomDialog
        size={"sm"}
        title="ข้อมูลความประพฤติ"
        onClose={dialogViolateForm.handleClose}
        open={dialogViolateForm.open}
      >
        <FormViolateType
          dialog={dialogViolateForm}
          data={current}
          onRefresh={onRefresh}
        />
      </CustomDialog>
      <CustomDialog
        size={"xs"}
        title="แจ้งเตือน"
        onClose={dialogDeleted.handleClose}
        open={dialogDeleted.open}
      >
        <Typography variant={`subtitle2`}>ยืนยันการลบรายการ</Typography>

        <Box display="flex" justifyContent={`end`}>
          <LoadingButton
            onClick={() => onDeleted()}
            variant="contained"
            startIcon={<FiCheck />}
          >
            ยืนยัน
          </LoadingButton>
        </Box>
      </CustomDialog>

      <Scrollbar>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <TableCell width={150} align="center">
                เลขบัตรปชช
              </TableCell>
              <TableCell width={200} sx={{ p: 2 }}>
                ชื่อ-นามสกุล
              </TableCell>

              <TableCell align="left">ที่อยู่</TableCell>
              {editable && (
                <TableCell align="center" width={200}>
                  Actions
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                  <Typography
                    sx={{
                      display: `flex`,
                      alignItems: `center`,
                      justifyContent: `center`,
                    }}
                    variant="subtitle2"
                  >
                    <CircularProgress
                      color="success"
                      size={32}
                      sx={{ mr: 2 }}
                    />{" "}
                    กำลังโหลดข้อมูล...
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              items.map((item) => {
                const location = `${
                  item?.addressRegistration?.address ?? ""
                } หมู่บ้าน ${item?.addressCurrentVillage?.name ?? "-"}  ต.${
                  item?.addressRegistration?.subdistrict ?? "-"
                } อ.${item?.addressRegistration?.district ?? "-"} จ.${
                  item?.addressRegistration?.province ?? "-"
                }`;
                return (
                  <TableRow hover key={item._id}>
                    <TableCell align={`center`}>{item?.taxId}</TableCell>
                    <TableCell>
                      {item?.firstName} {item?.lastName}
                    </TableCell>
                    <TableCell>{location}</TableCell>
                    {editable && (
                      <TableCell align="center">
                        {ability.can("edit", "userInfo") && (
                          <>
                            <IconButton onClick={() => handleOpenModal(item)}>
                              <SvgIcon>
                                <Edit02Icon />
                              </SvgIcon>
                            </IconButton>
                            <IconButton
                              onClick={() => handleOpenFormViolate(item)}
                            >
                              <SvgIcon>
                                <FiList />
                              </SvgIcon>
                            </IconButton>
                          </>
                        )}
                        {ability.can("deleted", "userInfo") && (
                          <IconButton
                            onClick={() => handleOpenModalDeleted(item)}
                          >
                            <SvgIcon>
                              <FiTrash2 />
                            </SvgIcon>
                          </IconButton>
                        )}
                        {ability.can("read", "userInfo") && (
                          <IconButton
                            component={Link}
                            to={`/personal/${item._id}`}
                          >
                            <SvgIcon>
                              <ArrowRightIcon />
                            </SvgIcon>
                          </IconButton>
                        )}
                        <LogsViewIconButton id={item._id} />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </Scrollbar>
    </Box>
  );
};

export default TableLists;
