import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { getInitials } from "../../../../../utils/firstChar";
import PageHeader from "../../../../components/PageHeader";
import { Link } from "react-router-dom";
import { FiChevronDown, FiEdit, FiEdit2, FiSave } from "react-icons/fi";
import { Helmet } from "react-helmet-async";
import { PropertyList } from "../../../../components/PropertyList";
import { PropertyListItem } from "../../../../components/PropertyList/PropertyListItem";

const UserDetails = () => {
  return (
    <>
      <PageHeader
        title={`รายละเอียดผู้ใช้งาน`}
        routes={[
          { title: `รายชื่อผู้ใช้งาน`, path: "/users" },
          { title: `รายละเอียด` },
        ]}
        back={{
          title: "รายชื่อผู้ใช้งาน",
          path: "/users",
        }}
        hideHeader={true}
      />

      <Stack
        alignItems="flex-start"
        direction={{
          xs: "column",
          md: "row",
        }}
        justifyContent="space-between"
        spacing={4}
      >
        <Stack alignItems="center" direction="row" spacing={2}>
          <Avatar
            src={""}
            sx={{
              height: 64,
              width: 64,
            }}
          >
            {getInitials("Admin")}
          </Avatar>
          <Stack spacing={1}>
            <Typography variant="h4">{"ADMIN"}</Typography>
            <Stack alignItems="center" direction="row" spacing={1}>
              <Typography variant="subtitle2">Email:</Typography>
              <Chip label={"test@gmail.com"} size="small" />
            </Stack>
          </Stack>
        </Stack>
        <Stack alignItems="center" direction="row" spacing={2}>
          <Button
            variant={"contained"}
            color="primary"
            startIcon={
              <SvgIcon>
                <FiEdit2 />
              </SvgIcon>
            }
          >
            แก้ไขข้อมูล
          </Button>
        </Stack>
      </Stack>

      <Box flexGrow={1}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={4}>
            <Card>
              <CardHeader title="Basic Details" />
              <PropertyList>
                <PropertyListItem
                  divider
                  label="Email"
                  value={"text@gmail.com"}
                />
                <PropertyListItem
                  divider
                  label="Phone"
                  value={"089-999-9999"}
                />
                <PropertyListItem divider label="Country" value={"Thailand"} />
                <PropertyListItem
                  divider
                  label="State/Region"
                  value={"Bangkok"}
                />
                <PropertyListItem divider label="Address 1" value={"123/456"} />
                <PropertyListItem divider label="Address 2" value={"789/123"} />
              </PropertyList>
              <CardActions>
                <Button color="inherit" size="small">
                  Reset Password
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Card>
              <CardHeader title="Data Management" />
              <CardContent sx={{ pt: 0 }}>
                <Button color="error" variant="outlined">
                  Delete Account
                </Button>
                <Box sx={{ mt: 1 }}>
                  <Typography color="text.secondary" variant="body2">
                    Remove this customer’s chart if he requested that, if not
                    please be aware that what has been deleted can never brought
                    back
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default UserDetails;
