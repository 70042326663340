// const ability = useContext(AbilityContext);

export const USER_ROLES = [
  { code: `master`, name: "ผู้ดูแลระบบ" },
  { code: `operational-staff`, name: `เจ้าหน้าที่ปฏิบัติงาน` },
  { code: `middle-management`, name: `ผู้บริหารระดับกลาง` },
  { code: `top-management`, name: `ผู้บริหารระดับสูง` },
  {
    code: `center-operational-staff`,
    name: `เจ้าหน้าที่ปฏิบัติงาน (ส่วนกลาง)`,
  },
  { code: `center-middle-management`, name: `ผู้บริหารระดับกลาง  (ส่วนกลาง)` },
  { code: `center-top-management`, name: `ผู้บริหารระดับสูง  (ส่วนกลาง)` },
  { code: `provincial-governor`, name: `ผู้บริหารจังหวัด` },
];

export const moduleConfig = {
  informInfo: {
    read: [
      "operational-staff",
      "middle-management",
      "top-management",
      "center-operational-staff",
      "center-middle-management",
      "center-top-management",
      "provincial-governor",
    ],
    create: [
      "operational-staff",
      "middle-management",
      "center-operational-staff",
      "center-middle-management",
    ],
    edit: [
      "operational-staff",
      "middle-management",
      "center-operational-staff",
      "center-middle-management",
    ],
    deleted: [],
  },
  userInfo: {
    read: [
      "operational-staff",
      "middle-management",
      "top-management",
      "center-operational-staff",
      "center-middle-management",
      "center-top-management",
      "provincial-governor",
    ],
    create: ["operational-staff", "center-operational-staff"],
    edit: ["operational-staff", "center-operational-staff"],
    deleted: ["operational-staff", "center-operational-staff"],
  },
  villageInfo: {
    read: [
      "operational-staff",
      "middle-management",
      "top-management",
      "center-operational-staff",
      "center-middle-management",
      "center-top-management",
      "provincial-governor",
    ],
    create: ["operational-staff", "center-operational-staff"],
    edit: ["operational-staff", "center-operational-staff"],
    deleted: ["operational-staff", "center-operational-staff"],
  },
  whiteboxInfo: {
    read: [
      "operational-staff",
      "middle-management",
      "top-management",
      "center-operational-staff",
      "center-middle-management",
      "center-top-management",
      "provincial-governor",
    ],
    create: ["operational-staff", "center-operational-staff"],
    edit: ["operational-staff", "center-operational-staff"],
    deleted: ["operational-staff", "center-operational-staff"],
  },
  report: {
    read: [
      "operational-staff",
      "middle-management",
      "top-management",
      "center-operational-staff",
      "center-middle-management",
      "center-top-management",
      "provincial-governor",
    ],
    create: [],
    edit: [],
    deleted: [],
  },
  userManagement: {
    read: ["provincial-governor"],
    create: [],
    edit: [],
    deleted: [],
  },
  setting: {
    read: [],
    create: [],
    edit: [],
    deleted: [],
  },
};
