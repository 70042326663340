import * as Yup from "yup";
import { useFormik } from "formik";
import { Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Save } from "@mui/icons-material";
import request from "../../../../../../../utils/request";
import toast from "react-hot-toast";
import { Modal } from "antd";
import { errorMessageTranform } from "../../../../../../../utils/error";
import { PrefixInterface } from "../../../../../../../types/prefix";

interface Props {
  data?: PrefixInterface;
  loading?: boolean;
  onRefresh: () => void;
  dialog: any;
}

const Form = (props: Props) => {
  const { data, loading, onRefresh, dialog } = props;

  const initialValues = {
    name: data?.name ?? "",
  };

  let yupObject = {
    name: Yup.string().required(`กรุณากรอกชื่อ`),
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object(yupObject),
    onSubmit: async (values) => {
      let options = {
        url: "/api/setting/prefix",
        method: "POST",
        data: values,
      };
      if (typeof data?._id !== "undefined") {
        options.method = "PUT";
        options.url = `/api/setting/prefix/${data?._id}`;
      }
      try {
        await request(options);
        toast.success("บันทึกข้อมูลสำเร็จ");
        onRefresh();
        dialog.handleClose();
      } catch (e: any) {
        Modal.error({
          title: "เกิดข้อผิดพลาด",
          content: errorMessageTranform(
            e?.response?.data?.errors ?? e.response?.data?.message ?? e.message
          ),
        });
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container mt={0} spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            disabled={formik.isSubmitting || loading}
            name="name"
            label="ชื่อคำนำหน้า"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            loading={formik.isSubmitting || loading}
            disabled={formik.isSubmitting || loading}
            type={"submit"}
            size={`small`}
            variant={`contained`}
            color={`primary`}
            startIcon={<Save />}
          >
            บันทึก
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
