import { useContext } from "react";
import { AbilityContext } from "../../components/Ability";
import { Route, Routes } from "react-router-dom";
import UserLists from "./Features/UserLists";
import PageNotFound from "../../components/PageNotFound";
import UserManage from "./Features/UserManage";
import UserDetails from "./Features/UserDetails";

const UserPage = () => {
  const ability = useContext(AbilityContext);

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            ability.can("read", "userManagement") ? (
              <UserLists />
            ) : (
              <PageNotFound />
            )
          }
        />
        <Route
          path="/create"
          element={
            ability.can("create", "userManagement") ? (
              <UserManage />
            ) : (
              <PageNotFound />
            )
          }
        />
        <Route
          path="/:id"
          element={
            ability.can("read", "userManagement") ? (
              <UserDetails />
            ) : (
              <PageNotFound />
            )
          }
        />
        <Route
          path="/:id/edit"
          element={
            ability.can("edit", "userManagement") ? (
              <UserManage />
            ) : (
              <PageNotFound />
            )
          }
        />
      </Routes>
      {/*<Outlet/>*/}
    </>
  );
};
export default UserPage;
