import request from "../../utils/request";
import { useCallback, useEffect, useState } from "react";
import { WhiteBoxInterface } from "../../types/whiteBox";

export const useFetchWhiteBox = (search = {}) => {
  const [data, setData] = useState<{
    data: WhiteBoxInterface[];
    count: number;
    page: number;
    perPage: number;
    loading: boolean;
  }>({
    data: [],
    count: 0,
    page: 0,
    perPage: 0,
    loading: true,
  });

  const fetch = useCallback(async () => {
    setData((prev) => ({ ...prev, loading: true }));
    try {
      const response = await request({
        url: "/api/white-box",
        method: "GET",
        params: search,
      });
      setData({
        data: response?.data ?? response ?? [],
        count: response.total || 0,
        page: response?.currentPage ?? 0,
        perPage: response?.perPage ?? 15,
        loading: false,
      });
    } catch (e) {}
  }, [search]);

  useEffect(() => {
    fetch();
  }, [search]);

  return data;
};

export const useWhiteBox = (id: string | undefined) => {
  const [data, setData] = useState<{
    data: WhiteBoxInterface | null;
    loading: boolean;
  }>({
    data: null,
    loading: true,
  });

  const get = useCallback(async () => {
    setData((prev) => ({ ...prev, loading: true }));
    try {
      const response = await request({
        url: `/api/white-box/${id}`,
        method: "GET",
      });
      setData({
        data: response?.data ?? response ?? [],
        loading: false,
      });
    } catch (e) {}
  }, [id]);

  useEffect(() => {
    get();
  }, [id]);

  return data;
};
