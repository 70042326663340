import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const SimpleBarChart = ({ data, keys = [] }: { data: any[]; keys?: any[] }) => {
  return (
    <ResponsiveContainer height={300} width="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        {/*<Legend />*/}
        <Bar dataKey="count" label={`sssss`}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color || ``}>
              <Label value={entry.name} />
            </Cell>
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SimpleBarChart;
