import PageHeader from "../../../../components/PageHeader";
import { useParams } from "react-router-dom";
import { usePersonal } from "../../../../hooks/usePersonal";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import MapMarker from "../../../../components/MapMarker";
import { Scrollbar } from "../../../../components/SimpleBar";
import { FiCheck, FiPlus, FiTrash2 } from "react-icons/fi";
import { useDialog } from "../../../../hooks/useDialog";
import CustomDialog from "../../../../components/CustomDialog";
import FormViolateType from "../../Conmponents/Modal/Form/FormViolateType";
import { useContext, useState } from "react";
import moment from "moment";
import { PrefixInterface } from "../../../../../types/prefix";
import { LoadingButton } from "@mui/lab";
import request from "../../../../../utils/request";
import toast from "react-hot-toast";
import { Modal } from "antd";
import { PersonalViolateInterface } from "../../../../../types/personal";
import CustomSkeleton from "../../../../components/Skeleton";
import { AbilityContext } from "../../../../components/Ability";

const Index = () => {
  const ability = useContext(AbilityContext);
  const { id } = useParams();
  const [refresh, setRefresh] = useState<number | null>(null);
  const [current, setCurrent] = useState<PersonalViolateInterface | null>();
  const { data, loading } = usePersonal(id, refresh);

  const dialog = useDialog();
  const dialogDeleted = useDialog();

  const onRefresh = () => {
    setRefresh(moment().unix());
  };

  const handleOpenModalDeleted = (item: PersonalViolateInterface) => {
    setCurrent(item);
    dialogDeleted.handleOpen({ open: true });
  };

  const onDeleted = async () => {
    try {
      await request({
        url: `/api/personal/violate/${current?._id}`,
        method: `DELETE`,
      });
      toast.success(`ลบข้อมูลสำเร็จ`);
      dialogDeleted.handleClose();
      onRefresh();
    } catch (e: any) {
      Modal.error({
        title: "เกิดข้อผิดพลาด",
        content: e.response?.data?.message ?? `Internal Server Error`,
      });
    }
  };

  return (
    <>
      <CustomDialog
        size={"xs"}
        title="แจ้งเตือน"
        onClose={dialogDeleted.handleClose}
        open={dialogDeleted.open}
      >
        <Typography variant={`subtitle2`}>ยืนยันการลบรายการ</Typography>

        <Box display="flex" justifyContent={`end`}>
          <LoadingButton
            onClick={() => onDeleted()}
            variant="contained"
            startIcon={<FiCheck />}
          >
            ยืนยัน
          </LoadingButton>
        </Box>
      </CustomDialog>
      <CustomDialog
        size={"sm"}
        title="ข้อมูลความประพฤติ"
        onClose={dialog.handleClose}
        open={dialog.open}
      >
        <FormViolateType dialog={dialog} data={data} onRefresh={onRefresh} />
      </CustomDialog>

      <PageHeader
        title={`รายละเอียดข้อมูลบุคคล`}
        routes={[
          { title: `รายชื่อข้อมูลบุคคล`, path: "/personal" },
          { title: `รายละเอียด` },
        ]}
        back={{
          title: "รายชื่อข้อมูลบุคคล",
          path: "/personal",
        }}
      />

      <Box flexGrow={1}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={3}>
            {loading ? (
              <CustomSkeleton count={10} loading={loading} />
            ) : (
              <img src={data?.image} width="100%" />
            )}
          </Grid>
          <Grid item xs={12} lg={9}>
            <Card sx={{ mb: 4 }}>
              <CardHeader title="ข้อมูลบุคคล" />
              <CardContent sx={{ pt: 2 }}>
                <Box flexGrow={1}>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      alignItems="center"
                      lg={4}
                    >
                      <Typography variant="subtitle1" gutterBottom>
                        ID Card :{data?.taxId}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Typography variant="subtitle1" gutterBottom>
                        ชื่อ-นามสกุล : {data?.firstName} {data?.lastName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Typography variant="subtitle1" gutterBottom>
                        ชื่อเล่น : {data?.nickName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Typography variant="subtitle1" gutterBottom>
                        เพศ : {data?.gender === "male" ? "ชาย" : "หญิง"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Typography variant="subtitle1" gutterBottom>
                        เกิดเมื่อ :{" "}
                        {moment(data?.birthDate)
                          .add(543, "year")
                          .format("YYYY-MM-DD")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Typography variant="subtitle1" gutterBottom>
                        ประเภทบุคคล : {data?.personalTypeName?.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Typography variant="subtitle1" gutterBottom>
                        สัญชาติ : {data?.nationality}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
            <Card sx={{ mb: 4 }}>
              <CardHeader title="ข้อมูลที่อยู่" />
              <CardContent sx={{ pt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={5}>
                    {typeof data?.addressPosition?.latitude !== "undefined" && (
                      <MapMarker
                        showMapOnly={true}
                        height={350}
                        position={{
                          lat: data?.addressPosition?.latitude,
                          lng: data?.addressPosition?.longitude,
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} lg={7}>
                    <Box flexGrow={1} sx={{ mb: 2 }}>
                      <Typography variant={`h6`} sx={{ mb: 1 }}>
                        ที่อยู่ตามทะเบียนราษฏ์
                      </Typography>
                      <Grid container spacing={1}>
                        <Grid item xs={12} lg={4}>
                          <Typography variant="subtitle1" gutterBottom>
                            ที่อยู่ : {data?.addressRegistration?.address ?? ""}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                          <Typography variant="subtitle1" gutterBottom>
                            ตำบล :{" "}
                            {data?.addressRegistration?.subdistrict ?? ""}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                          <Typography variant="subtitle1" gutterBottom>
                            อำเภอ : {data?.addressRegistration?.district ?? ""}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                          <Typography variant="subtitle1" gutterBottom>
                            จังหวัด :{" "}
                            {data?.addressRegistration?.province ?? ""}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box flexGrow={1} sx={{ mb: 2 }}>
                      <Typography variant={`h6`} sx={{ mb: 1 }}>
                        ที่อยู่ปัจจุบัน
                      </Typography>
                      <Grid container spacing={1}>
                        <Grid item xs={12} lg={4}>
                          <Typography variant="subtitle1" gutterBottom>
                            ที่อยู่ : {data?.addressCurrent?.address ?? ""}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                          <Typography variant="subtitle1" gutterBottom>
                            ตำบล : {data?.addressCurrent?.subdistrict ?? ""}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                          <Typography variant="subtitle1" gutterBottom>
                            อำเภอ : {data?.addressCurrent?.district ?? ""}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                          <Typography variant="subtitle1" gutterBottom>
                            จังหวัด : {data?.addressCurrent?.province ?? ""}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box flexGrow={1} sx={{ mb: 2 }}>
                      <Typography variant={`h6`} sx={{ mb: 1 }}>
                        พิกัดตำแหน่ง
                      </Typography>
                      <Grid container spacing={1}>
                        <Grid item xs={12} lg={6}>
                          <Typography variant="subtitle1" gutterBottom>
                            ละติจูด : {data?.addressPosition?.latitude ?? ""}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <Typography variant="subtitle1" gutterBottom>
                            ลองติจูด : {data?.addressPosition?.longitude ?? ""}
                          </Typography>
                        </Grid>
                        {data?.addressPosition?.googleMapUrl && (
                          <Grid item xs={12}>
                            <Typography
                              display="flex"
                              alignItems="center"
                              variant="subtitle1"
                            >
                              Map :
                              <Typography
                                sx={{ pl: 1 }}
                                variant="subtitle2"
                                fontSize={14}
                                color={`primary`}
                              >
                                <a
                                  href={
                                    data?.addressPosition?.googleMapUrl ?? ""
                                  }
                                >
                                  {data?.addressPosition?.googleMapUrl ?? ""}
                                </a>
                              </Typography>
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card sx={{ mb: 4 }}>
              <CardHeader
                title="ข้อมูลการกระทำผิด"
                action={
                  <>
                    {ability.can("create", "userInfo") && (
                      <>
                        <Button
                          variant={`contained`}
                          startIcon={<FiPlus />}
                          onClick={() => dialog.handleOpen({ open: true })}
                        >
                          เพิ่มข้อมูลใหม่
                        </Button>
                      </>
                    )}
                  </>
                }
              />
              <CardContent sx={{ pt: 2 }}>
                <Scrollbar>
                  <Table sx={{ minWidth: 700 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ p: 2 }}>วันที่</TableCell>
                        <TableCell>แหล่งที่มา</TableCell>
                        <TableCell>ความผิด</TableCell>
                        <TableCell>พฤติกรรม</TableCell>
                        <TableCell>ประเภท</TableCell>
                        <TableCell>ต้องโทษ</TableCell>
                        <TableCell>การยืนยัน</TableCell>
                        <TableCell align="center" width={100}>
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loading ? (
                        <TableRow>
                          <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                            <Typography
                              sx={{
                                display: `flex`,
                                alignItems: `center`,
                                justifyContent: `center`,
                              }}
                              variant="subtitle2"
                            >
                              <CircularProgress
                                color="success"
                                size={32}
                                sx={{ mr: 2 }}
                              />{" "}
                              กำลังโหลดข้อมูล...
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ) : typeof data?.violates !== `undefined` &&
                        data?.violates?.length < 1 ? (
                        <TableRow>
                          <TableCell colSpan={8} align="center">
                            ไม่พบข้อมูล
                          </TableCell>
                        </TableRow>
                      ) : (
                        data?.violates.map((violate) => {
                          return (
                            <TableRow key={violate._id}>
                              <TableCell>{violate.date}</TableCell>
                              <TableCell>
                                {violate?.department?.name ?? ""}
                              </TableCell>
                              <TableCell>{violate?.violate}</TableCell>
                              <TableCell>{violate.behavior}</TableCell>
                              <TableCell>
                                {violate?.violateTypeName?.name ?? ""}
                              </TableCell>
                              <TableCell>
                                {violate?.jailed ? `ใช่` : `ไม่ใช่`}
                              </TableCell>
                              <TableCell>
                                {violate?.confirm ? `ใช่` : `ไม่ใช่`}
                              </TableCell>
                              <TableCell align="center">
                                {ability.can("deleted", "userInfo") && (
                                  <IconButton
                                    onClick={() =>
                                      handleOpenModalDeleted(violate)
                                    }
                                  >
                                    <SvgIcon>
                                      <FiTrash2 />
                                    </SvgIcon>
                                  </IconButton>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      )}
                    </TableBody>
                  </Table>
                </Scrollbar>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Index;
