import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface Props {
  loading?: boolean;
  count?: number;
}

const CustomSkeleton = (prop: Props) => {
  const { loading, count = 1 } = prop;
  return <>{loading ? <Skeleton count={count} /> : ``}</>;
};

export default CustomSkeleton;
