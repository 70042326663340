import request from "../../utils/request";
import { useCallback, useEffect, useState } from "react";
import { PrefixInterface } from "../../types/prefix";

export const useFetchPrefixes = (search = {}) => {
  const [data, setData] = useState<{
    data: PrefixInterface[];
    count: number;
    page: number;
    loading: boolean;
  }>({
    data: [],
    count: 0,
    page: 0,
    loading: true,
  });

  const fetch = useCallback(async () => {
    setData((prev) => ({...prev, loading: true}));
    try {
      const response = await request({
        url: "/api/setting/prefix",
        method: "GET",
        params: search,
      });
      setData({
        data: response?.data ?? response ?? [],
        count: response.total || 0,
        page: response?.currentPage ?? 0,
        loading: false,
      });
    } catch (e) {}
  }, [search]);

  useEffect(() => {
    fetch();
  }, [search]);

  return data;
};
