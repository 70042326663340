import { SeverityPill } from "../../../../components/ServerityPill";
import { INFORM_STATUS } from "../../../../constants";

interface Props {
  status: string;
}

const StatusIcon = (props: Props) => {
  const { status } = props;
  const current = INFORM_STATUS.find((item) => item.code === status);
  if (current) {
    return <SeverityPill color={current?.color}>{current?.name}</SeverityPill>;
  }
  return <></>;
};

export default StatusIcon;
