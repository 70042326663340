import store from "store2";
import { merge } from "lodash";
import axios from "axios";

export class ResponseError extends Error {
  public response: Response;

  constructor(response: Response) {
    super(response.statusText);
    this.response = response;
  }
}

function parseJSON(response: any) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response;
}

function checkStatus(response: any) {
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }

  const error = new ResponseError(response.statusText);
  error.response = response.message;
  throw error;
}

export function withAuthenticationHeader(headers = {}) {
  const jwtToken = store.get("jwtToken");
  return merge(headers, {
    Authorization: `Bearer ${jwtToken}`,
  });
}

export const fetcher = async (url: string) => {
  try {
    const response = await axios.get(url, {
      headers: { "Cache-Control": "no-cache" },
    });
    return response.data;
  } catch (e: any) {
    throw new Error(e);
  }
};

export const fetcherWithAuthentication = async (url: string) => {
  try {
    const response = await axios.get(url, {
      headers: withAuthenticationHeader({ "Cache-Control": "no-cache" }),
    });
    return response.data;
  } catch (e: any) {
    return Promise.reject(e.response);
  }
};

export default async function request(
  options: any
): Promise<any | { err: ResponseError }> {
  const fetchResponse = await axios(options);
  const response = await checkStatus(fetchResponse);
  return parseJSON(response);
}
