import { useState } from "react";
import { MuiFileInput } from "mui-file-input";
import { Stack } from "@mui/material";

interface Props {
  name: string;
  formik?: any;
  placeholder?: string;
}

const FileInput = (props: Props) => {
  const { name, formik, placeholder } = props;
  const [value, setValue] = useState<File | null>(null);

  const handleChange = (newValue: File | null) => {
    setValue(newValue);
    formik.setFieldValue(name, newValue);
  };

  return (
    <Stack className={`fileInput`}>
      <MuiFileInput
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={(e) => handleChange(e)}
      />
    </Stack>
  );
};

export default FileInput;
