import { ChangeEvent, useCallback, useState } from "react";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Stack,
  SvgIcon,
  TextField,
} from "@mui/material";
import SearchMdIcon from "@untitled-ui/icons-react/build/esm/SearchMd";
import { SearchProps } from "../../../../../../interface";
import { useUpdateEffect } from "../../../../../hooks/useUpdateEffect";
import { useFetchSubjects } from "../../../../../hooks/useSubject";
import { SubjectInterface } from "../../../../../../types/subject";
import { useFetchVillages } from "../../../../../hooks/useVillage";
import { VillageInterface } from "../../../../../../types/village";
import AddressAutoFilter from "../../../../../components/AddressAuto/filter";
import { INFORM_STATUS } from "../../../../../constants";
import useUser from "../../../../../hooks/useUser";
import { noneAccountCenter } from "../../../../../../utils/usage";

const search = {
  all: true,
};

const InformSearch = (props: SearchProps) => {
  const { onFiltersChange, placeholder } = props;
  const { user } = useUser();
  const [filters, setFilters] = useState({});
  const [data, setData] = useState({
    search: "",
  });
  const subjects = useFetchSubjects(search);
  const villages = useFetchVillages(search);

  const handleFiltersUpdate = useCallback(() => {
    onFiltersChange?.(filters);
  }, [filters, onFiltersChange]);

  useUpdateEffect(() => {
    handleFiltersUpdate();
  }, [filters, handleFiltersUpdate]);

  const handleQueryChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      setFilters((prevState) => ({
        ...prevState,
        query: data?.search,
      }));
    },
    []
  );

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSearch = useCallback(() => {
    setFilters((prevState) => ({
      ...prevState,
      search: data,
    }));
  }, [data]);

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        flexWrap="wrap"
        spacing={3}
        sx={{ p: 3 }}
      >
        <Box component="form" onSubmit={handleQueryChange} flexGrow={1}>
          <Grid container alignItems={`center`} spacing={2}>
            <Grid item xs={6} lg={2}>
              <TextField
                fullWidth
                label="ประเภท"
                name="subject"
                onChange={(e) => handleInputChange(e)}
                select
              >
                <MenuItem value={``}>ประเภททั้งหมด</MenuItem>
                {subjects.data?.map((option: SubjectInterface) => (
                  <MenuItem key={option._id} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6} lg={2}>
              <TextField
                fullWidth
                label="สถานะ"
                name="status"
                onChange={(e) => handleInputChange(e)}
                select
              >
                <MenuItem value={``}>สถานะทั้งหมด</MenuItem>
                {INFORM_STATUS.map((option: any) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.name}
                  </MenuItem>
                ))}
                {/*<MenuItem value={`new`}>เรื่องใหม่ (รอดำเนินการ)</MenuItem>*/}
                {/*<MenuItem value={`inprogress`}>กำลังดำเนินการ</MenuItem>*/}
                {/*<MenuItem value={`required`}>ข้อมูลไม่เพียงพอ</MenuItem>*/}
                {/*<MenuItem value={`spam`}>สแปม</MenuItem>*/}
                {/*<MenuItem value={`done`}>เรื่องเสร็จสิ้น</MenuItem>*/}
              </TextField>
            </Grid>
            <Grid item xs={6} lg={2}>
              <TextField
                fullWidth
                label="หมู่บ้าน"
                name="village"
                onChange={(e) => handleInputChange(e)}
                select
              >
                <MenuItem value={``}>หมู่บ้านทั้งหมด</MenuItem>
                {villages.data?.map((option: VillageInterface) => (
                  <MenuItem key={option._id} value={option._id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <AddressAutoFilter
              hideProvice={true}
              forceProvince={`นนทบุรี`}
              forceDistrict={
                noneAccountCenter(user?.roles ?? "") ? user?.district ?? "" : ``
              }
              name={{
                province: "province",
                district: "district",
                subdistrict: "subdistrict",
              }}
              handleInputChange={handleInputChange}
              lg={2}
              xs={6}
            />
            {/*<Grid item xs={6} lg={2}>*/}
            {/*  <TextField*/}
            {/*    fullWidth*/}
            {/*    label="ตำบล"*/}
            {/*    name="subdistrict"*/}
            {/*    onChange={(e) => handleInputChange(e)}*/}
            {/*  />*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} lg={4}>*/}
            {/*  <TextField*/}
            {/*    fullWidth*/}
            {/*    label="อำเภอ"*/}
            {/*    name="district"*/}
            {/*    onChange={(e) => handleInputChange(e)}*/}
            {/*  />*/}
            {/*</Grid>*/}
            <Grid item xs={6} lg={2}>
              <TextField
                fullWidth
                label="วันที่เริ่มต้น"
                name="startDate"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleInputChange(e)}
              />
            </Grid>
            <Grid item xs={6} lg={2}>
              <TextField
                fullWidth
                label="วันที่สิ้นสุด"
                name="endDate"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleInputChange(e)}
              />
            </Grid>
            <Grid item xs={6} lg={3}>
              <Button
                variant={"contained"}
                color={"primary"}
                size={"large"}
                startIcon={<SearchMdIcon />}
                type={"submit"}
                onClick={() => handleSearch()}
              >
                ค้นหา
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </>
  );
};

export default InformSearch;
