import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Modal } from "antd";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import Edit02Icon from "@untitled-ui/icons-react/build/esm/Edit02";

import { useDialog } from "../../../../../hooks/useDialog";
import request from "../../../../../../utils/request";
import { Scrollbar } from "../../../../../components/SimpleBar";
import { TableProps } from "../../../../../../interface";
import { FiCheck, FiTrash2 } from "react-icons/fi";
import { useState } from "react";
import DeptartmentForm from "../../Conmponents/Modal/DeptartmentForm";
import CustomDialog from "../../../../../components/CustomDialog";
import { DepartmentInterface } from "../../../../../../types/department";
import { LoadingButton } from "@mui/lab";
import LogsViewIconButton from "../../../../../components/LogsViewIconButton";

const DepartmentLists = (props: TableProps) => {
  const { items = [], onRefresh = () => {}, loading = false } = props;
  const [current, setCurrent] = useState<DepartmentInterface>();

  const dialogUpdated = useDialog();
  const dialogDeleted = useDialog();

  const onDeleted = async () => {
    if (typeof current !== `undefined`) {
      try {
        await request({
          url: `/api/setting/department/${current._id}`,
          method: `DELETE`,
        });
        toast.success(`ลบข้อมูลสำเร็จ`);
        dialogDeleted.handleClose();
        onRefresh();
      } catch (e: any) {
        Modal.error({
          title: "เกิดข้อผิดพลาด",
          content: e.response?.data?.message ?? `Internal Server Error`,
        });
      }
    }
  };

  const handleOpenModal = (item: DepartmentInterface) => {
    setCurrent(item);
    dialogUpdated.handleOpen({ open: true });
  };

  const handleOpenModalDeleted = (item: DepartmentInterface) => {
    setCurrent(item);
    dialogDeleted.handleOpen({ open: true });
  };

  return (
    <Box sx={{ position: "relative" }}>
      <CustomDialog
        size={"sm"}
        title="ข้อมูลหน่วยงาน"
        onClose={dialogUpdated.handleClose}
        open={dialogUpdated.open}
      >
        <DeptartmentForm
          dialog={dialogUpdated}
          data={current}
          onRefresh={onRefresh}
        />
      </CustomDialog>
      <CustomDialog
        size={"xs"}
        title="แจ้งเตือน"
        onClose={dialogDeleted.handleClose}
        open={dialogDeleted.open}
      >
        <Typography variant={`subtitle2`}>ยืนยันการลบรายการ</Typography>

        <Box display="flex" justifyContent={`end`}>
          <LoadingButton
            onClick={() => onDeleted()}
            variant="contained"
            startIcon={<FiCheck />}
          >
            ยืนยัน
          </LoadingButton>
        </Box>
      </CustomDialog>
      <Scrollbar>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ p: 2 }}>ชื่อ</TableCell>
              <TableCell>ตำบล</TableCell>
              <TableCell>หมายเลขโทรศัพท์</TableCell>
              <TableCell>ที่อยู่</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                  <Typography
                    sx={{
                      display: `flex`,
                      alignItems: `center`,
                      justifyContent: `center`,
                    }}
                    variant="subtitle2"
                  >
                    <CircularProgress
                      color="success"
                      size={32}
                      sx={{ mr: 2 }}
                    />{" "}
                    กำลังโหลดข้อมูล...
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              items.map((item) => {
                return (
                  <TableRow hover key={item._id}>
                    <TableCell>
                      <Stack alignItems="center" direction="row" spacing={1}>
                        {item?.name}
                      </Stack>
                    </TableCell>
                    <TableCell>{item?.district}</TableCell>
                    <TableCell>{item?.phone}</TableCell>
                    <TableCell>
                      {item?.address} {item?.subdistrict} {item?.district}{" "}
                      {item?.province}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton onClick={() => handleOpenModal(item)}>
                        <SvgIcon>
                          <Edit02Icon />
                        </SvgIcon>
                      </IconButton>
                      <IconButton onClick={() => handleOpenModalDeleted(item)}>
                        <SvgIcon>
                          <FiTrash2 />
                        </SvgIcon>
                      </IconButton>
                      <LogsViewIconButton id={item._id} />
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </Scrollbar>
    </Box>
  );
};

export default DepartmentLists;
