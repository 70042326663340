import { useCallback, useEffect, useState } from "react";
import { InformInterface } from "../../types/inform";
import request from "../../utils/request";

export const useInform = (id: string | undefined, refreash: number | null) => {
  const [data, setData] = useState({
    data: {} as InformInterface,
    loading: true,
  });
  const get = useCallback(async () => {
    if (!id) return;
    setData((prev) => ({ ...prev, loading: true }));
    try {
      const response = await request({
        url: `/api/inform/${id}`,
        method: "GET",
      });
      setData({
        data: response?.data ?? response ?? {},
        loading: false,
      });
    } catch (e) {}
  }, [id, refreash]);

  useEffect(() => {
    get();
  }, [id, refreash]);
  return data;
};

export const useFetchInform = (search: any = {}) => {
  const [data, setData] = useState<{
    data: InformInterface[];
    count: number;
    page: number;
    perPage: number;
    loading: boolean;
  }>({
    data: [],
    count: 0,
    page: 0,
    perPage: 15,
    loading: true,
  });

  const fetch = useCallback(async () => {
    setData((prev) => ({ ...prev, loading: true }));
    try {
      const response = await request({
        url: "/api/inform",
        method: "GET",
        params: {
          ...search,
          pageSize: search?.perPage ?? 15,
        },
      });
      setData({
        data: response?.data ?? response ?? [],
        count: response.total || 0,
        page: response?.currentPage ?? 0,
        perPage: response?.perPage ?? 0,
        loading: false,
      });
    } catch (e) {}
  }, [search]);

  useEffect(() => {
    fetch();
  }, [search]);

  return data;
};
