import {
  Box,
  Breadcrumbs,
  Link,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { BreadcrumbsSeparator } from "../BreadcrumbsSeparator";
import { Link as RouterLink } from "react-router-dom";
import { ReactNode } from "react";
import { Helmet } from "react-helmet-async";
import ArrowLeftIcon from "@untitled-ui/icons-react/build/esm/ArrowLeft";

interface Props {
  title: string;
  titleIcon?: ReactNode;
  descriptions?: string;
  routes?: {
    title: string;
    path?: string | undefined;
  }[];
  createButton?: ReactNode;
  back?: {
    title: string;
    path?: string | undefined;
  };
  hideHeader?: boolean;

}

const PageHeader = ({
  title,
  titleIcon = null,
  routes = [],
  createButton = null,
  descriptions = "",
  back,
  hideHeader = false,
}: Props) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={descriptions} />
      </Helmet>
      <Stack spacing={4} sx={{ mb: 3 }}>
        {typeof back !== `undefined` && (
          <Box flexGrow={1}>
            <Link
              color="text.primary"
              component={RouterLink}
              to={back?.path ?? ``}
              sx={{
                alignItems: "center",
                display: "inline-flex",
              }}
              underline="hover"
            >
              <SvgIcon sx={{ mr: 1 }}>
                <ArrowLeftIcon />
              </SvgIcon>
              <Typography variant="subtitle2">{back?.title}</Typography>
            </Link>
          </Box>
        )}
        {!hideHeader && (
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Stack spacing={1}>
              <Typography variant="h4">{title} {titleIcon}</Typography>
              <Breadcrumbs
                sx={{ fontSize: `14px` }}
                separator={<BreadcrumbsSeparator />}
              >
                <RouterLink to={`/dashboard`}>แดชบอร์ด</RouterLink>
                {routes?.map(({ path, title }, index) =>
                  typeof path !== `undefined` ? (
                    <RouterLink key={`breadcrumbs-${index}`} to={path ?? ``}>
                      {title}
                    </RouterLink>
                  ) : (
                    <Typography
                      key={`breadcrumbs-${index}`}
                      color="text.secondary"
                      variant="subtitle2"
                    >
                      {title}
                    </Typography>
                  )
                )}
              </Breadcrumbs>
            </Stack>

            {createButton !== null && (
              <Stack alignItems="center" direction="row" spacing={3}>
                {createButton}
                {/*<Button*/}
                {/*    startIcon={<PlusOutlined/>}*/}
                {/*    variant="contained">*/}
                {/*    สร้างพนักงานใหม่*/}
                {/*</Button>*/}
              </Stack>
            )}
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default PageHeader;
