import request from "../../utils/request";
import { useCallback, useEffect, useState } from "react";
import {
  PersonalInterface,
  PersonalViolateTypeInterface,
} from "../../types/personal";

export const useSearchPersonal = () => {
  const [data, setData] = useState({
    all: true,
  });

  const handleSetSearch = (search: any) => {
    setData(search);
  };
  return {
    handleSetSearch,
  };
};

export const usePersonal = (
  id: string | undefined,
  refreash: number | null
) => {
  const [data, setData] = useState({
    data: {} as PersonalInterface,
    loading: true,
  });
  const get = useCallback(async () => {
    if (!id) return;
    setData((prev) => ({ ...prev, loading: true }));
    try {
      const response = await request({
        url: `/api/personal/${id}`,
        method: "GET",
      });
      setData({
        data: response?.data ?? response ?? {},
        loading: false,
      });
    } catch (e) {}
  }, [id, refreash]);

  useEffect(() => {
    get();
  }, [id, refreash]);
  return data;
};

export const useFetchPersonals = (search = {}) => {
  const [data, setData] = useState<{
    data: PersonalInterface[];
    count: number;
    page: number;
    perPage: number;
    loading: boolean;
  }>({
    data: [],
    count: 0,
    page: 0,
    perPage: 15,
    loading: true,
  });

  const fetch = useCallback(async () => {
    setData((prev) => ({ ...prev, loading: true }));
    try {
      const response = await request({
        url: "/api/personal",
        method: "GET",
        params: search,
      });
      setData({
        data: response?.data ?? response ?? [],
        count: response.total || 0,
        page: response?.currentPage ?? 0,
        perPage: response?.perPage ?? 15,
        loading: false,
      });
    } catch (e) {}
  }, [search]);

  useEffect(() => {
    fetch();
  }, [search]);

  return data;
};

export const useFetchViolateType = (search = {}) => {
  const [data, setData] = useState<{
    data: PersonalViolateTypeInterface[];
    count: number;
    page: number;
    loading: boolean;
  }>({
    data: [],
    count: 0,
    page: 0,
    loading: true,
  });

  const fetch = useCallback(async () => {
    setData((prev) => ({ ...prev, loading: true }));
    try {
      const response = await request({
        url: "/api/setting/violate",
        method: "GET",
        params: search,
      });
      setData({
        data: response?.data ?? response ?? [],
        count: response.total || 0,
        page: response?.currentPage ?? 0,
        loading: false,
      });
    } catch (e) {}
  }, [search]);

  useEffect(() => {
    fetch();
  }, [search]);

  return data;
};
