import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import { FormEvent, ReactNode, useCallback, useState } from "react";
import SearchMdIcon from "@untitled-ui/icons-react/build/esm/SearchMd";
import XIcon from "@untitled-ui/icons-react/build/esm/X";
import { Tip } from "../Tip";

interface props {
  title: string;
  onClose: () => void;
  open?: boolean;
  children?: ReactNode;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  overflow?: boolean;
}

const CustomDialog = (props: props) => {
  const {
    onClose,
    open = false,
    children,
    title = "",
    size = "sm",
    overflow = false,
    ...other
  } = props;
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [displayArticles, setDisplayArticles] = useState(false);

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setDisplayArticles(false);
      setIsLoading(true);
      // Do search here

      setIsLoading(false);
      setDisplayArticles(true);
    },
    []
  );

  return (
    <Dialog
      fullWidth
      maxWidth={size}
      className={`custom-dialog-${overflow ? "visible" : ""}`}
      // scroll="paper"
      onClose={onClose}
      open={open}
      {...other}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={3}
        sx={{
          px: 3,
          py: 2,
        }}
      >
        <Typography variant="h6">{title}</Typography>
        <IconButton color="inherit" onClick={onClose}>
          <SvgIcon>
            <XIcon />
          </SvgIcon>
        </IconButton>
      </Stack>
      <DialogContent sx={{ pt: 0 }}>{children}</DialogContent>
    </Dialog>
  );
};

export default CustomDialog;
