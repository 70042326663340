import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Modal } from "antd";
import toast from "react-hot-toast";
import Edit02Icon from "@untitled-ui/icons-react/build/esm/Edit02";

import { useDialog } from "../../../../../hooks/useDialog";
import request from "../../../../../../utils/request";
import { Scrollbar } from "../../../../../components/SimpleBar";
import { TableProps } from "../../../../../../interface";
import { FiCheck, FiEye, FiTrash2 } from "react-icons/fi";
import { useContext, useState } from "react";
import CustomDialog from "../../../../../components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import Form from "../../Conmponents/Modal/Form";
import { WhiteBoxInterface } from "../../../../../../types/whiteBox";
import QrCode from "../../Conmponents/Modal/QrCode";
import { AbilityContext } from "../../../../../components/Ability";
import LogsViewIconButton from "../../../../../components/LogsViewIconButton";

const TableLists = (props: TableProps) => {
  const ability = useContext(AbilityContext);
  const {
    items = [],
    onRefresh = () => {},
    loading = false,
    editable = true,
  } = props;
  const [current, setCurrent] = useState<WhiteBoxInterface>();
  const [viewOnly, setViewOnly] = useState<boolean>(false);

  const dialogUpdated = useDialog();
  const dialogDeleted = useDialog();
  const dialogQrcode = useDialog();
  const dialogGoogleMap = useDialog();

  const onDeleted = async () => {
    if (typeof current !== `undefined`) {
      try {
        await request({
          url: `/api/white-box/${current._id}`,
          method: `DELETE`,
        });
        toast.success(`ลบข้อมูลสำเร็จ`);
        dialogDeleted.handleClose();
        onRefresh();
      } catch (e: any) {
        Modal.error({
          title: "เกิดข้อผิดพลาด",
          content: e.response?.data?.message ?? `Internal Server Error`,
        });
      }
    }
  };

  const handleOpenModal = (item: WhiteBoxInterface, view?: boolean) => {
    setCurrent(item);
    setViewOnly(view ?? false);
    dialogUpdated.handleOpen({ open: true });
  };

  const handleOpenModalDeleted = (item: WhiteBoxInterface) => {
    setCurrent(item);
    dialogDeleted.handleOpen({ open: true });
  };

  const handleOpenModalQrCode = (item: WhiteBoxInterface) => {
    setCurrent(item);
    dialogQrcode.handleOpen({ open: true });
  };

  return (
    <Box sx={{ position: "relative" }}>
      <CustomDialog
        size={"sm"}
        title="ข้อมูลตู้ขาว"
        overflow={true}
        onClose={dialogUpdated.handleClose}
        open={dialogUpdated.open}
      >
        <Form
          viewOnly={viewOnly}
          dialog={dialogUpdated}
          data={current}
          onRefresh={onRefresh}
        />
      </CustomDialog>
      <CustomDialog
        size={"xs"}
        title="แจ้งเตือน"
        onClose={dialogDeleted.handleClose}
        open={dialogDeleted.open}
      >
        <Typography variant={`subtitle2`}>ยืนยันการลบรายการ</Typography>

        <Box display="flex" justifyContent={`end`}>
          <LoadingButton
            onClick={() => onDeleted()}
            variant="contained"
            startIcon={<FiCheck />}
          >
            ยืนยัน
          </LoadingButton>
        </Box>
      </CustomDialog>
      <CustomDialog
        size={"xs"}
        title="QRCODE"
        onClose={dialogQrcode.handleClose}
        open={dialogQrcode.open}
      >
        <QrCode id={current?._id} />
      </CustomDialog>

      <Scrollbar>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <TableCell width={150} sx={{ p: 2 }}>
                Code
              </TableCell>
              <TableCell align="left">คำอธิบาย</TableCell>
              <TableCell align="left">สถานที่ติดตั้ง</TableCell>
              <TableCell align="left">ละติจูด, ลองติจูด</TableCell>
              <TableCell align="center" width={50}></TableCell>
              <TableCell align="center" width={100}>
                QrCode
              </TableCell>
              {editable && (
                <TableCell align="center" width={200}>
                  Actions
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                  <Typography
                    sx={{
                      display: `flex`,
                      alignItems: `center`,
                      justifyContent: `center`,
                    }}
                    variant="subtitle2"
                  >
                    <CircularProgress
                      color="success"
                      size={32}
                      sx={{ mr: 2 }}
                    />{" "}
                    กำลังโหลดข้อมูล...
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              items.map((item) => {
                const location = `หมู่บ้าน ${item?.village?.name ?? "-"} ต.${
                  item?.subdistrict
                } อ.${item?.district} จ.${item?.province}`;
                return (
                  <TableRow hover key={item._id}>
                    <TableCell>
                      <Typography variant="h6">{item?.code}</Typography>
                    </TableCell>
                    <TableCell>{item?.description}</TableCell>
                    <TableCell>{location}</TableCell>
                    <TableCell>
                      <a href={item?.googleMapUrl} target={`_blank`}>
                        <u>
                          {item?.latitude}, {item?.longitude}
                        </u>
                      </a>
                    </TableCell>
                    <TableCell align={`center`}>
                      <Box
                        sx={{
                          width: `20px`,
                          height: `20px`,
                          backgroundColor: item?.color,
                        }}
                      ></Box>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant={`contained`}
                        color={`error`}
                        onClick={() => handleOpenModalQrCode(item)}
                      >
                        QrCode
                      </Button>
                    </TableCell>
                    {editable && (
                      <TableCell align="center">
                        {ability.can("edit", "whiteboxInfo") && (
                          <IconButton onClick={() => handleOpenModal(item)}>
                            <SvgIcon>
                              <Edit02Icon />
                            </SvgIcon>
                          </IconButton>
                        )}
                        {ability.can("read", "whiteboxInfo") && (
                          <IconButton
                            onClick={() => handleOpenModal(item, true)}
                          >
                            <SvgIcon>
                              <FiEye />
                            </SvgIcon>
                          </IconButton>
                        )}
                        {ability.can("deleted", "whiteboxInfo") && (
                          <IconButton
                            onClick={() => handleOpenModalDeleted(item)}
                          >
                            <SvgIcon>
                              <FiTrash2 />
                            </SvgIcon>
                          </IconButton>
                        )}
                        <LogsViewIconButton id={item._id} />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </Scrollbar>
    </Box>
  );
};

export default TableLists;
