import { useCallback, useState } from "react";
import moment from "moment/moment";
import { useFetchDepartments } from "../../../hooks/useDepartment";
import { Box, Button, Card } from "@mui/material";
import PageHeader from "../../../components/PageHeader";
import { PlusOutlined } from "@ant-design/icons";
import CustomDialog from "../../../components/CustomDialog";
import { useDialog } from "../../../hooks/useDialog";
import DeptartmentForm from "./Conmponents/Modal/DeptartmentForm";
import DepartmentLists from "./Features/DepartmentLists";
import CustomSearchTableList from "../../../components/CustomSearchTableList";
import { downloadExcel } from "../../../../utils/excel";
import { Modal } from "antd";
import { FiDownload } from "react-icons/fi";

const useDeptSearch = () => {
  const [state, setState] = useState({
    filters: {
      query: undefined,
      refreshTimes: 0,
    },
  });

  const handleRefresh = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        refreshTimes: moment().unix(),
      },
    }));
  }, []);

  const handleFiltersChange = useCallback((filters: any) => {
    setState((prevState) => ({
      ...prevState,
      filters,
    }));
  }, []);

  return {
    handleRefresh,
    handleFiltersChange,
    state,
  };
};

const Department = () => {
  const { handleRefresh, handleFiltersChange, state } = useDeptSearch();
  const depts = useFetchDepartments(state);

  const dialog = useDialog();

  const handleDownload = useCallback(async () => {
    try {
      await downloadExcel(state.filters, "department");
    } catch (e) {
      console.log(e);
      Modal.error({
        title: "เกิดข้อผิดพลาด",
        content: "ไม่สามารถดาวน์โหลดได้",
      });
    }
  }, [state]);

  return (
    <>
      <PageHeader
        title={`ข้อมูลหน่วยงาน`}
        routes={[{ title: `ข้อมูลหน่วยงาน` }]}
        createButton={
          <>
            <Button
              onClick={() => dialog.handleOpen({ open: true })}
              startIcon={<PlusOutlined />}
              variant="contained"
            >
              สร้างข้อมูลหน่วยงานใหม่
            </Button>
            <CustomDialog
              size={"sm"}
              title="สร้างข้อมูลหน่วยงานใหม่"
              onClose={dialog.handleClose}
              open={dialog.open}
            >
              <DeptartmentForm dialog={dialog} onRefresh={handleRefresh} />
            </CustomDialog>
            <Button
              onClick={() => handleDownload()}
              startIcon={<FiDownload />}
              variant="contained"
              color={"success"}
            >
              ดาวน์โหลด Excel
            </Button>
          </>
        }
      />
      <Box sx={{ flexGrow: 1 }}>
        <Card>
          <CustomSearchTableList
            placeholder={`ค้นหาข้อมูลหน่วยงาน`}
            onFiltersChange={handleFiltersChange}
          />
          <DepartmentLists
            count={depts.count}
            items={depts.data}
            loading={depts.loading}
            onRefresh={handleRefresh}
            page={depts.page}
          />
        </Card>
      </Box>
    </>
  );
};

export default Department;
