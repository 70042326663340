import { Avatar, Box, ButtonBase, SvgIcon } from "@mui/material";
import { usePopover } from "../../../hooks/usePopover";
import User01Icon from '@untitled-ui/icons-react/build/esm/User01';
import AccountPopover from "./AccountPopover";
import useUser from "../../../hooks/useUser";

export const useMockedUser = () => {
  return {
    id: "5e86809283e28b96d2d38537",
    avatar: "/images/avatars/avatar-anika-visser.png",
    name: "Anika Visser",
    email: "anika.visser@devias.io",
  };
};

export const AccountButton = () => {
  const userMock = useMockedUser();
  const popover = usePopover();
  const { user, loading, isAuthentication } = useUser();


  return (
    <>
      <Box
        component={ButtonBase}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: 'divider',
          height: 40,
          width: 40,
          borderRadius: '50%'
        }}
      >
        <Avatar
          sx={{
            height: 32,
            width: 32
          }}
          src={userMock.avatar}
        >
          <SvgIcon>
            <User01Icon />
          </SvgIcon>
        </Avatar>
      </Box>
      <AccountPopover
        user={user}
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );

};
