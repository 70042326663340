import { useCallback, useEffect, useState } from "react";

const useSelection = (items: any[] = []) => {
  const [selected, setSelected] = useState<any[]>([]);

  useEffect(() => {
    setSelected([]);
  }, [items]);

  const handleSelectAll = useCallback(() => {
    // console.log(items)
    setSelected([...items]);
  }, [items]);

  const handleSelectOne = useCallback((item: string) => {
    setSelected((prevState) => {
      return [...prevState, item];
    });
  }, []);

  const handleDeselectAll = useCallback(() => {
    setSelected([]);
  }, []);

  const handleDeselectOne = useCallback((item: string) => {
    setSelected((prevState) => {
      return prevState.filter((_item) => _item !== item);
    });
  }, []);

  return {
    handleDeselectAll,
    handleDeselectOne,
    handleSelectAll,
    handleSelectOne,
    selected,
  };
};

export default useSelection;
