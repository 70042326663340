import { Helmet } from "react-helmet-async";
import { Stack, Typography } from "@mui/material";

const PageNotFound = () => {
  return (
    <>
      <Helmet>
        <title>404 Page Not Found</title>
        <meta name="description" content="Page not found" />
      </Helmet>
      <Stack
        alignItems={`center`}
        justifyContent={`center`}
        sx={{
          height: `100vh`,
        }}
      >
        <Typography variant={`h1`}>Page not found.</Typography>
      </Stack>
    </>
  );
};

export default PageNotFound;
