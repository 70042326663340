import { useCallback, useEffect, useState } from "react";
import request from "../../utils/request";

export const useReportInform = (search = {}) => {
  const [data, setData] = useState({
    data: [],
    loading: true,
  });
  const get = useCallback(async () => {
    setData((prev) => ({ ...prev, loading: true }));
    try {
      const response = await request({
        url: `/api/report/informs`,
        method: "GET",
        params: search,
      });
      setData({
        data: response?.data ?? response ?? {},
        loading: false,
      });
    } catch (e) {}
  }, [search]);

  useEffect(() => {
    get();
  }, [search]);
  return data;
};


export const useReportPersonalType = (search = {}) => {
  const [data, setData] = useState({
    data: [],
    loading: true,
  });
  const get = useCallback(async () => {
    setData((prev) => ({ ...prev, loading: true }));
    try {
      const response = await request({
        url: `/api/report/personal/types`,
        method: "GET",
        params: search,
      });
      setData({
        data: response?.data ?? response ?? {},
        loading: false,
      });
    } catch (e) {}
  }, [search]);

  useEffect(() => {
    get();
  }, [search]);
  return data;
};



export const useReportVillage = (search = {}) => {
  const [data, setData] = useState({
    data: [],
    loading: true,
  });
  const get = useCallback(async () => {
    setData((prev) => ({ ...prev, loading: true }));
    try {
      const response = await request({
        url: `/api/report/villages`,
        method: "GET",
        params: search,
      });
      setData({
        data: response?.data ?? response ?? {},
        loading: false,
      });
    } catch (e) {}
  }, [search]);

  useEffect(() => {
    get();
  }, [search]);
  return data;
};



export const useReportWhiteBox = (search = {}) => {
  const [data, setData] = useState({
    data: [],
    loading: true,
  });
  const get = useCallback(async () => {
    setData((prev) => ({ ...prev, loading: true }));
    try {
      const response = await request({
        url: `/api/report/whitebox`,
        method: "GET",
        params: search,
      });
      setData({
        data: response?.data ?? response ?? {},
        loading: false,
      });
    } catch (e) {}
  }, [search]);

  useEffect(() => {
    get();
  }, [search]);
  return data;
};
