import request from "../../utils/request";
import { useCallback, useEffect, useState } from "react";
import { VillageInterface } from "../../types/village";

export const useFetchVillages = (search = {}) => {
  const [villages, setVillages] = useState<{
    data: VillageInterface[];
    count: number;
    page: number;
    loading: boolean;
    perPage?: number;
  }>({
    data: [],
    count: 0,
    page: 0,
    perPage: 15,
    loading: true,
  });

  const fetch = useCallback(async () => {
    setVillages((prev) => ({ ...prev, loading: true }));
    try {
      const response = await request({
        url: "/api/village",
        method: "GET",
        params: search,
      });
      setVillages({
        data: response?.data ?? response ?? [],
        count: response.total || 0,
        page: response?.currentPage ?? 0,
        perPage: response?.perPage ?? 15,
        loading: false,
      });
    } catch (e) {}
  }, [search]);

  useEffect(() => {
    fetch();
  }, [search]);

  return villages;
};
