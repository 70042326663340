import {
  Cell,
  Label,
  Pie,
  PieChart as CustomPieChart,
  ResponsiveContainer,
} from "recharts";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = (props: any) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, percent, index } = props;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const PieChart = ({ data, keys = [] }: { data: any[]; keys?: any[] }) => {
  return (
    <ResponsiveContainer height={300} width="100%">
      <CustomPieChart width={400} height={400}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          label={renderCustomizedLabel}
          labelLine={false}
          outerRadius={150}
          fill="#8884d8"
          dataKey="count"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color || ``}>
              <Label value={entry.name} />
            </Cell>
          ))}
        </Pie>
      </CustomPieChart>
    </ResponsiveContainer>
  );
};

export default PieChart;
