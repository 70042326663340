export const noneAccountCenter = (roles: string) => {
  const n = [
    "operational-staff",
    "middle-management",
    "top-management",
    // "provincial-governor",
    // "master",
  ];
  return n.includes(roles);
};
