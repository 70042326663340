import { UserInterface } from "../../../../../types/user";
import { useFormik } from "formik";
import * as Yup from "yup";
import request from "../../../../../utils/request";
import toast from "react-hot-toast";
import { Modal } from "antd";
import { errorMessageTranform } from "../../../../../utils/error";
import { useNavigate } from "react-router-dom";
import { useFetchVillages } from "../../../../hooks/useVillage";
import { useFetchDepartments } from "../../../../hooks/useDepartment";
import { Box, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useContext, useEffect, useState } from "react";
import { USER_ROLES } from "../../../../components/Ability/access";
import { AbilityContext } from "../../../../components/Ability";
import AddressAuto from "../../../../components/AddressAuto";

interface Props {
  user?: UserInterface;
  loading?: boolean;
  changeRole?: boolean;
}

const search = {
  all: true,
};

const UserForm = (props: Props) => {
  const { user, loading, changeRole = true } = props;
  const ability = useContext(AbilityContext);
  const navigate = useNavigate();
  const villages = useFetchVillages(search);
  const depts = useFetchDepartments(search);

  const initialValues: UserInterface = {
    username: user?.username ?? "",
    password: "",
    firstName: user?.firstName ?? "",
    lastName: user?.lastName ?? "",
    subdistrict: user?.subdistrict ?? "",
    district: user?.district ?? "",
    province: user?.province ?? "",
    roles: user?.roles ?? USER_ROLES[0].code,
    status: user?.status ?? "",
    villageId: user?.villageId ?? "",
    departmentId: user?.departmentId ?? "",
  };

  let yupObject = {
    username: Yup.string()
      .required("กรุณากรอกชื่อผู้ใช้งาน")
      .min(6, "รหัสผ่านต้องมี 6 ตัวอักษร"),
    firstName: Yup.string().required("กรุณากรอกชื่อ"),
    // lastName: Yup.string().required("กรุณากรอกนามสกุล"),
    // subdistrict: Yup.string().required("กรุณากรอกตำบล"),
    district: Yup.string().required("กรุณากรอกอำเภอ"),
    province: Yup.string().required("กรุณากรอกจังหวัด"),
    roles: Yup.string().required("กรุณาเลือกสิทธิ์การใช้งาน"),
    status: Yup.string().required("กรุณาเลือกสถานะ"),
    // villageId: Yup.string().required("กรุณาเลือกหมู่บ้าน"),
    // departmentId: Yup.string().required("กรุณาเลือกแผนก"),
    password:
      typeof user?._id !== "undefined"
        ? Yup.string()
        : Yup.string().required("กรุณากรอกรหัสผ่าน"),
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object(yupObject),
    onSubmit: async (values) => {
      let options = {
        url: "/api/users",
        method: "POST",
        data: values,
      };

      if (typeof user?._id !== "undefined") {
        options = {
          ...options,
          url: `/api/users/${user?._id}`,
          method: "PUT",
        };
      }

      try {
        await request(options);
        toast.success("บันทึกข้อมูลสำเร็จ");
        if (typeof user?._id === "undefined") {
          navigate(`/users`);
        }
      } catch (e: any) {
        Modal.error({
          title: "เกิดข้อผิดพลาด",
          content: errorMessageTranform(
            e?.response?.data?.errors ?? e.response?.data?.message ?? e.message
          ),
        });
      }
    },
  });

  useEffect(() => {
    if (user) {
      formik.setValues({
        username: user.username,
        firstName: user.firstName,
        lastName: user.lastName,
        subdistrict: user.subdistrict,
        district: user.district,
        province: user.province,
        roles: user.roles,
        status: user.status,
        villageId: user.villageId,
        departmentId: user.departmentId,
      });
    }
  }, [user]);

  useEffect(() => {
    formik.resetForm();
  }, [navigate]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box>
        <Typography variant={`h6`}>ข้อมูลส่วนตัว</Typography>
      </Box>
      <Grid container mt={0} spacing={2}>
        <Grid item xs={12}>
          <TextField
            autoComplete="new-password"
            fullWidth
            disabled={formik.isSubmitting || loading}
            name="username"
            label="ชื่อผู้ใช้งาน"
            value={formik.values.username}
            onChange={formik.handleChange}
            error={formik.touched.username && Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type={`password`}
            autoComplete="new-password"
            fullWidth
            name="password"
            label="รหัสผ่าน"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        </Grid>
      </Grid>

      <Box pt={3}>
        <Typography variant={`h6`}>ข้อมูลทั่วไป</Typography>
      </Box>
      <Grid container mt={0} spacing={2}>
        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            disabled={formik.isSubmitting || loading}
            name="firstName"
            label="ชื่อ"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            disabled={formik.isSubmitting || loading}
            name="lastName"
            label="นามสกุล"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <TextField
            fullWidth
            disabled={formik.isSubmitting || loading}
            name="villageId"
            label="หมู่บ้าน"
            value={formik.values.villageId}
            onChange={formik.handleChange}
            error={formik.touched.villageId && Boolean(formik.errors.villageId)}
            helperText={formik.touched.villageId && formik.errors.villageId}
            select
          >
            {villages?.data?.map((village) => (
              <MenuItem key={village._id} value={village._id}>
                {village.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <AddressAuto
          formik={formik}
          disabled={formik.isSubmitting || loading}
          lg={4}
          xs={12}
          name={{
            province: `province`,
            district: `district`,
            subdistrict: `subdistrict`,
          }}
          value={{
            province: formik.values.province,
            district: formik.values.district,
            subdistrict: formik.values.subdistrict,
          }}
          touched={{
            province: formik.touched?.province,
            district: formik.touched?.district,
            subdistrict: formik.touched?.subdistrict,
          }}
          errors={{
            province: formik.errors?.province,
            district: formik.errors?.district,
            subdistrict: formik.errors?.subdistrict,
          }}
        />
        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            disabled={formik.isSubmitting || loading}
            name="departmentId"
            label="หน่วยงาน"
            value={formik.values.departmentId}
            onChange={formik.handleChange}
            error={
              formik.touched.departmentId && Boolean(formik.errors.departmentId)
            }
            helperText={
              formik.touched.departmentId && formik.errors.departmentId
            }
            select
          >
            <MenuItem value={``}>เลือกหน่วยงาน</MenuItem>
            {depts?.data?.map((dept) => (
              <MenuItem key={dept._id} value={dept._id}>
                {dept.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {changeRole && (
          <>
            <Grid item xs={12} lg={6}>
              <TextField
                fullWidth
                name="roles"
                disabled={formik.isSubmitting || loading}
                label="สิทธิ์การใช้งาน"
                value={formik.values.roles}
                onChange={formik.handleChange}
                error={formik.touched.roles && Boolean(formik.errors.roles)}
                helperText={formik.touched.roles && formik.errors.roles}
                select
              >
                {USER_ROLES.map((role) => (
                  <MenuItem key={role.code} value={role.code}>
                    {role.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} lg={6}>
              <TextField
                fullWidth
                disabled={formik.isSubmitting || loading}
                name="status"
                label="สถานะ"
                value={formik.values.status}
                onChange={formik.handleChange}
                error={formik.touched.status && Boolean(formik.errors.status)}
                helperText={formik.touched.status && formik.errors.status}
                select
              >
                <MenuItem value={`active`}>ใช้งาน</MenuItem>
                <MenuItem value={`inactive`}>ไม่ใช้งาน</MenuItem>
              </TextField>
            </Grid>
          </>
        )}
        <Grid item xs={12} lg={12}>
          <LoadingButton
            loading={formik.isSubmitting || loading}
            disabled={formik.isSubmitting || loading}
            type={"submit"}
            size={`small`}
            variant={`contained`}
            color={`primary`}
            startIcon={<Save />}
          >
            บันทึก
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default UserForm;
