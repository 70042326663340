import * as Yup from "yup";
import { useFormik } from "formik";
import { Grid, MenuItem, TextField } from "@mui/material";
import request from "../../../../../../../utils/request";
import toast from "react-hot-toast";
import { Modal } from "antd";
import { errorMessageTranform } from "../../../../../../../utils/error";
import { WhiteBoxInterface } from "../../../../../../../types/whiteBox";
import { useFetchVillages } from "../../../../../../hooks/useVillage";
import MapMarker from "../../../../../../components/MapMarker";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Save } from "@mui/icons-material";
import { VillageInterface } from "../../../../../../../types/village";
import AddressAuto from "../../../../../../components/AddressAuto";

interface Props {
  data?: VillageInterface | undefined;
  loading?: boolean;
  onRefresh: () => void;
  dialog: any;
  viewOnly?: boolean;
}

const search = {
  all: true,
};

const Form = (props: Props) => {
  const { data, loading, onRefresh, dialog, viewOnly = false } = props;
  const [isUpdate] = useState(typeof data?._id !== "undefined");

  const initialValues = {
    name: data?.name ?? "",
    address: data?.address ?? "",
    moo: data?.moo ?? "",
    statusColor: data?.statusColor ?? "",
    isFunding: data?.isFunding ? 1 : 0,
    leaderName: data?.leaderName ?? "",
    subdistrict: data?.subdistrict ?? "",
    district: data?.district ?? "",
    province: data?.province ?? "",
    latitude: data?.latitude ?? "",
    longitude: data?.longitude ?? "",
    googleMapUrl: data?.googleMapUrl ?? "",
    maleCount: data?.maleCount ?? 0,
    femaleCount: data?.femaleCount ?? 0,
    populationCount: data?.populationCount ?? 0,
    householdCount: data?.householdCount ?? 0,
  };

  let yupObject = {
    name: Yup.string().required(`กรุณากรอก ชื่อหมู่บ้าน`),
    address: Yup.string().required(`กรุณากรอก ที่อยู่`),
    subdistrict: Yup.string().required(`กรุณากรอก ตำบล`),
    district: Yup.string().required(`กรุณากรอก อำเภอ`),
    province: Yup.string().required(`กรุณากรอก จังหวัด`),
    latitude: Yup.string().required(`กรุณากรอก ละติจูด`),
    longitude: Yup.string().required(`กรุณากรอก ลองติจูด`),
    googleMapUrl: Yup.string().required(`กรุณากรอก ลิงค์แผนที่`),
    maleCount: Yup.number().required(`กรุณากรอก จำนวนผู้ชาย`),
    femaleCount: Yup.number().required(`กรุณากรอก จำนวนผู้หญิง`),
    populationCount: Yup.number().required(`กรุณากรอก จำนวนประชากร`),
    householdCount: Yup.number().required(`กรุณากรอก จำนวนครัวเรือน`),
    statusColor: Yup.string().required(`กรุณาเลือก สถานะ`),
    isFunding: Yup.boolean().required(`กรุณาเลือก สถานะกองทุน`),
    leaderName: Yup.string().required(`กรุณากรอก ผู้นำชุมชน`),
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object(yupObject),
    onSubmit: async (values) => {
      let options = {
        url: "/api/village",
        method: "POST",
        data: values,
      };
      if (typeof data?._id !== "undefined") {
        options.method = "PUT";
        options.url = `${options.url}/${data?._id}`;
      }
      try {
        await request(options);
        toast.success("บันทึกข้อมูลสำเร็จ");
        onRefresh();
        dialog.handleClose();
      } catch (e: any) {
        Modal.error({
          title: "เกิดข้อผิดพลาด",
          content: errorMessageTranform(
            e?.response?.data?.errors ?? e.response?.data?.message ?? e.message
          ),
        });
      }
    },
  });

  const handleLocation = (location: {
    latitude: number;
    longitude: number;
    googleMapUrl: string;
  }) => {
    // console.log(location)
    formik.setFieldValue("latitude", `${location.latitude}`);
    formik.setFieldValue("longitude", `${location.longitude}`);
    formik.setFieldValue("googleMapUrl", location.googleMapUrl);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container mt={0} spacing={2}>
        <Grid item xs={12} lg={viewOnly ? 12 : 7}>
          <MapMarker
            viewOnly={viewOnly}
            height={viewOnly ? 300 : 550}
            position={
              isUpdate
                ? {
                    lat: formik.values.latitude,
                    lng: formik.values.longitude,
                  }
                : {}
            }
            handleLocation={handleLocation}
          />
        </Grid>
        <Grid item xs={12} lg={viewOnly ? 12 : 5}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={viewOnly ? 4 : 12}>
              <TextField
                className={viewOnly ? "text-label" : ""}
                InputProps={{
                  readOnly: viewOnly,
                }}
                required
                fullWidth
                disabled={formik.isSubmitting || loading}
                name="name"
                label="ชื่อหมู่บ้าน"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12} lg={viewOnly ? 4 : 12}>
              <TextField
                className={viewOnly ? "text-label" : ""}
                InputProps={{
                  readOnly: viewOnly,
                }}
                required
                fullWidth
                disabled={formik.isSubmitting || loading}
                name="address"
                label="ที่อยู่"
                value={formik.values.address}
                onChange={formik.handleChange}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
            </Grid>
            <Grid item xs={12} lg={viewOnly ? 4 : 12}>
              <TextField
                className={viewOnly ? "text-label" : ""}
                InputProps={{
                  readOnly: viewOnly,
                }}
                required
                fullWidth
                disabled={formik.isSubmitting || loading}
                name="moo"
                label="หมู่ที่"
                value={formik.values.moo}
                onChange={formik.handleChange}
                error={formik.touched.moo && Boolean(formik.errors.moo)}
                helperText={formik.touched.moo && formik.errors.moo}
              />
            </Grid>

            <AddressAuto
              viewOnly={viewOnly}
              formik={formik}
              disabled={formik.isSubmitting || loading}
              lg={viewOnly ? 4 : 12}
              xs={12}
              name={{
                province: `province`,
                district: `district`,
                subdistrict: `subdistrict`,
              }}
              value={{
                province: formik.values.province,
                district: formik.values.district,
                subdistrict: formik.values.subdistrict,
              }}
              touched={{
                province: formik.touched?.province,
                district: formik.touched?.district,
                subdistrict: formik.touched?.subdistrict,
              }}
              errors={{
                province: formik.errors?.province,
                district: formik.errors?.district,
                subdistrict: formik.errors?.subdistrict,
              }}
            />

            {/*<Grid item xs={12} lg={6}>*/}
            {/*  <TextField*/}
            {/*    required*/}
            {/*    fullWidth*/}
            {/*    disabled={formik.isSubmitting || loading}*/}
            {/*    name="subdistrict"*/}
            {/*    label="ตำบล"*/}
            {/*    value={formik.values.subdistrict}*/}
            {/*    onChange={formik.handleChange}*/}
            {/*    error={*/}
            {/*      formik.touched.subdistrict &&*/}
            {/*      Boolean(formik.errors.subdistrict)*/}
            {/*    }*/}
            {/*    helperText={*/}
            {/*      formik.touched.subdistrict && formik.errors.subdistrict*/}
            {/*    }*/}
            {/*  />*/}
            {/*</Grid>*/}
            {/*<Grid item xs={12}>*/}
            {/*  <TextField*/}
            {/*    required*/}
            {/*    fullWidth*/}
            {/*    disabled={formik.isSubmitting || loading}*/}
            {/*    name="district"*/}
            {/*    label="อำเภอ"*/}
            {/*    value={formik.values.district}*/}
            {/*    onChange={formik.handleChange}*/}
            {/*    error={*/}
            {/*      formik.touched.district && Boolean(formik.errors.district)*/}
            {/*    }*/}
            {/*    helperText={formik.touched.district && formik.errors.district}*/}
            {/*  />*/}
            {/*</Grid>*/}
            <Grid item xs={12} lg={viewOnly ? 4 : 12}>
              <TextField
                className={viewOnly ? "text-label" : ""}
                InputProps={{
                  readOnly: viewOnly,
                }}
                required
                fullWidth
                disabled={formik.isSubmitting || loading}
                name="statusColor"
                label="สถานะ"
                value={formik.values.statusColor}
                onChange={formik.handleChange}
                error={
                  formik.touched.statusColor &&
                  Boolean(formik.errors.statusColor)
                }
                helperText={
                  formik.touched.statusColor && formik.errors.statusColor
                }
                select
              >
                <MenuItem value="red">แดง</MenuItem>
                <MenuItem value="yellow">เหลือง</MenuItem>
                <MenuItem value="green">เขียว</MenuItem>
                <MenuItem value="white">ขาว</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} lg={viewOnly ? 4 : 12}>
              <TextField
                className={viewOnly ? "text-label" : ""}
                InputProps={{
                  readOnly: viewOnly,
                }}
                required
                fullWidth
                disabled={formik.isSubmitting || loading}
                name="isFunding"
                label="สถานะกอนทุนแผ่นดิน"
                value={formik.values.isFunding}
                onChange={formik.handleChange}
                error={
                  formik.touched.isFunding && Boolean(formik.errors.isFunding)
                }
                helperText={formik.touched.isFunding && formik.errors.isFunding}
                select
              >
                <MenuItem value={0}>ไม่เป็น</MenuItem>
                <MenuItem value={1}>เป็น</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} lg={viewOnly ? 4 : 12}>
              <TextField
                className={viewOnly ? "text-label" : ""}
                InputProps={{
                  readOnly: viewOnly,
                }}
                required
                fullWidth
                disabled={formik.isSubmitting || loading}
                name="leaderName"
                label="ชื่อผู้นำชุมชน"
                value={formik.values.leaderName}
                onChange={formik.handleChange}
                error={
                  formik.touched.leaderName && Boolean(formik.errors.leaderName)
                }
                helperText={
                  formik.touched.leaderName && formik.errors.leaderName
                }
              />
            </Grid>
            <Grid item xs={12} lg={viewOnly ? 3 : 12}>
              <TextField
                className={viewOnly ? "text-label" : ""}
                InputProps={{
                  readOnly: viewOnly,
                }}
                required
                fullWidth
                disabled={formik.isSubmitting || loading}
                name="maleCount"
                label="จำนวนประชากรผู้ชาย"
                value={formik.values.maleCount}
                onChange={formik.handleChange}
                error={
                  formik.touched.maleCount && Boolean(formik.errors.maleCount)
                }
                helperText={formik.touched.maleCount && formik.errors.maleCount}
              />
            </Grid>
            <Grid item xs={12} lg={viewOnly ? 3 : 12}>
              <TextField
                className={viewOnly ? "text-label" : ""}
                InputProps={{
                  readOnly: viewOnly,
                }}
                required
                fullWidth
                disabled={formik.isSubmitting || loading}
                name="femaleCount"
                label="จำนวนประชากรผู้หญิง"
                value={formik.values.femaleCount}
                onChange={formik.handleChange}
                error={
                  formik.touched.femaleCount &&
                  Boolean(formik.errors.femaleCount)
                }
                helperText={
                  formik.touched.femaleCount && formik.errors.femaleCount
                }
              />
            </Grid>
            <Grid item xs={12} lg={viewOnly ? 3 : 12}>
              <TextField
                className={viewOnly ? "text-label" : ""}
                InputProps={{
                  readOnly: viewOnly,
                }}
                required
                fullWidth
                disabled={formik.isSubmitting || loading}
                name="populationCount"
                label="จำนวนประชากรทั้งหมด"
                value={formik.values.populationCount}
                onChange={formik.handleChange}
                error={
                  formik.touched.populationCount &&
                  Boolean(formik.errors.populationCount)
                }
                helperText={
                  formik.touched.populationCount &&
                  formik.errors.populationCount
                }
              />
            </Grid>
            <Grid item xs={12} lg={viewOnly ? 3 : 12}>
              <TextField
                className={viewOnly ? "text-label" : ""}
                InputProps={{
                  readOnly: viewOnly,
                }}
                required
                fullWidth
                disabled={formik.isSubmitting || loading}
                name="householdCount"
                label="จำนวนครัวเรือน"
                value={formik.values.householdCount}
                onChange={formik.handleChange}
                error={
                  formik.touched.householdCount &&
                  Boolean(formik.errors.householdCount)
                }
                helperText={
                  formik.touched.householdCount && formik.errors.householdCount
                }
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                className={viewOnly ? "text-label" : ""}
                InputProps={{
                  readOnly: viewOnly,
                }}
                required
                fullWidth
                disabled={formik.isSubmitting || loading}
                name="latitude"
                label="Latitude"
                value={formik.values.latitude}
                onChange={formik.handleChange}
                error={
                  formik.touched.latitude && Boolean(formik.errors.latitude)
                }
                helperText={formik.touched.latitude && formik.errors.latitude}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                className={viewOnly ? "text-label" : ""}
                InputProps={{
                  readOnly: viewOnly,
                }}
                required
                fullWidth
                disabled={formik.isSubmitting || loading}
                name="longitude"
                label="Longitude"
                value={formik.values.longitude}
                onChange={formik.handleChange}
                error={
                  formik.touched.longitude && Boolean(formik.errors.longitude)
                }
                helperText={formik.touched.longitude && formik.errors.longitude}
              />
            </Grid>
            {!viewOnly && (
              <Grid item xs={12}>
                <LoadingButton
                  loading={formik.isSubmitting || loading}
                  disabled={formik.isSubmitting || loading}
                  type={"submit"}
                  size={`small`}
                  variant={`contained`}
                  color={`primary`}
                  startIcon={<Save />}
                >
                  บันทึก
                </LoadingButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
