import { Box, Card } from "@mui/material";
import PageHeader from "../../../components/PageHeader";
import CustomSearchTableList from "../../../components/CustomSearchTableList";
import TableLists from "./Features/TableLists";
import { useSearch } from "../../../hooks/useSearch";
import { useFetchLogs } from "../../../hooks/useLogs";
import TablePaginate from "../../../components/TablePaginate";

const Logs = () => {
  const {
    handleRefresh,
    handleFiltersChange,
    state,
    handleSetPage,
    handleSetPerPage,
  } = useSearch();
  const data = useFetchLogs(state);
  return (
    <>
      <PageHeader title={`Logs`} routes={[{ title: `Logs` }]} />
      <Box sx={{ flexGrow: 1 }}>
        <Card>
          <CustomSearchTableList
            placeholder={`ค้นหา`}
            onFiltersChange={handleFiltersChange}
          />
          <TableLists
            count={data.count}
            items={data.data}
            loading={data.loading}
            onRefresh={handleRefresh}
            page={data.page}
          />
        </Card>
      </Box>
      <Box py={2}>
        <TablePaginate
          handleSetPage={handleSetPage}
          handleSetPerPage={handleSetPerPage}
          count={data.count}
          perPage={data.perPage}
          currentPage={data.page}
        />
      </Box>
    </>
  );
};

export default Logs;
