import { useSearch } from "../../hooks/useSearch";
import { useFetchInform } from "../../hooks/useInform";
import { useDialog } from "../../hooks/useDialog";
import PageHeader from "../../components/PageHeader";
import { Box, Button, Card, Pagination } from "@mui/material";
import TableLists from "./Features/TableLists";
import InformSearch from "./Features/TableLists/InformSearch";
import { ceil } from "lodash";
import TablePaginate from "../../components/TablePaginate";
import { useCallback, useContext } from "react";
import { AbilityContext } from "../../components/Ability";
import { Modal } from "antd";
import { FiDownload } from "react-icons/fi";
import { downloadExcel } from "../../../utils/excel";

const InformPage = () => {
  const ability = useContext(AbilityContext);

  const {
    handleRefresh,
    handleFiltersChange,
    state,
    handleSetFilters,
    handleSetPage,
    handleSetPerPage,
  } = useSearch();
  const data = useFetchInform(state);
  const dialog = useDialog();

  const handlePageChange = (event: any) => {
    console.log(event);
  };

  const handleDownload = useCallback(async () => {
    try {
      await downloadExcel(state.filters, "inform");
    } catch (e) {
      console.log(e);
      Modal.error({
        title: "เกิดข้อผิดพลาด",
        content: "ไม่สามารถดาวน์โหลดได้",
      });
    }
  }, [state]);

  return (
    <>
      <PageHeader
        title={`ข้อมูลแจ้งเหตุ`}
        routes={[{ title: `ข้อมูลแจ้งเหตุ` }]}
        createButton={
          <Button
            onClick={() => handleDownload()}
            startIcon={<FiDownload />}
            variant="contained"
            color={"success"}
          >
            ดาวน์โหลด Excel
          </Button>
        }
      />
      <Box sx={{ flexGrow: 1 }}>
        <Card>
          <InformSearch
            placeholder={`ค้นหา`}
            onFiltersChange={handleFiltersChange}
          />
          <TableLists
            count={data.count}
            items={data.data}
            loading={data.loading}
            onRefresh={handleRefresh}
            page={data.page}
          />
        </Card>
        <Box py={2}>
          <TablePaginate
            handleSetPage={handleSetPage}
            handleSetPerPage={handleSetPerPage}
            count={data.count}
            perPage={data.perPage}
            currentPage={data.page}
          />
        </Box>
      </Box>
    </>
  );
};

export default InformPage;
