import { Box, Button, Card } from "@mui/material";
import { PlusOutlined } from "@ant-design/icons";
import PageHeader from "../../../../components/PageHeader";
import { Link } from "react-router-dom";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useMounted } from "../../../../hooks/useMounted";
import { UserInterface } from "../../../../../types/user";
import useSelection from "../../../../hooks/useSelection";
import UserListSearch from "./UserListSearch";
import UserListTable from "./UserListTable";
import { useDialog } from "../../../../hooks/useDialog";
import { useFetchUsers } from "../../../../hooks/useUser";
import moment from "moment";
import { AbilityContext } from "../../../../components/Ability";
import { useSearch } from "../../../../hooks/useSearch";
import TablePaginate from "../../../../components/TablePaginate";
import { downloadExcel } from "../../../../../utils/excel";
import { Modal } from "antd";
import { FiDownload } from "react-icons/fi";

const useUsersSearch = () => {
  const [state, setState] = useState({
    filters: {
      query: undefined,
      hasAcceptedMarketing: undefined,
      isProspect: undefined,
      isReturning: undefined,
    },
    refreshTimes: 0,
    page: 0,
    rowsPerPage: 5,
    sortBy: "updatedAt",
    sortDir: "desc",
  });

  const handleFiltersChange = useCallback((filters: any) => {
    setState((prevState) => ({
      ...prevState,
      filters,
    }));
  }, []);

  const handleSortChange = useCallback((sort: any) => {
    setState((prevState) => ({
      ...prevState,
      sortBy: sort.sortBy,
      sortDir: sort.sortDir,
    }));
  }, []);

  const handlePageChange = useCallback((event: any, page: number) => {
    setState((prevState) => ({
      ...prevState,
      page,
    }));
  }, []);

  const handleRowsPerPageChange = useCallback((event: any) => {
    setState((prevState) => ({
      ...prevState,
      rowsPerPage: parseInt(event.target.value, 10),
    }));
  }, []);

  const handleRefresh = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      refreshTimes: moment().unix(),
    }));
  }, []);

  return {
    handleRefresh,
    handleFiltersChange,
    handleSortChange,
    handlePageChange,
    handleRowsPerPageChange,
    state,
  };
};

const useUsersStore = (searchState: any) => {
  const isMounted = useMounted();
  const [state, setState] = useState<{
    users: UserInterface[];
    usersCount: number;
    loading: boolean;
    perPage: number;
    page: number;
  }>({
    users: [],
    usersCount: 0,
    loading: true,
    perPage: 15,
    page: 0,
  });

  const handleUsersGet = useCallback(async () => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    try {
      const response = await useFetchUsers(searchState);
      if (isMounted()) {
        setState({
          users: response.data,
          usersCount: response.count,
          perPage: response.perPage,
          page: response.page,
          loading: false,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }, [searchState, isMounted]);

  useEffect(() => {
    handleUsersGet();
  }, [searchState]);

  return {
    ...state,
  };
};

const useUsersIds = (users: any[] = []) => {
  return useMemo(() => {
    return users.map((user) => user?._id || "");
  }, [users]);
};

const UserLists = () => {
  const ability = useContext(AbilityContext);
  const usersSearch = useUsersSearch();

  const {
    handleRefresh,
    handleFiltersChange,
    state,
    handleSetFilters,
    handleSetPage,
    handleSetPerPage,
  } = useSearch();

  const usersStore = useUsersStore(state);
  const usersIds = useUsersIds(usersStore.users);
  const usersSelection = useSelection(usersIds);

  const dialog = useDialog();

  const handleDownload = useCallback(async () => {
    try {
      await downloadExcel(state.filters, "users");
    } catch (e) {
      console.log(e);
      Modal.error({
        title: "เกิดข้อผิดพลาด",
        content: "ไม่สามารถดาวน์โหลดได้",
      });
    }
  }, [state]);

  return (
    <>
      <PageHeader
        title={`จัดการผู้ใช้งาน`}
        routes={[{ title: `จัดการผู้ใช้งาน` }]}
        createButton={
          <>
            {ability.can("create", "userManagement") && (
              <Button
                component={Link}
                to={`/users/create`}
                startIcon={<PlusOutlined />}
                variant="contained"
              >
                สร้างผู้ใช้งานใหม่
              </Button>
            )}
            <Button
              onClick={() => handleDownload()}
              startIcon={<FiDownload />}
              variant="contained"
              color={"success"}
            >
              ดาวน์โหลด Excel
            </Button>
            {/*<Button*/}
            {/*  onClick={() => dialog.handleOpen({open: true})}*/}
            {/*  startIcon={<GiOpenFolder/>}*/}
            {/*  variant="contained"*/}
            {/*>*/}
            {/*  สร้างผู้ใช้งานใหม่*/}
            {/*</Button>*/}
            {/*<CustomDialog*/}
            {/*  size={"md"}*/}
            {/*  title="สร้างผู้ใช้งานใหม่"*/}
            {/*  onClose={dialog.handleClose}*/}
            {/*  open={dialog.open}*/}
            {/*>*/}
            {/*  <UserManage hidePageHeader={true}/>*/}
            {/*</CustomDialog>*/}
          </>
        }
      />

      <Box sx={{ flexGrow: 1 }}>
        <Card>
          <UserListSearch
            onFiltersChange={handleFiltersChange}
            onSortChange={usersSearch.handleSortChange}
            sortBy={usersSearch.state.sortBy}
            sortDir={usersSearch.state.sortBy}
          />
          <UserListTable
            loading={usersStore.loading}
            count={usersStore.usersCount}
            items={usersStore.users}
            onDeselectAll={usersSelection.handleDeselectAll}
            onDeselectOne={usersSelection.handleDeselectOne}
            onPageChange={usersSearch.handlePageChange}
            onRowsPerPageChange={usersSearch.handleRowsPerPageChange}
            onSelectAll={usersSelection.handleSelectAll}
            onSelectOne={usersSelection.handleSelectOne}
            page={usersSearch.state.page}
            rowsPerPage={usersSearch.state.rowsPerPage}
            selected={usersSelection.selected}
            onRefresh={usersSearch.handleRefresh}
          />
        </Card>
      </Box>
      <Box py={2}>
        <TablePaginate
          handleSetPage={handleSetPage}
          handleSetPerPage={handleSetPerPage}
          count={usersStore.usersCount}
          perPage={usersStore.perPage}
          currentPage={usersStore.page}
        />
      </Box>
    </>
  );
};

export default UserLists;
