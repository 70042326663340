import { UserInterface } from "../../../types/user";
import { Ability } from "@casl/ability";
import { intersection } from "lodash";
import { createContext } from "react";
import { createContextualCan } from "@casl/react";
import { moduleConfig } from "./access";

export default function defineAbilityFor(user?: UserInterface) {
  if (!user) {
    return new Ability([]);
  }

  const moduleAccessConfig: any = moduleConfig;

  const roles = user.roles || "";
  const allAccess = ["master"];
  if (intersection(user.roles, allAccess).length || allAccess.includes(roles)) {
    return new Ability([
      {
        action: "manage",
        subject: "all",
      },
    ]);
  }

  let ability: any = [];
  const accessAction = ["read", "create", "edit", "deleted"];
  Object.keys(moduleAccessConfig).forEach((key) => {
    const module: any = moduleAccessConfig[key];
    const actionModule: any = [];
    accessAction.map((action) => {
      const allow = module[action];
      if (allow.includes(roles)) {
        actionModule.push(action);
      }
    });
    if (actionModule) {
      ability.push({
        action: actionModule,
        subject: key,
      });
    }
  });

  return new Ability(ability);
}
export const AbilityContext = createContext(defineAbilityFor());
export const Can = createContextualCan(AbilityContext.Consumer);
