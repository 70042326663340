import axios from "axios";
import { UserInterface } from "../../types/user";
import useSWR from "swr";
import request from "../../utils/request";

const now = new Date();

const userFetcher = async (): Promise<UserInterface> => {
  try {
    const { data } = await axios.get("/api/users/me");
    return data;
  } catch (e: any) {
    throw e.response;
  }
};

export default function useUser() {
  const { data, mutate, error } = useSWR("api_user", userFetcher);
  const loading = !data && !error;
  return {
    loading,
    error: error || undefined,
    user: data,
    isAuthentication: !!data,
    mutate,
  };
}

export const useUserInfo = async (username: string) => {
  const response = await request({
    url: `/api/users/${username}`,
    method: "GET",
  });
  return response;
};

export const useFetchUsers = async (search = {}) => {
  const response = await request({
    url: "/api/users",
    method: "GET",
    params: search,
  });
  return {
    data: response.data || [],
    count: response.total || 0,
    page: response?.currentPage ?? 0,
    perPage: response?.perPage ?? 15,
  };
};
