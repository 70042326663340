import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import store2 from "store2";
import isEmpty from "lodash/isEmpty";
import { Provider } from "react-redux";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";
import { store } from "./store/configureStore";
import { HelmetProvider } from "react-helmet-async";
import { ConfigProvider } from "antd";
import thTH from "antd/lib/locale/th_TH";
import App from "./app";
import "./styles/index.css";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "./theme";

dayjs.extend(buddhistEra);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(isSameOrAfter);
dayjs.locale("th");

axios.defaults.baseURL = process.env.REACT_APP_API_SERVICE;
axios.interceptors.request.use(async (config) => {
  const jwtToken = store2.get("jwtToken") || "";
  if (!isEmpty(jwtToken) && !config?.headers["Authorization"]) {
    config.headers["Authorization"] = `Bearer ${jwtToken}`;
  }
  if (["get", "GET"].includes(config?.method || "")) {
    config.params = {
      ...(config?.params || {}),
    };
  }
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      if (window.location.pathname !== '/login') {
        window.location.href = '/login';
      }
    }

    return Promise.reject(error);
  }
);


const settings = {
  colorPreset: "indigo",
  contrast: "normal",
  direction: "ltr",
  layout: "vertical",
  navColor: "evident",
  paletteMode: "light",
  responsiveFontSizes: true,
  stretch: false,
};

const theme = createTheme({
  colorPreset: settings.colorPreset,
  contrast: settings.contrast,
  direction: settings.direction,
  paletteMode: settings.paletteMode,
  responsiveFontSizes: settings.responsiveFontSizes,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <HelmetProvider>
      {/*<React.StrictMode>*/}
      <ConfigProvider locale={thTH}>
        <ThemeProvider theme={theme}>
          <App/>
        </ThemeProvider>
      </ConfigProvider>
      {/*</React.StrictMode>*/}
    </HelmetProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
