export const DATE_FORMAT = "YYYY-MM-DD";
export const MONEY_FORMAT = "0,0.00";
export const NUMBER_FORMAT = "0,0.[00]";
export const COUNT_FORMAT = "0,0";

export const AVATARS_IMAGES = [
  { code: 1, src: `avatar-alcides-antonio.png`, name: "Alcides Antonio" },
  { code: 2, src: `avatar-anika-visser.png`, name: "Anika Visser" },
  { code: 3, src: `avatar-cao-yu.png`, name: "Cao Yu" },
  { code: 4, src: `avatar-carson-darrin.png`, name: "Carson Darrin" },
  { code: 5, src: `avatar-chinasa-neo.png`, name: "Chinasa Neo" },
  { code: 6, src: `avatar-fran-perez.png`, name: "Fran Perez" },
  { code: 7, src: `avatar-iulia-albu.png`, name: "Iulia Albu" },
  { code: 8, src: `avatar-jane-rotanson.png`, name: "Jane Rotanson" },
  { code: 9, src: `avatar-jie-yan-song.png`, name: "Jie Yan Song" },
  { code: 10, src: `avatar-marcus-finn.png`, name: "Marcus Finn" },
  { code: 11, src: `avatar-miron-vitold.png`, name: "Miron Vitold" },
  { code: 12, src: `avatar-nasimiyu-danai.png`, name: "Nasimiyu Danai" },
  { code: 13, src: `avatar-neha-punita.png`, name: "Neha Punita" },
  { code: 14, src: `avatar-omar-darboe.png`, name: "Omar Darboe" },
  { code: 15, src: `avatar-penjani-inyene.png`, name: "Penjani Inyene" },
  { code: 16, src: `avatar-seo-hyeon-ji.png`, name: "Seo Hyeon Ji" },
  {
    code: 17,
    src: `avatar-siegbert-gottfried.png`,
    name: "Siegbert Gottfried",
  },
];

export const INFORM_STATUS = [
  { code: `new`, name: "เรื่องใหม่", color: `info` },
  { code: `inprogress`, name: "กำลังดำเนินการ", color: `warning` },
  { code: `required`, name: "ข้อมูลไม่เพียงพอ", color: `primary` },
  { code: `spam`, name: "สแปม", color: `info` },
  { code: `done`, name: "เรื่องเสร็จสิ้น", color: `success` },
];

export const MONTHS: any[] = [
  { code: 1, name: "มกราคม" },
  { code: 2, name: "กุมภาพันธ์" },
  { code: 3, name: "มีนาคม" },
  { code: 4, name: "เมษายน" },
  { code: 5, name: "พฤษภาคม" },
  { code: 6, name: "มิถุนายน" },
  { code: 7, name: "กรกฎาคม" },
  { code: 8, name: "สิงหาคม" },
  { code: 9, name: "กันยายน" },
  { code: 10, name: "ตุลาคม" },
  { code: 11, name: "พฤศจิกายน" },
  { code: 12, name: "ธันวาคม" },
];
