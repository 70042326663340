import { Autocomplete } from "@mui/lab";
import { TextField } from "@mui/material";
import { useFetchPersonals } from "../../../hooks/usePersonal";
import { SyntheticEvent, useEffect, useState } from "react";

const search = {
  all: true,
  key: "name",
};

interface Props {
  handleSelect?: (value: any) => void;
}

const AutoCompetePersonal = (prop: Props) => {
  const { handleSelect } = prop;
  const { data } = useFetchPersonals(search);
  const [option, setOption] = useState<any[]>([]);

  useEffect(() => {
    let items = [];
    items = data.map((item) => {
      return {
        title: `${item.firstName} ${item.lastName}`,
        id: item._id,
      };
    });
    setOption(items);
  }, [data]);

  const handleOnSelect = (value: any) => {
    handleSelect?.(value);
  };

  return (
    <Autocomplete
      multiple
      id="tags-outlined"
      options={option}
      getOptionLabel={(option) => option.title}
      filterSelectedOptions
      onChange={(event, newValue) => handleOnSelect(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="เลือกข้อมูลบุคคล"
          placeholder="เลือกข้อมูลบุคคล"
        />
      )}
    />
  );
};

export default AutoCompetePersonal;
