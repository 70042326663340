import { Box, Card, CardContent, CardHeader, Grid } from "@mui/material";
import PageHeader from "../../../../components/PageHeader";
import { useParams } from "react-router-dom";
import useUser, { useUserInfo } from "../../../../hooks/useUser";
import { useCallback, useEffect, useState } from "react";
import { UserInterface } from "../../../../../types/user";
import UserForm from "./UserForm";

interface props {
  hidePageHeader?: boolean;
}

const useUserStoreInfo = (id?: string) => {
  const [data, setData] = useState({
    userInfo: {} as UserInterface,
    loading: false,
  });

  const getUserInfo = useCallback(async () => {
    if (id === undefined) return;
    setData((prev) => ({ ...prev, loading: true }));
    try {
      const user = await useUserInfo(id);
      setData({ userInfo: user, loading: false });
    } catch (e) {}
  }, [id]);

  useEffect(() => {
    getUserInfo();
  }, [id]);

  return data;
};

const UserProfile = () => {
  const { user } = useUser();
  const { userInfo, loading } = useUserStoreInfo(user?._id);

  return (
    <>
      <PageHeader
        title={`ข้อมูลผู้ใช้งาน ${userInfo?.username || ""}`}
        routes={[
          { title: `รายชื่อผู้ใช้งาน`, path: "/users" },
          { title: `${userInfo?.username || "สร้างใหม่"}` },
        ]}
        back={{
          title: "รายชื่อผู้ใช้งาน",
          path: "/users",
        }}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container mt={0} spacing={2}>
          <Grid item xs={12} lg={6}>
            <Card>
              {/*<CardHeader title={`ข้อมูลส่วนตัว`} />*/}
              <CardContent>
                <UserForm changeRole={false} loading={loading} user={userInfo} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default UserProfile;
