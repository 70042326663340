import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { TableProps } from "../../../../../../interface";
import { Scrollbar } from "../../../../../components/SimpleBar";
import { getInitials } from "../../../../../../utils/firstChar";
import { Link } from "react-router-dom";
import Edit02Icon from "@untitled-ui/icons-react/build/esm/Edit02";
import ArrowRightIcon from "@untitled-ui/icons-react/build/esm/ArrowRight";
import { FiCheck, FiTrash } from "react-icons/fi";
import UserManage from "../../UserManage";
import CustomDialog from "../../../../../components/CustomDialog";
import { useDialog } from "../../../../../hooks/useDialog";
import { LoadingButton } from "@mui/lab";
import request from "../../../../../../utils/request";
import { Modal } from "antd";
import toast from "react-hot-toast";
import { USER_ROLES } from "../../../../../components/Ability/access";
import { useContext } from "react";
import { AbilityContext } from "../../../../../components/Ability";
import LogsViewIconButton from "../../../../../components/LogsViewIconButton";

const UserListTable = (props: TableProps) => {
  const {
    count = 0,
    items = [],
    onDeselectAll,
    onDeselectOne,
    onPageChange = () => {},
    onRefresh = () => {},
    onRowsPerPageChange,
    onSelectAll,
    onSelectOne,
    page = 0,
    rowsPerPage = 0,
    loading = false,
    selected = [],
  } = props;
  const ability = useContext(AbilityContext);
  const selectedSome = selected.length > 0 && selected.length < items.length;
  const selectedAll = items.length > 0 && selected.length === items.length;
  const enableBulkActions = selected.length > 0;

  const dialog = useDialog();

  const onDeleted = async () => {
    try {
      await request({
        url: `/api/users`,
        method: `DELETE`,
        data: {
          id: selected,
        },
      });
      toast.success(`ลบผู้ใช้งานสำเร็จ`);
      dialog.handleClose();
      onRefresh();
    } catch (e: any) {
      Modal.error({
        title: "เกิดข้อผิดพลาด",
        content: e.response?.data?.message ?? `Internal Server Error`,
      });
    }
  };

  return (
    <Box sx={{ position: "relative" }}>
      {enableBulkActions && (
        <Stack
          direction="row"
          spacing={2}
          sx={{
            alignItems: "center",
            backgroundColor: "#F8F9FA",
            display: enableBulkActions ? "flex" : "none",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            paddingLeft: "10px",
            py: 0.5,
            zIndex: 10,
          }}
        >
          <Checkbox
            checked={selectedAll}
            indeterminate={selectedSome}
            onChange={(event) => {
              if (event.target.checked) {
                onSelectAll?.();
              } else {
                onDeselectAll?.();
              }
            }}
          />
          <Button
            startIcon={<FiTrash />}
            color="error"
            onClick={() => dialog.handleOpen({ open: true })}
            size="small"
          >
            ลบรายการที่เลือก
          </Button>
          <CustomDialog
            size={"xs"}
            title="แจ้งเตือน"
            onClose={dialog.handleClose}
            open={dialog.open}
          >
            <Typography variant={`subtitle2`}>
              ยืนยันการลบรายการที่เลือก {selected.length} รายการ
            </Typography>

            <Box display="flex" justifyContent={`end`}>
              <LoadingButton
                onClick={() => onDeleted()}
                variant="contained"
                startIcon={<FiCheck />}
              >
                ยืนยัน
              </LoadingButton>
            </Box>
          </CustomDialog>
        </Stack>
      )}
      <Scrollbar>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              {ability.can("deleted", "userManagement") && (
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAll}
                    indeterminate={selectedSome}
                    onChange={(event) => {
                      if (event.target.checked) {
                        onSelectAll?.();
                      } else {
                        onDeselectAll?.();
                      }
                    }}
                  />
                </TableCell>
              )}
              <TableCell>ชื่อ-นามสกุล</TableCell>
              <TableCell>สถานที่</TableCell>
              <TableCell align={`center`}>สถานะ</TableCell>
              <TableCell align={`center`}>สิทธิการใช้งาน</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                  <Typography
                    sx={{
                      display: `flex`,
                      alignItems: `center`,
                      justifyContent: `center`,
                    }}
                    variant="subtitle2"
                  >
                    <CircularProgress
                      color="success"
                      size={32}
                      sx={{ mr: 2 }}
                    />{" "}
                    กำลังโหลดข้อมูล...
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              items.map((user) => {
                const isSelected = selected.includes(user._id);
                const location = `หมู่บ้าน ${user?.village?.name ?? "-"} ตำบล${
                  user?.subdistrict ?? "-"
                } อำเภอ${user?.district ?? "-"} จังหวัด${
                  user?.province ?? "-"
                }`;
                const totalSpent = 0;

                return (
                  <TableRow hover key={user._id} selected={isSelected}>
                    {ability.can("deleted", "userManagement") && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isSelected}
                          onChange={(event) => {
                            if (event.target.checked) {
                              onSelectOne?.(user._id);
                            } else {
                              onDeselectOne?.(user._id);
                            }
                          }}
                          value={isSelected}
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      <Stack alignItems="center" direction="row" spacing={1}>
                        <Avatar
                          src={user.avatar}
                          sx={{
                            height: 42,
                            width: 42,
                          }}
                        >
                          {getInitials(user.firstName)}
                        </Avatar>
                        <div>
                          <Link color="inherit" to={"/users/" + user._id}>
                            <Typography variant={"subtitle2"}>
                              {user.firstName} {user.lastName}
                            </Typography>
                          </Link>
                          <Typography color="text.secondary" variant="body2">
                            {user.username}
                          </Typography>
                        </div>
                      </Stack>
                    </TableCell>
                    <TableCell>{location}</TableCell>
                    <TableCell align={`center`}>{user.status}</TableCell>
                    <TableCell align={`center`}>
                      <Typography variant="subtitle2">
                        {USER_ROLES.find((role) => role.code == user.roles)
                          ?.name ?? ""}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      {ability.can("edit", "userManagement") && (
                        <IconButton
                          component={Link}
                          to={`/users/${user._id}/edit`}
                        >
                          <SvgIcon>
                            <Edit02Icon />
                          </SvgIcon>
                        </IconButton>
                      )}
                      <LogsViewIconButton id={user._id} />
                      {/*<IconButton component={Link} to={`/users/${user._id}`}>*/}
                      {/*  <SvgIcon>*/}
                      {/*    <ArrowRightIcon />*/}
                      {/*  </SvgIcon>*/}
                      {/*</IconButton>*/}
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </Scrollbar>
      {/*<TablePagination*/}
      {/*  component="div"*/}
      {/*  count={count}*/}
      {/*  onPageChange={onPageChange}*/}
      {/*  onRowsPerPageChange={onRowsPerPageChange}*/}
      {/*  page={page}*/}
      {/*  rowsPerPage={rowsPerPage}*/}
      {/*  rowsPerPageOptions={[5, 10, 25]}*/}
      {/*/>*/}
    </Box>
  );
};

export default UserListTable;
