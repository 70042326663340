import { useCallback, useState } from "react";
import moment from "moment/moment";
import { Box, Button, Card } from "@mui/material";
import PageHeader from "../../../components/PageHeader";
import { PlusOutlined } from "@ant-design/icons";
import CustomDialog from "../../../components/CustomDialog";
import { useDialog } from "../../../hooks/useDialog";
import CustomSearchTableList from "../../../components/CustomSearchTableList";
import Form from "./Conmponents/Modal/Form";
import { useFetchPrefixes } from "../../../hooks/usePrefix";
import TableLists from "./Features/TableLists";
import { useSearch } from "../../../hooks/useSearch";

const Prefix = () => {
  const { handleRefresh, handleFiltersChange, state } = useSearch();
  const data = useFetchPrefixes(state);
  const dialog = useDialog();
  return (
    <>
      <PageHeader
        title={`ข้อมูลคำนำหน้า`}
        routes={[{ title: `ข้อมูลคำนำหน้า` }]}
        createButton={
          <>
            <Button
              onClick={() => dialog.handleOpen({ open: true })}
              startIcon={<PlusOutlined />}
              variant="contained"
            >
              สร้างข้อมูลคำนำหน้าใหม่
            </Button>
            <CustomDialog
              size={"sm"}
              title="สร้างข้อมูลคำนำหน้าใหม่"
              onClose={dialog.handleClose}
              open={dialog.open}
            >
              <Form dialog={dialog} onRefresh={handleRefresh} />
            </CustomDialog>
          </>
        }
      />
      <Box sx={{ flexGrow: 1 }}>
        <Card>
          <CustomSearchTableList
            placeholder={`ค้นหา`}
            onFiltersChange={handleFiltersChange}
          />
          <TableLists
            count={data.count}
            items={data.data}
            loading={data.loading}
            onRefresh={handleRefresh}
            page={data.page}
          />
        </Card>
      </Box>
    </>
  );
};

export default Prefix;
