export const downloadExcel = async (filters, endpoint) => {
  const queryString = convertObjectToQueryString({
    export: 1,
    filters: filters,
  });

  const url = `${process.env.REACT_APP_API_SERVICE}/export/${endpoint}?${queryString}`;

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${endpoint}.xlsx`); // Set the desired file name
  document.body.appendChild(link);
  link.click();
  link?.parentNode?.removeChild(link);
};

function convertObjectToQueryString(obj, parentKey) {
  return Object.keys(obj)
    .map((key) => {
      const propKey = parentKey ? `${parentKey}[${key}]` : key;

      if (!obj[key]) {
        return;
      }

      if (typeof obj[key] === "object" && obj[key] !== null) {
        return convertObjectToQueryString(obj[key], propKey);
      } else {
        if (obj[key]) {
          return `${propKey}=${encodeURIComponent(obj[key])}`;
        }
      }
    })
    .join("&");
}
