import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import PageHeader from "../../../components/PageHeader";
import { useReportVillage } from "../../../hooks/useReport";
import { useSearch } from "../../../hooks/useSearch";
import { useFetchPersonals } from "../../../hooks/usePersonal";
import PieChart from "../../../components/ReCharts/PieChart";
import CustomSearchTableList from "../../../components/CustomSearchTableList";
import TableLists from "../../SettingPage/Village/Features/TableLists";
import { useFetchVillages } from "../../../hooks/useVillage";
import TablePaginate from "../../../components/TablePaginate";

const initSearch = {};

const ReportVillage = () => {
  const {
    handleRefresh,
    handleFiltersChange,
    state,
    handleSetFilters,
    handleSetPage,
    handleSetPerPage,
  } = useSearch();
  const data = useFetchVillages(state);
  const report = useReportVillage(state);

  const handleSearch = (value: any) => {};

  return (
    <>
      <PageHeader
        title={`รายงานหมู่บ้าน/ สถานที่`}
        routes={[{ title: `รายงานหมู่บ้าน/ สถานที่` }]}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Card sx={{ mb: 4 }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <PieChart data={report.data} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Box mb={4}>
                  <Typography variant={`h5`} pb={1}>
                    จำนวนหมู่บ้านทั้งหมด
                  </Typography>
                  <Typography variant={`h4`}>
                    {report?.data?.reduce((accumulator, currentObject: any) => {
                      return accumulator + (currentObject?.count ?? 0);
                    }, 0)}{" "}
                    หมู่บ้าน
                  </Typography>
                </Box>
                <Grid container spacing={3}>
                  {report.data.map((item: any, index: number) => (
                    <Grid
                      item
                      xs={12}
                      lg={3}
                      sx={{ color: item.color }}
                      key={index}
                    >
                      <Typography variant={`h6`}>{item?.name}</Typography>
                      <Typography variant={`h5`}>
                        {item?.count} หมู่บ้าน
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/*<InformPage handleSearch={handleSearch} hideHeader={true} />*/}
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <Card>
          <CustomSearchTableList
            placeholder={`ค้นหา`}
            onFiltersChange={handleFiltersChange}
          />
          <TableLists
            editable={false}
            count={data.count}
            items={data.data}
            loading={data.loading}
            onRefresh={handleRefresh}
            page={data.page}
          />
        </Card>
      </Box>
      <Box py={2}>
        <TablePaginate
          handleSetPage={handleSetPage}
          handleSetPerPage={handleSetPerPage}
          count={data.count}
          perPage={data.perPage}
          currentPage={data.page}
        />
      </Box>
    </>
  );
};

export default ReportVillage;
