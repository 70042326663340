import { useWhiteBox } from "../../../../../../hooks/useWhiteBox";
import { CircularProgress, Stack, Typography } from "@mui/material";

const QrCode = ({ id }: { id: string | undefined }) => {
  const whtieBox = useWhiteBox(id);
  return (
    <>
      {whtieBox.loading ? (
        <Typography
          sx={{
            display: `flex`,
            alignItems: `center`,
            justifyContent: `center`,
          }}
          variant="subtitle2"
        >
          <CircularProgress color="success" size={32} sx={{ mr: 2 }} />{" "}
          กำลังโหลดข้อมูล...
        </Typography>
      ) : (
        <Stack alignItems={`center`} justifyContent={`center`}>
          <object
            data={whtieBox?.data?.qrCode}
            width={320}
            height={320}
            type="image/svg+xml"
          >
            SVG not supported
          </object>
        </Stack>
      )}

      {/*<img src={`http://nontgis.local:8100/api/white-box/qrcode/649e5ca63b56dd0831077046.svg`} type="image/svg+xml" alt="" />*/}
    </>
  );
};

export default QrCode;
