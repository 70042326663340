import {
  Box,
  Button,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  SvgIcon,
  Typography,
} from "@mui/material";
import { useMockedUser } from "../index";
import { Link, useNavigate } from "react-router-dom";
import CreditCard01Icon from "@untitled-ui/icons-react/build/esm/CreditCard01";
import Settings04Icon from "@untitled-ui/icons-react/build/esm/Settings04";
import User03Icon from "@untitled-ui/icons-react/build/esm/User03";
import { FiLock, FiSettings, FiUser } from "react-icons/fi";
import { useCallback } from "react";
import request from "../../../../../utils/request";
import toast from "react-hot-toast";
import { Modal } from "antd";
import { errorMessageTranform } from "../../../../../utils/error";
import store from "store2";

interface props {
  user: any;
  anchorEl: any;
  onClose: () => void;
  open: boolean;
}

const AccountPopover = (props: props) => {
  const { anchorEl, onClose, open, user, ...other } = props;
  // const user = useMockedUser();

  const handleLogout = useCallback(async () => {
    let options = {
      url: "/api/logout",
      method: "POST",
    };

    try {
      await request(options);
      store.remove("jwtToken");
      window.location.href = "/login";
    } catch (e: any) {
      Modal.error({
        title: "เกิดข้อผิดพลาด",
        content: errorMessageTranform(
          e?.response?.data?.errors ?? e.response?.data?.message ?? e.message
        ),
      });
    }
  }, []);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 200 } }}
      {...other}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="subtitle2">{user.username}</Typography>
        <Typography color="text.secondary" fontSize={12} variant="body2">
          {user?.firstName} {user?.lastName}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 1 }}>
        <ListItemButton
          component={Link}
          to={"/profile"}
          onClick={onClose}
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5,
          }}
        >
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <FiUser />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="subtitle2">แก้ไขข้อมูลส่วนตัว</Typography>
            }
          />
        </ListItemButton>
        {/*<ListItemButton*/}
        {/*  component={Link}*/}
        {/*  to={"/settings"}*/}
        {/*  onClick={onClose}*/}
        {/*  sx={{*/}
        {/*    borderRadius: 1,*/}
        {/*    px: 1,*/}
        {/*    py: 0.5,*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <ListItemIcon>*/}
        {/*    <SvgIcon fontSize="small">*/}
        {/*      <FiSettings />*/}
        {/*    </SvgIcon>*/}
        {/*  </ListItemIcon>*/}
        {/*  <ListItemText*/}
        {/*    primary={<Typography variant="subtitle2">ตั้งค่า</Typography>}*/}
        {/*  />*/}
        {/*</ListItemButton>*/}
      </Box>
      <Divider sx={{ my: "0 !important" }} />
      <Box
        sx={{
          display: "flex",
          p: 1,
          justifyContent: "center",
        }}
      >
        <Button
          variant={"contained"}
          color="error"
          size="small"
          onClick={() => handleLogout()}
          startIcon={<FiLock />}
        >
          ออกจากระบบ
        </Button>
      </Box>
    </Popover>
  );
};

export default AccountPopover;
