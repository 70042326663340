import { useState } from "react";
import { TablePagination } from "@mui/material";

interface Props {
  currentPage: number;
  count: number;
  perPage?: number;
  handleSetPage: (filters: any) => void;
  handleSetPerPage: (filters: any) => void;
}

const TablePaginate = (props: Props) => {
  const {
    currentPage,
    perPage = 15,
    count = 0,
    handleSetPage,
    handleSetPerPage,
  } = props;

  const [page, setPage] = useState(currentPage);
  const [rowsPerPage, setRowsPerPage] = useState(perPage);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    handleSetPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    handleSetPerPage(parseInt(event.target.value, 10));
    handleSetPage(1);
    setPage(0);
  };

  return (
    <TablePagination
      rowsPerPageOptions={[15, 25, 50, 100]}
      labelRowsPerPage={`แสดงข้อมูลต่อหน้า`}
      component="div"
      count={count}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default TablePaginate;
