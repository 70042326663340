import { SearchProps } from "../../../../../../interface";
import { ChangeEvent, useCallback, useRef, useState } from "react";
import SearchMdIcon from "@untitled-ui/icons-react/build/esm/SearchMd";
import {
  Box,
  Button,
  InputAdornment,
  OutlinedInput,
  Stack,
  SvgIcon,
  TextField,
} from "@mui/material";
import { useUpdateEffect } from "../../../../../hooks/useUpdateEffect";

const UserListSearch = (props: SearchProps) => {
  const { onFiltersChange, onSortChange, sortBy, sortDir } = props;
  const queryRef = useRef(null);
  const [filters, setFilters] = useState({});
  const [data, setData] = useState({
    search: "",
  });

  const handleFiltersUpdate = useCallback(() => {
    onFiltersChange?.(filters);
  }, [filters, onFiltersChange]);

  useUpdateEffect(() => {
    handleFiltersUpdate();
  }, [filters, handleFiltersUpdate]);

  const handleQueryChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      setFilters((prevState) => ({
        ...prevState,
        query: data?.search,
      }));
    },
    []
  );

  const handleSortChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const [sortBy, sortDir] = event.target.value.split("|");

      onSortChange?.({
        sortBy,
        sortDir,
      });
    },
    [onSortChange]
  );

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSearch = useCallback(() => {
    setFilters((prevState) => ({
      ...prevState,
      query: data?.search,
    }));
  }, [data?.search]);

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        flexWrap="wrap"
        spacing={3}
        sx={{ p: 3 }}
      >
        <Box component="form" onSubmit={handleQueryChange} sx={{ flexGrow: 1 }}>
          <OutlinedInput
            defaultValue=""
            fullWidth
            name={"search"}
            onChange={(e) => handleInputChange(e)}
            placeholder="ค้นหาจาก ชื่อ-นามสกุล หรือ อีเมล"
            startAdornment={
              <InputAdornment position="start">
                <SvgIcon>
                  <SearchMdIcon />
                </SvgIcon>
              </InputAdornment>
            }
          />
        </Box>
        <Button
          variant={"contained"}
          color={"primary"}
          size={"large"}
          startIcon={<SearchMdIcon />}
          type={"submit"}
          onClick={() => handleSearch()}
        >
          ค้นหา
        </Button>
      </Stack>
    </>
  );
};

export default UserListSearch;
