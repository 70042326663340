import request from "../../utils/request";
import { useCallback, useEffect, useState } from "react";
import { LogsInterface } from "../../types/logs";

export interface FetchLogsInterface {
  data: LogsInterface[];
  count: number;
  page: number;
  loading: boolean;
  perPage?: number;
}

export const fetchLogs = async (search = {}) => {
  return await request({
    url: "/api/logs",
    method: "GET",
    params: search,
  });
};

export const useFetchLogs = (search = {}) => {
  const [data, setData] = useState<FetchLogsInterface>({
    data: [],
    count: 0,
    page: 0,
    perPage: 15,
    loading: true,
  });

  const fetch = useCallback(async () => {
    setData((prev) => ({ ...prev, loading: true }));
    try {
      const response = await fetchLogs(search);
      setData({
        data: response?.data ?? response ?? [],
        count: response.total || 0,
        page: response?.currentPage ?? 0,
        perPage: response?.perPage ?? 15,
        loading: false,
      });
    } catch (e) {}
  }, [search]);

  useEffect(() => {
    fetch();
  }, [search]);

  return data;
};

export const useFetchLog = (id: string) => {
  const [data, setData] = useState<{
    data: LogsInterface[];
    count: number;
    page: number;
    loading: boolean;
    perPage?: number;
  }>({
    data: [],
    count: 0,
    page: 0,
    perPage: 15,
    loading: true,
  });
  useEffect(() => {
    setData(
      useFetchLogs({
        id,
      })
    );
  }, [id]);

  return data;
};
