import { useWhiteBox } from "../../../../../../hooks/useWhiteBox";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import QRCode from "qrcode.react";
import { useRef } from "react";

const QrCode = ({ id }: { id: string | undefined }) => {
  const whtieBox = useWhiteBox(id);

  const qrCodeValue = "https://nont-gis.com/?boxId=" + id;

  const handelDownloadSvgToJpg = () => {
    const canvas: any = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `qrcode-${whtieBox.data?.code ?? "none"}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  return (
    <>
      {whtieBox.loading ? (
        <Typography
          sx={{
            display: `flex`,
            alignItems: `center`,
            justifyContent: `center`,
          }}
          variant="subtitle2"
        >
          <CircularProgress color="success" size={32} sx={{ mr: 2 }} />{" "}
          กำลังโหลดข้อมูล...
        </Typography>
      ) : (
        <>
          <Stack alignItems={`center`} pb={3} justifyContent={`center`}>
            <QRCode id="qr-gen" size={320} value={qrCodeValue} />
            {/*<object*/}
            {/*  data={whtieBox?.data?.qrCode}*/}
            {/*  width={320}*/}
            {/*  height={320}*/}
            {/*  type="image/svg+xml"*/}
            {/*>*/}
            {/*  SVG not supported*/}
            {/*</object>*/}
          </Stack>
          <Box justifyContent={`center`} textAlign={`center`}>
            <Button
              variant={`contained`}
              onClick={() => handelDownloadSvgToJpg()}
            >
              ดาวน์โหลด
            </Button>
          </Box>
        </>
      )}

      {/*<img src={`http://nontgis.local:8100/api/white-box/qrcode/649e5ca63b56dd0831077046.svg`} type="image/svg+xml" alt="" />*/}
    </>
  );
};

export default QrCode;
