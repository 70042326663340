import sizeConfigs from "../../../../theme/sidebar/sizeConfig";
import { Box, Drawer, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Scrollbar } from "../../SimpleBar";
import { Link } from "react-router-dom";
import useSections, { SectionProps } from "../../../../routes/section";
import SidebarNavSection from "../SidebarNavSection";
import { usePathname } from "../../../hooks/useMobileNav";
import { useEffect } from "react";
import { Logo } from "../../Logo";

const SIDE_NAV_WIDTH = sizeConfigs.sidebar.width;

export const useCssVars = () => {
  return {
    "--nav-bg": "#1C2536",
    "--nav-color": "#fff",
    "--nav-border-color": "transparent",
    "--nav-logo-border": "rgba(28,37,54,0.84)",
    "--nav-section-title-color": "#9da4ae",
    "--nav-item-color": "#9da4ae",
    "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
    "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
    "--nav-item-active-color": "#fff",
    "--nav-item-disabled-color": "rgba(28,37,54,0.5)",
    "--nav-item-icon-color": "#9da4ae",
    "--nav-item-icon-active-color": "#c297ed",
    "--nav-item-icon-disabled-color": "rgba(28,37,54,0.5)",
    "--nav-item-chevron-color": "#4d5761",
    "--nav-scrollbar-color": "rgba(28,37,54,0.4)",
  };
};

const SidebarNav = ({ sections }: { sections: SectionProps[] }) => {
  const pathname = usePathname();
  const cssVars = useCssVars();

  return (
    <Drawer
      anchor="left"
      open
      PaperProps={{
        sx: {
          ...cssVars,
          backgroundColor: "var(--nav-bg)",
          borderRightColor: "var(--nav-border-color)",
          borderRightStyle: "solid",
          borderRightWidth: 1,
          color: "var(--nav-color)",
          width: SIDE_NAV_WIDTH,
        },
      }}
      variant="permanent"
    >
      <Scrollbar
        sx={{
          height: "100%",
          "& .simplebar-content": {
            height: "100%",
          },
          "& .simplebar-scrollbar:before": {
            background: "var(--nav-scrollbar-color)",
          },
        }}
      >
        <Stack sx={{ height: "100%" }}>
          <Stack alignItems="center" direction="row" spacing={2} sx={{ p: 3 }}>
            <Box
              component={Link}
              to={"/"}
              sx={{
                borderColor: "var(--nav-logo-border)",
                borderRadius: 1,
                borderStyle: "solid",
                borderWidth: 1,
                display: "flex",
                height: 40,
                p: "4px",
                width: 40,
              }}
            >
              <Logo />
            </Box>

            <Stack alignItems="center" direction="row" spacing={2}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography color="inherit" variant="h6">
                  Nont GIS
                </Typography>
                <Typography color="neutral.400" fontSize={11} variant="body2">
                  version 0.0.1-beta
                </Typography>
              </Box>
            </Stack>
          </Stack>
          <Stack
            component="nav"
            spacing={2}
            sx={{
              flexGrow: 1,
              px: 2,
            }}
          >
            {sections.map((section, index) => {
              // console.log(section);
              return (
                <SidebarNavSection
                  items={section.items}
                  access={section.access}
                  key={index}
                  pathname={pathname}
                  subheader={section.subheader}
                />
              );
            })}
          </Stack>
        </Stack>
      </Scrollbar>
    </Drawer>
  );
};

export default SidebarNav;
