import { ReactNode, useContext, useMemo } from "react";
import { SvgIcon } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  FiBarChart,
  FiBell,
  FiBox,
  FiHome,
  FiLock,
  FiOctagon,
  FiStar,
  FiUser,
  FiUsers,
} from "react-icons/fi";
import { RiDashboard3Line, RiUser3Line } from "react-icons/ri";
import { GoInfo, GoWorkflow } from "react-icons/go";
import { AbilityContext } from "../app/components/Ability";

export interface SectionItemProps {
  title: string;
  access?: boolean;
  path: string;
  icon?: ReactNode;
  items?: SectionItemProps[];
  disabled?: boolean;
  label?: string;
  external?: string;
}

export interface SectionProps {
  items?: SectionItemProps[];
  access?: boolean;
  key?: any;
  pathname?: string;
  path?: string;
  title?: string;
  subheader?: string;
  label?: string;
  external?: string;
  active?: boolean;
  disabled?: boolean;
  open?: boolean;
  depth?: number;
  icon?: ReactNode;
  children?: ReactNode;
  element?: ReactNode;
}

const useSections = (): SectionProps[] => {
  const { t } = useTranslation();
  const ability = useContext(AbilityContext);
  return useMemo<SectionProps[]>(() => {
    return [
      {
        items: [
          {
            title: "แจ้งเหตุ",
            access: ability.can("read", "informInfo"),
            path: "/inform",
            icon: (
              <SvgIcon fontSize={"small"}>
                <FiBell />
              </SvgIcon>
            ),
          },
          {
            title: "ข้อมูลบุคคล",
            access: ability.can("read", "userInfo"),
            path: "/personal",
            icon: (
              <SvgIcon fontSize={"small"}>
                <RiUser3Line />
              </SvgIcon>
            ),
          },
          {
            title: "ข้อมูลหมู่บ้าน",
            access: ability.can("read", "villageInfo"),
            path: "/setting/villages",
            icon: (
              <SvgIcon fontSize={"small"}>
                <FiHome />
              </SvgIcon>
            ),
          },
          {
            title: "ข้อมูลตู้ขาว",
            access: ability.can("read", "whiteboxInfo"),
            path: "/setting/white-box",
            icon: (
              <SvgIcon fontSize={"small"}>
                <FiBox />
              </SvgIcon>
            ),
          },
        ],
      },
      {
        subheader: "รายงาน",
        access: ability.can("read", "report"),
        items: [
          {
            title: "รายงานข้อมูล",
            path: "/report",
            access: ability.can("read", "report"),
            icon: (
              <SvgIcon fontSize={"small"}>
                <FiBarChart />
              </SvgIcon>
            ),
            items: [
              {
                title: "รายงานการแจ้งเหตุ",
                access: ability.can("read", "report"),
                path: "/report/inform",
              },
              {
                title: "รายงานบุคคล",
                access: ability.can("read", "report"),
                path: "/report/personal",
              },
              {
                title: "รายงานหมู่บ้าน/ สถานที่",
                access: ability.can("read", "report"),
                path: "/report/village",
              },
              {
                title: "รายงานตู้ขาว",
                access: ability.can("read", "report"),
                path: "/report/whitebox",
              },
            ],
          },
        ],
      },
      {
        subheader: "ผู้ใช้งาน",
        access: ability.can("read", "userManagement"),
        items: [
          {
            title: "จัดการข้อมูลผู้ใช้งาน",
            path: "/users",
            access: ability.can("read", "userManagement"),
            icon: (
              <SvgIcon fontSize={"small"}>
                <FiUser />
              </SvgIcon>
            ),
            items: [
              {
                title: "รายชื่อผู้ใช้งาน",
                path: "/users",
                access: ability.can("read", "userManagement"),
              },
              {
                title: "สร้างผู้ใช้งาน",
                path: "/users/create",
                access: ability.can("create", "userManagement"),
              },
            ],
          },
        ],
      },
      {
        subheader: "ตั้งค่า",
        access: ability.can("read", "setting"),
        items: [
          {
            title: "กลุ่มประเภทบุคคล",
            path: "/setting/personalType",
            access: ability.can("read", "setting"),
            icon: (
              <SvgIcon fontSize={"small"}>
                <FiUsers />
              </SvgIcon>
            ),
          },
          {
            title: "คำนำหน้า",
            path: "/setting/prefix",
            access: ability.can("read", "setting"),
            icon: (
              <SvgIcon fontSize={"small"}>
                <FiStar />
              </SvgIcon>
            ),
          },
          {
            title: "ข้อมูลหน่วยงาน",
            path: "/setting/departments",
            access: ability.can("read", "setting"),
            icon: (
              <SvgIcon fontSize={"small"}>
                <GoWorkflow />
              </SvgIcon>
            ),
          },
          // {
          //   title: "ข้อมูล Logs",
          //   path: "/setting/logs",
          //   access: ability.can("read", "setting"),
          //   icon: (
          //     <SvgIcon fontSize={"small"}>
          //       <GoInfo />
          //     </SvgIcon>
          //   ),
          // },
        ],
      },
    ];
  }, [t]);
};
export default useSections;
