import { useCallback, useState } from "react";
import moment from "moment";

export const useSearch = () => {
  const [state, setState] = useState({
    filters: {
      query: undefined,
      refreshTimes: 0,
    },
  });
  const [callback, setCallback] = useState<any>();

  const handleRefresh = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        refreshTimes: moment().unix(),
      },
    }));
  }, []);

  const handleFiltersChange = useCallback((filters: any) => {
    setState((prevState) => ({
      ...prevState,
      filters,
    }));
  }, []);

  const handleSetFilters = (data: any) => {
    setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        ...data,
      },
    }));
  };

  const hadleCallbackSearch = (callback: any) => {
    setCallback(callback);
  };

  const handleSetPage = (page: number) => {
    setState((prevState) => ({
      ...prevState,
      page,
    }));
  };
  const handleSetPerPage = (perPage: number) => {
    setState((prevState) => ({
      ...prevState,
      perPage,
    }));
  };

  return {
    handleRefresh,
    handleFiltersChange,
    handleSetFilters,
    hadleCallbackSearch,
    handleSetPage,
    handleSetPerPage,
    state,
  };
};
