import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import request from "../../../utils/request";
import store from "store2";
import { Modal } from "antd";
import { LoadingButton } from "@mui/lab";
import { indigo } from "../../../theme/colors";
import { Logo } from "../../components/Logo";

const initialValues = {
  username: "",
  password: "",
  submit: null,
};

const validationSchema = Yup.object({
  username: Yup.string().max(255).required("username is required"),
  password: Yup.string().max(255).required("Password is required"),
});

const LoginPage = () => {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (value) => {
      try {
        const response = await request({
          url: "/api/login",
          method: "POST",
          data: {
            username: value.username,
            password: value.password,
          },
        });
        store.set("jwtToken", response.access_token);
        window.location.href = "/inform";
      } catch (e: any) {
        const statusCode = e.response.status;
        errorModal(statusCode);
      }
    },
  });

  const errorModal = (statusCode: number) => {
    const errorDetails = [
      {
        statusCode: 401,
        title: "การเข้าสู่ระบบล้มเหลว",
        content:
          "ไม่สามารถเข้าสู่ระบบได้ กรุณาตรวจสอบชื่อผู้ใช้ หรือรหัสผ่านอีกครั้ง",
      },
      {
        statusCode: 429,
        content:
          "ไม่สามารถเข้าสู่ระบบได้ เนื่องจากมีการร้องขอใช้ระบบมากเกินไป กรุณารอซักครู่",
      },
      {
        statusCode: 403,
        content: "ไม่สามารถเข้าสู่ระบบได้ เนื่องจากผู้ใช้นี้ถูกระงับการเข้าถึง",
      },
    ];
    const error = errorDetails.find((error) => error.statusCode === statusCode);
    Modal.error({
      title: "การเข้าสู่ระบบล้มเหลว!",
      content: error ? error.content : "กรุณาลองใหม่อีกครั้ง!",
    });
  };

  return (
    <>
      {/*<Seo title="Login" />*/}

      <Card elevation={16}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={3} display={`flex`} alignItems={`center`} justifyContent={`center`} sx={{ backgroundColor: indigo.main }}>
              <Logo size={180} />
            </Grid>
            <Grid item xs={9}>
              <CardHeader
                subheader={
                  <Typography color="text.secondary" variant="body2">
                    หากคุณยังไม่มีบัญชีผู้ใช้งาน กรุณา
                    <Link href="#" underline="hover" variant="subtitle2">
                      ติดต่อผู้ดูแลระบบ
                    </Link>
                  </Typography>
                }
                sx={{ pb: 0 }}
                title="เข้าสู่ระบบ Nonthaburi GIS"
              />
              <CardContent>
                <form noValidate onSubmit={formik.handleSubmit}>
                  <Stack spacing={3}>
                    <TextField
                      autoFocus
                      error={
                        !!(formik.touched.username && formik.errors.username)
                      }
                      fullWidth
                      helperText={
                        formik.touched.username && formik.errors.username
                      }
                      label="ชื่อผู้ใช้งาน"
                      name="username"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="email"
                      value={formik.values.username}
                    />
                    <TextField
                      error={
                        !!(formik.touched.password && formik.errors.password)
                      }
                      fullWidth
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      label="รหัสผ่าน"
                      name="password"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="password"
                      value={formik.values.password}
                    />
                  </Stack>
                  <LoadingButton
                    disabled={formik.isSubmitting}
                    loading={formik.isSubmitting}
                    fullWidth
                    size="large"
                    sx={{ mt: 2 }}
                    type="submit"
                    variant="contained"
                    color={`primary`}
                  >
                    <span>เข้าสู่ระบบ</span>
                  </LoadingButton>
                </form>
              </CardContent>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );
};

export default LoginPage;
