import { DepartmentInterface } from "../../../../../../../types/department";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Save } from "@mui/icons-material";
import request from "../../../../../../../utils/request";
import toast from "react-hot-toast";
import { Modal } from "antd";
import { errorMessageTranform } from "../../../../../../../utils/error";
import AddressAuto from "../../../../../../components/AddressAuto";

interface Props {
  data?: DepartmentInterface;
  loading?: boolean;
  onRefresh: () => void;
  dialog: any;
}

const DeptartmentForm = (props: Props) => {
  const { data, loading, onRefresh, dialog } = props;

  const initialValues = {
    name: data?.name ?? "",
    address: data?.address ?? "",
    district: data?.district ?? "",
    province: data?.province ?? "",
    subdistrict: data?.subdistrict ?? "",
    phone: data?.phone ?? "",
  };

  let yupObject = {
    name: Yup.string().required(`กรุณากรอกชื่อ`),
    address: Yup.string().required("กรุณากรอกที่อยู่"),
    phone: Yup.string().required("กรุณากรอกหมายเลขโทรศัพท์"),
    district: Yup.string().required("กรุณากรอกอำเภอ"),
    province: Yup.string().required("กรุณากรอกจังหวัด"),
    subdistrict: Yup.string().required("กรุณากรอกตำบล"),
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object(yupObject),
    onSubmit: async (values) => {
      let options = {
        url: "/api/setting/department",
        method: "POST",
        data: values,
      };
      if (typeof data?._id !== "undefined") {
        options.method = "PUT";
        options.url = `/api/setting/department/${data?._id}`;
      }
      try {
        await request(options);
        toast.success("บันทึกข้อมูลสำเร็จ");
        onRefresh();
        dialog.handleClose();
      } catch (e: any) {
        Modal.error({
          title: "เกิดข้อผิดพลาด",
          content: errorMessageTranform(
            e?.response?.data?.errors ?? e.response?.data?.message ?? e.message
          ),
        });
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container mt={0} spacing={2}>
        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            disabled={formik.isSubmitting || loading}
            name="name"
            label="ชื่อหน่วยงาน"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            disabled={formik.isSubmitting || loading}
            name="phone"
            label="หมายเลขโทรศัพท์"
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
          />
        </Grid>



        {/*<Grid item xs={12} lg={6}>*/}
        {/*  <TextField*/}
        {/*    fullWidth*/}
        {/*    disabled={formik.isSubmitting || loading}*/}
        {/*    name="district"*/}
        {/*    label="อำเภอ"*/}
        {/*    value={formik.values.district}*/}
        {/*    onChange={formik.handleChange}*/}
        {/*    error={formik.touched.district && Boolean(formik.errors.district)}*/}
        {/*    helperText={formik.touched.district && formik.errors.district}*/}
        {/*  />*/}
        {/*</Grid>*/}
        <Grid item xs={12}>
          <TextField
            fullWidth
            disabled={formik.isSubmitting || loading}
            name="address"
            label="ที่อยู่"
            value={formik.values.address}
            onChange={formik.handleChange}
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
            multiline={true}
            rows={5}
          />
        </Grid>
        <AddressAuto
          formik={formik}
          disabled={formik.isSubmitting || loading}
          lg={12}
          xs={12}
          name={{
            province: `province`,
            district: `district`,
            subdistrict: `subdistrict`,
          }}
          value={{
            province: formik.values.province,
            district: formik.values.district,
            subdistrict: formik.values.subdistrict,
          }}
          touched={{
            province: formik.touched?.province,
            district: formik.touched?.district,
            subdistrict: formik.touched?.subdistrict,
          }}
          errors={{
            province: formik.errors?.province,
            district: formik.errors?.district,
            subdistrict: formik.errors?.subdistrict,
          }}
        />
        <Grid item xs={12}>
          <LoadingButton
            loading={formik.isSubmitting || loading}
            disabled={formik.isSubmitting || loading}
            type={"submit"}
            size={`small`}
            variant={`contained`}
            color={`primary`}
            startIcon={<Save />}
          >
            บันทึก
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default DeptartmentForm;
