import { Box, Stack } from "@mui/material";
import { SectionItemProps, SectionProps } from "../../../../routes/section";
import { ReactNode } from "react";
import SidebarNavItem from "../SidebarNavItem";

const renderItems = ({
  depth = 0,
  items = [],
  pathname = "",
  access = false,
}: {
  depth?: number;
  items?: SectionItemProps[];
  pathname?: string;
  access?: boolean;
}) =>
  items?.reduce(
    (acc: any, item: any) =>
      reduceChildRoutes({
        acc,
        depth,
        item,
        pathname,
        access,
      }),
    []
  );

const reduceChildRoutes = ({
  acc,
  depth,
  item,
  pathname,
  access,
}: {
  acc: ReactNode[];
  depth: number;
  item: SectionItemProps;
  pathname: string;
  access?: boolean;
}) => {
  const checkPath = !!(item.path && pathname);
  const partialMatch = checkPath ? pathname.includes(item.path) : false;
  const exactMatch = checkPath ? pathname === item.path : false;

  // console.log(partialMatch, exactMatch, item.path, pathname);
  if (item.access) {
    if (typeof item.items !== `undefined`) {
      acc.push(
        <SidebarNavItem
          active={partialMatch}
          depth={depth}
          disabled={item.disabled}
          icon={item.icon}
          key={item.title}
          label={item.label}
          open={partialMatch}
          title={item.title}
          access={item.access}
        >
          <Stack
            component="ul"
            spacing={0.5}
            sx={{
              listStyle: "none",
              m: 0,
              p: 0,
            }}
          >
            {renderItems({
              depth: depth + 1,
              items: item.items,
              pathname,
              access: item.access,
            })}
          </Stack>
        </SidebarNavItem>
      );
    } else {
      acc.push(
        <SidebarNavItem
          active={exactMatch}
          access={item.access}
          depth={depth}
          disabled={item.disabled}
          external={item.external}
          icon={item.icon}
          key={item.title}
          label={item.label}
          path={item.path}
          title={item.title}
        />
      );
    }
  }

  return acc;
};

const SidebarNavSection = (props: SectionProps) => {
  const {
    items = [],
    pathname,
    subheader = "",
    access = false,
    ...other
  } = props;
  return (
    <Stack
      component="ul"
      spacing={0.5}
      sx={{
        listStyle: "none",
        m: 0,
        p: 0,
      }}
      {...other}
    >
      {subheader && access && (
        <Box
          component="li"
          sx={{
            color: "var(--nav-section-title-color)",
            fontSize: 12,
            fontWeight: 700,
            lineHeight: 1.66,
            mb: 1,
            ml: 1,
            textTransform: "uppercase",
          }}
        >
          {subheader}
        </Box>
      )}
      {renderItems({ items, pathname, access })}
    </Stack>
  );
};

export default SidebarNavSection;
