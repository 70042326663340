import { Box, Container, Stack, styled } from "@mui/material";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { Logo } from "../../components/Logo";

const AuthLayout = ({ children }: { children: ReactNode }) => {
  return (
    <LayoutRoot>
      <Box
        component="header"
        sx={{
          left: 0,
          position: "fixed",
          right: 0,
          top: 0,
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        <Container maxWidth="lg">
          <Stack
            direction="row"
            justifyContent={"center"}
            spacing={2}
            sx={{ height: 64 }}
          >
            <Stack
              alignItems="center"
              justifyContent={"center"}
              component={Link}
              direction="row"
              display="inline-flex"
              to={"/"}
              spacing={1}
              sx={{ textDecoration: "none" }}
            >
              <Box
                sx={{
                  display: "inline-flex",
                  height: 24,
                  width: 24,
                }}
              >
                <Logo />
              </Box>
              <Box
                sx={{
                  color: "text.primary",
                  fontFamily: "'Plus Jakarta Sans', sans-serif",
                  fontSize: 14,
                  fontWeight: 800,
                  letterSpacing: "0.3px",
                  lineHeight: 2.5,
                  "& span": {
                    color: "primary.main",
                  },
                }}
              >
                NONT <span>GIS</span>
              </Box>
            </Stack>
          </Stack>
        </Container>
      </Box>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          flex: "1 1 auto",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            py: {
              xs: "60px",
              md: "100px",
            },
          }}
        >
          {children}
        </Container>
      </Box>
    </LayoutRoot>
  );
};

export default AuthLayout;

const LayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top center",
  backgroundImage: 'url("/assets/gradient-bg.svg")',
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  height: "100%",
}));
