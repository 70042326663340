import * as Yup from "yup";
import { useFormik } from "formik";
import { Box, Grid, MenuItem, TextField } from "@mui/material";
import request from "../../../../../../../utils/request";
import toast from "react-hot-toast";
import { Modal } from "antd";
import { errorMessageTranform } from "../../../../../../../utils/error";
import { WhiteBoxInterface } from "../../../../../../../types/whiteBox";
import { useFetchVillages } from "../../../../../../hooks/useVillage";
import MapMarker from "../../../../../../components/MapMarker";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Save } from "@mui/icons-material";
import InputColor from "react-input-color";
import AddressAuto from "../../../../../../components/AddressAuto";

interface Props {
  data?: WhiteBoxInterface | undefined;
  loading?: boolean;
  onRefresh: () => void;
  dialog: any;
  viewOnly?: boolean;
}

const search = {
  all: true,
};

const Form = (props: Props) => {
  const { data, loading, onRefresh, dialog, viewOnly = false } = props;
  const [isUpdate] = useState(typeof data?._id !== "undefined");
  const [currentVillage, setCurrentVillage] = useState<any>(
    data?.villageId ?? null
  );
  // const depts = useFetchDepartments(search)
  const villages = useFetchVillages(search);

  const initialValues = {
    code: data?.code ?? "",
    villageId: data?.villageId ?? "",
    subdistrict: data?.subdistrict ?? "",
    district: data?.district ?? "",
    province: data?.province ?? "",
    description: data?.description ?? "",
    latitude: data?.latitude ?? "",
    longitude: data?.longitude ?? "",
    googleMapUrl: data?.googleMapUrl ?? "",
    color: data?.color ?? "",
  };

  let yupObject = {
    code: Yup.string().required(`กรุณากรอก Code`),
    villageId: Yup.string().required(`กรุณากรอก หมู่บ้าน`),
    subdistrict: Yup.string().required(`กรุณากรอก ตำบล`),
    district: Yup.string().required(`กรุณากรอก อำเภอ`),
    province: Yup.string().required(`กรุณากรอก จังหวัด`),
    description: Yup.string().required(`กรุณากรอก หมายเหตุ`),
    latitude: Yup.string().required(`กรุณากรอก ละติจูด`),
    longitude: Yup.string().required(`กรุณากรอก ลองติจูด`),
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object(yupObject),
    onSubmit: async (values) => {
      let options = {
        url: "/api/white-box",
        method: "POST",
        data: values,
      };
      if (typeof data?._id !== "undefined") {
        options.method = "PUT";
        options.url = `${options.url}/${data?._id}`;
      }
      try {
        await request(options);
        toast.success("บันทึกข้อมูลสำเร็จ");
        onRefresh();
        dialog.handleClose();
      } catch (e: any) {
        Modal.error({
          title: "เกิดข้อผิดพลาด",
          content: errorMessageTranform(
            e?.response?.data?.errors ?? e.response?.data?.message ?? e.message
          ),
        });
      }
    },
  });

  const handleLocation = (location: {
    latitude: number;
    longitude: number;
    googleMapUrl: string;
  }) => {
    // console.log(location)
    formik.setFieldValue("latitude", `${location.latitude}`);
    formik.setFieldValue("longitude", `${location.longitude}`);
    formik.setFieldValue("googleMapUrl", location.googleMapUrl);
  };

  const handleOnVillageChange = (e: any) => {
    formik.handleChange(e);
    const village = villages?.data?.find(
      (village) => village._id === e.target.value
    );
    if (village) {
      setCurrentVillage(village);
      formik.setFieldValue("subdistrict", village.subdistrict);
      formik.setFieldValue("district", village.district);
      formik.setFieldValue("province", village.province);
    } else {
      setCurrentVillage(null);
      formik.setFieldValue("subdistrict", "");
      formik.setFieldValue("district", "");
      formik.setFieldValue("province", "");
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container mt={0} spacing={2}>
        <Grid item xs={12} lg={viewOnly ? 12 : 7}>
          <MapMarker
            viewOnly={viewOnly}
            position={
              isUpdate
                ? {
                    lat: formik.values.latitude,
                    lng: formik.values.longitude,
                  }
                : {}
            }
            handleLocation={handleLocation}
          />
        </Grid>
        <Grid item xs={12} lg={viewOnly ? 12 : 5}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={viewOnly ? 6 : 12}>
              <TextField
                className={viewOnly ? "text-label" : ""}
                InputProps={{
                  readOnly: viewOnly,
                }}
                fullWidth
                disabled={formik.isSubmitting || loading}
                name="code"
                label="Code"
                value={formik.values.code}
                onChange={formik.handleChange}
                error={formik.touched.code && Boolean(formik.errors.code)}
                helperText={formik.touched.code && formik.errors.code}
              />
            </Grid>
            <Grid item xs={12} lg={viewOnly ? 6 : 12}>
              {!viewOnly && (
                <Box mb={2}>
                  <InputColor
                    initialValue={formik.values.color}
                    onChange={(color: any) => {
                      formik.setFieldValue("color", color.hex);
                    }}
                    placement="right"
                  />
                </Box>
              )}
              <TextField
                className={viewOnly ? "text-label" : ""}
                InputProps={{
                  readOnly: viewOnly,
                }}
                fullWidth
                disabled={formik.isSubmitting || loading}
                name="รหัสสี"
                label="Color"
                value={formik.values.color}
                onChange={formik.handleChange}
                error={formik.touched.color && Boolean(formik.errors.color)}
                helperText={formik.touched.color && formik.errors.color}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <TextField
                className={viewOnly ? "text-label" : ""}
                InputProps={{
                  readOnly: viewOnly,
                }}
                fullWidth
                disabled={formik.isSubmitting || loading}
                name="villageId"
                label="หมู่บ้าน"
                value={formik.values.villageId}
                onChange={handleOnVillageChange}
                error={
                  formik.touched.villageId && Boolean(formik.errors.villageId)
                }
                helperText={formik.touched.villageId && formik.errors.villageId}
                select
              >
                <MenuItem value={""}>เลือกหมู่บ้าน</MenuItem>
                {villages?.data?.map((village) => (
                  <MenuItem key={village._id} value={village._id}>
                    {village.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {currentVillage ? (
              <>
                <Grid item xs={12} lg={12}>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    label="จังหวัด"
                    value={formik.values.province}
                    error={
                      formik.touched.province && Boolean(formik.errors.province)
                    }
                    helperText={
                      formik.touched.province && formik.errors.province
                    }
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    label="อำเภอ"
                    value={formik.values.district}
                    error={
                      formik.touched.district && Boolean(formik.errors.district)
                    }
                    helperText={
                      formik.touched.district && formik.errors.district
                    }
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    label="ตำบล"
                    value={formik.values.subdistrict}
                    error={
                      formik.touched.subdistrict &&
                      Boolean(formik.errors.subdistrict)
                    }
                    helperText={
                      formik.touched.subdistrict && formik.errors.subdistrict
                    }
                  />
                </Grid>
              </>
            ) : (
              <>
                <AddressAuto
                  viewOnly={viewOnly}
                  formik={formik}
                  disabled={formik.isSubmitting || loading}
                  lg={viewOnly ? 4 : 12}
                  xs={12}
                  name={{
                    province: `province`,
                    district: `district`,
                    subdistrict: `subdistrict`,
                  }}
                  value={{
                    province: formik.values.province,
                    district: formik.values.district,
                    subdistrict: formik.values.subdistrict,
                  }}
                  touched={{
                    province: formik.touched?.province,
                    district: formik.touched?.district,
                    subdistrict: formik.touched?.subdistrict,
                  }}
                  errors={{
                    province: formik.errors?.province,
                    district: formik.errors?.district,
                    subdistrict: formik.errors?.subdistrict,
                  }}
                />
              </>
            )}

            {/*<Grid item xs={12} lg={6}>*/}
            {/*  <TextField*/}
            {/*    fullWidth*/}
            {/*    disabled={formik.isSubmitting || loading}*/}
            {/*    name="subdistrict"*/}
            {/*    label="ตำบล"*/}
            {/*    value={formik.values.subdistrict}*/}
            {/*    onChange={formik.handleChange}*/}
            {/*    error={*/}
            {/*      formik.touched.subdistrict &&*/}
            {/*      Boolean(formik.errors.subdistrict)*/}
            {/*    }*/}
            {/*    helperText={*/}
            {/*      formik.touched.subdistrict && formik.errors.subdistrict*/}
            {/*    }*/}
            {/*  />*/}
            {/*</Grid>*/}
            {/*<Grid item xs={12} lg={6}>*/}
            {/*  <TextField*/}
            {/*    fullWidth*/}
            {/*    disabled={formik.isSubmitting || loading}*/}
            {/*    name="district"*/}
            {/*    label="อำเภอ"*/}
            {/*    value={formik.values.district}*/}
            {/*    onChange={formik.handleChange}*/}
            {/*    error={*/}
            {/*      formik.touched.district && Boolean(formik.errors.district)*/}
            {/*    }*/}
            {/*    helperText={formik.touched.district && formik.errors.district}*/}
            {/*  />*/}
            {/*</Grid>*/}
            <Grid item xs={12}>
              <TextField
                className={viewOnly ? "text-label" : ""}
                InputProps={{
                  readOnly: viewOnly,
                }}
                fullWidth
                disabled={formik.isSubmitting || loading}
                name="description"
                label="คำอธิบาย"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
                multiline={true}
                rows={viewOnly ? 2 : 4}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                className={viewOnly ? "text-label" : ""}
                InputProps={{
                  readOnly: viewOnly,
                }}
                fullWidth
                disabled={formik.isSubmitting || loading}
                name="latitude"
                label="Latitude"
                value={formik.values.latitude}
                onChange={formik.handleChange}
                error={
                  formik.touched.latitude && Boolean(formik.errors.latitude)
                }
                helperText={formik.touched.latitude && formik.errors.latitude}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                className={viewOnly ? "text-label" : ""}
                InputProps={{
                  readOnly: viewOnly,
                }}
                fullWidth
                disabled={formik.isSubmitting || loading}
                name="longitude"
                label="Longitude"
                value={formik.values.longitude}
                onChange={formik.handleChange}
                error={
                  formik.touched.longitude && Boolean(formik.errors.longitude)
                }
                helperText={formik.touched.longitude && formik.errors.longitude}
              />
            </Grid>
            {!viewOnly && (
              <Grid item xs={12}>
                <LoadingButton
                  loading={formik.isSubmitting || loading}
                  disabled={formik.isSubmitting || loading}
                  type={"submit"}
                  size={`small`}
                  variant={`contained`}
                  color={`primary`}
                  startIcon={<Save />}
                >
                  บันทึก
                </LoadingButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
