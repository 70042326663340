import { useTranslation } from "react-i18next";
import useUser from "./hooks/useUser";
import SplashScreenLoading from "./components/SplashScreenLoading";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { ConfigProvider } from "antd";
import thTH from "antd/lib/locale/th_TH";
import defineAbilityFor, { AbilityContext } from "./components/Ability";
import { routes } from "../routes";
import LoginPage from "./pages/LoginPage";
import AuthLayout from "./layouts/AuthLayout";
import { DashboardLayout } from "./layouts/DashboardLayout";
import useSections from "../routes/section";
import { Toaster } from "./components/Toaster";

const App = () => {
  const { i18n } = useTranslation();
  const { user, loading, isAuthentication } = useUser();

  if (loading) {
    return <SplashScreenLoading />;
  }

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - Dashboard"
        defaultTitle="Admin Dashboard"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name={`description`} content={`Admin Application`} />
      </Helmet>
      <ConfigProvider locale={thTH}>
        <AbilityContext.Provider value={defineAbilityFor(user)}>
          {isAuthentication ? (
            <Routes>
              <Route path="/" element={<DashboardLayout />}>
                {routes}
              </Route>
            </Routes>
          ) : (
            <Routes>
              <Route
                path="/login"
                element={
                  <AuthLayout>
                    <LoginPage />
                  </AuthLayout>
                }
              />
            </Routes>
          )}

          <Toaster />
        </AbilityContext.Provider>
      </ConfigProvider>
    </BrowserRouter>
  );
};

export default App;
