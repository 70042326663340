import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Pagination,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Modal } from "antd";
import toast from "react-hot-toast";
import Edit02Icon from "@untitled-ui/icons-react/build/esm/Edit02";

import { useDialog } from "../../../../hooks/useDialog";
import request from "../../../../../utils/request";
import { Scrollbar } from "../../../../components/SimpleBar";
import { TableProps } from "../../../../../interface";
import { FiCheck, FiList, FiTrash2 } from "react-icons/fi";
import { useState } from "react";
import CustomDialog from "../../../../components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import { PersonalInterface } from "../../../../../types/personal";
import { Link } from "react-router-dom";
import ArrowRightIcon from "@untitled-ui/icons-react/build/esm/ArrowRight";
import StatusIcon from "../../Components/StatusIcon";
import moment from "moment";
import LogsViewIconButton from "../../../../components/LogsViewIconButton";

const TableLists = (props: TableProps) => {
  const {
    items = [],
    onRefresh = () => {},
    loading = false,
    editable = true,
  } = props;
  const [current, setCurrent] = useState<PersonalInterface>();

  const dialogUpdated = useDialog();
  const dialogDeleted = useDialog();
  const dialogQrcode = useDialog();
  const dialogViolateForm = useDialog();

  const onDeleted = async () => {
    if (typeof current !== `undefined`) {
      try {
        await request({
          url: `/api/personal/${current._id}`,
          method: `DELETE`,
        });
        toast.success(`ลบข้อมูลสำเร็จ`);
        dialogDeleted.handleClose();
        onRefresh();
      } catch (e: any) {
        Modal.error({
          title: "เกิดข้อผิดพลาด",
          content: e.response?.data?.message ?? `Internal Server Error`,
        });
      }
    }
  };

  const handleOpenModal = (item: PersonalInterface) => {
    setCurrent(item);
    dialogUpdated.handleOpen({ open: true });
  };

  const handleOpenModalDeleted = (item: PersonalInterface) => {
    setCurrent(item);
    dialogDeleted.handleOpen({ open: true });
  };

  const handleOpenFormViolate = (item: PersonalInterface) => {
    setCurrent(item);
    dialogViolateForm.handleOpen({ open: true });
  };

  return (
    <Box sx={{ position: "relative" }}>
      <CustomDialog
        size={"xs"}
        title="แจ้งเตือน"
        onClose={dialogDeleted.handleClose}
        open={dialogDeleted.open}
      >
        <Typography variant={`subtitle2`}>ยืนยันการลบรายการ</Typography>

        <Box display="flex" justifyContent={`end`}>
          <LoadingButton
            onClick={() => onDeleted()}
            variant="contained"
            startIcon={<FiCheck />}
          >
            ยืนยัน
          </LoadingButton>
        </Box>
      </CustomDialog>

      <Scrollbar>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <TableCell width={150} align="center">
                วันที่
              </TableCell>
              <TableCell width={150} align="center">
                Code
              </TableCell>
              <TableCell width={300} align="center">
                สถานที่
              </TableCell>
              <TableCell align="center" sx={{ p: 2 }}>
                รายละเอียด
              </TableCell>
              <TableCell align="center">สถานะ</TableCell>
              <TableCell align="center">วันที่สร้าง</TableCell>
              {editable && (
                <TableCell align="center" width={200}>
                  Actions
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                  <Typography
                    sx={{
                      display: `flex`,
                      alignItems: `center`,
                      justifyContent: `center`,
                    }}
                    variant="subtitle2"
                  >
                    <CircularProgress
                      color="success"
                      size={32}
                      sx={{ mr: 2 }}
                    />{" "}
                    กำลังโหลดข้อมูล...
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              items.map((item) => {
                const location = `${item?.village?.address ?? ""} ต.${
                  item?.subdistrict
                } อ.${item?.district}`;
                const subjects = item?.subject?.join(`, `);
                return (
                  <TableRow hover key={item._id}>
                    <TableCell align={`center`}>{item?.date}</TableCell>
                    <TableCell align={`center`}>{item?.code || ``}</TableCell>
                    <TableCell>{location}</TableCell>
                    <TableCell align={`center`}>{subjects}</TableCell>
                    <TableCell align="center">
                      <StatusIcon status={item?.status} />
                    </TableCell>
                    <TableCell align={`center`}>
                      {moment(item.createdAt).format(`DD/MM/YYYY HH:mm`)}
                    </TableCell>
                    {editable && (
                      <TableCell align="center">
                        <IconButton component={Link} to={`/inform/${item._id}`}>
                          <SvgIcon>
                            <ArrowRightIcon />
                          </SvgIcon>
                        </IconButton>
                        <LogsViewIconButton id={item._id} />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </Scrollbar>
    </Box>
  );
};

export default TableLists;
