import { Box, Button, Card } from "@mui/material";
import PageHeader from "../../../components/PageHeader";
import { PlusOutlined } from "@ant-design/icons";
import CustomDialog from "../../../components/CustomDialog";
import { useDialog } from "../../../hooks/useDialog";
import CustomSearchTableList from "../../../components/CustomSearchTableList";
import Form from "./Conmponents/Modal/Form";
import TableLists from "./Features/TableLists";
import { useSearch } from "../../../hooks/useSearch";
import { useFetchWhiteBox } from "../../../hooks/useWhiteBox";
import { useCallback, useContext } from "react";
import { AbilityContext } from "../../../components/Ability";
import { downloadExcel } from "../../../../utils/excel";
import { Modal } from "antd";
import { FiDownload } from "react-icons/fi";
import TablePaginate from "../../../components/TablePaginate";

const WhiteBox = () => {
  const ability = useContext(AbilityContext);
  const {
    handleRefresh,
    handleFiltersChange,
    state,
    handleSetPage,
    handleSetPerPage,
  } = useSearch();
  const data = useFetchWhiteBox(state);
  const dialog = useDialog();

  const handleDownload = useCallback(async () => {
    try {
      await downloadExcel(state.filters, "white-box");
    } catch (e) {
      console.log(e);
      Modal.error({
        title: "เกิดข้อผิดพลาด",
        content: "ไม่สามารถดาวน์โหลดได้",
      });
    }
  }, [state]);

  return (
    <>
      <PageHeader
        title={`ตู้ขาว`}
        routes={[{ title: `ตู้ขาว` }]}
        createButton={
          <>
            {ability.can("create", "whiteboxInfo") && (
              <>
                <Button
                  onClick={() => dialog.handleOpen({ open: true })}
                  startIcon={<PlusOutlined />}
                  variant="contained"
                >
                  สร้างตู้ขาวใหม่
                </Button>
                <CustomDialog
                  overflow={true}
                  size={"md"}
                  title="สร้างตู้ขาวใหม่"
                  onClose={dialog.handleClose}
                  open={dialog.open}
                >
                  <Form dialog={dialog} onRefresh={handleRefresh} />
                </CustomDialog>
              </>
            )}
            <Button
              onClick={() => handleDownload()}
              startIcon={<FiDownload />}
              variant="contained"
              color={"success"}
            >
              ดาวน์โหลด Excel
            </Button>
          </>
        }
      />
      <Box sx={{ flexGrow: 1 }}>
        <Card>
          <CustomSearchTableList
            placeholder={`ค้นหา`}
            onFiltersChange={handleFiltersChange}
          />
          <TableLists
            count={data.count}
            items={data.data}
            loading={data.loading}
            onRefresh={handleRefresh}
            page={data.page}
          />
        </Card>
      </Box>
      <Box py={2}>
        <TablePaginate
          handleSetPage={handleSetPage}
          handleSetPerPage={handleSetPerPage}
          count={data.count}
          perPage={data.perPage}
          currentPage={data.page}
        />
      </Box>
    </>
  );
};

export default WhiteBox;
