import PageHeader from "../../../../components/PageHeader";
import { Link, useParams } from "react-router-dom";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import MapMarker from "../../../../components/MapMarker";
import { useCallback, useContext, useEffect, useState } from "react";
import moment from "moment";
import { InformInterface } from "../../../../../types/inform";
import { useInform } from "../../../../hooks/useInform";
import AutoCompetePersonal from "../../../../components/AutoComplate/Personal";
import { LoadingButton } from "@mui/lab";
import request from "../../../../../utils/request";
import toast from "react-hot-toast";
import { Modal } from "antd";
import { errorMessageTranform } from "../../../../../utils/error";
import { getInitials } from "../../../../../utils/firstChar";
import { FiTrash2 } from "react-icons/fi";
import StatusIcon from "../../Components/StatusIcon";
import { AbilityContext } from "../../../../components/Ability";
import { INFORM_STATUS } from "../../../../constants";

const Index = () => {
  const ability = useContext(AbilityContext);
  const { id } = useParams();
  const [refresh, setRefresh] = useState<number | null>(null);
  const [current, setCurrent] = useState<InformInterface | null>();
  const [personals, setPersonals] = useState<any[]>([]);
  const [onLoading, setOnLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<any>(`new`);
  const { data, loading } = useInform(id, refresh);

  useEffect(() => {
    setStatus(data?.status);
  }, [data]);

  const onRefresh = () => {
    setRefresh(moment().unix());
  };

  const handleOnSelectPersonal = (value: any) => {
    setPersonals(value);
  };

  const onAddPersonal = useCallback(async () => {
    if (personals.length === 0) {
      Modal.warning({
        title: "เกิดข้อผิดพลาด",
        content: "กรุณาเลือกข้อมูลบุคคลที่ต้องการเพิ่ม",
      });
      return;
    }
    setOnLoading(true);
    try {
      await request({
        url: `/api/inform/${id}/personal`,
        method: `POST`,
        data: {
          personalId: personals.map((item) => item.id),
        },
      });
      toast.success(`เพิ่มข้อมูลสำเร็จ`);
      setPersonals([]);
      onRefresh();
    } catch (e: any) {
      Modal.error({
        title: "เกิดข้อผิดพลาด",
        content: errorMessageTranform(
          e?.response?.data?.errors ?? e.response?.data?.message ?? e.message
        ),
      });
    }
    setOnLoading(false);
  }, [data, personals]);

  const onRemovePersonal = (personalId: string) => {
    Modal.confirm({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่",
      content: "ข้อมูลที่ถูกลบจะไม่สามารถกู้คืนได้",
      onOk: async () => {
        try {
          await request({
            url: `/api/inform/${id}/personal`,
            method: `DELETE`,
            data: {
              personalId,
            },
          });
          toast.success(`ลบข้อมูลสำเร็จ`);
          onRefresh();
        } catch (e: any) {
          Modal.error({
            title: "เกิดข้อผิดพลาด",
            content: errorMessageTranform(
              e?.response?.data?.errors ??
                e.response?.data?.message ??
                e.message
            ),
          });
        }
      },
    });
  };

  const onStatusChange = useCallback(async () => {
    Modal.confirm({
      title: "คุณต้องการเปลี่ยนสถานะใช่หรือไม่",
      content: "คุณสามารถเปลี่ยนสถานะได้ตลอดเวลา หากตั้งค่าผิด",
      onOk: async () => {
        try {
          await request({
            url: `/api/inform/${id}/status`,
            method: `POST`,
            data: {
              status,
            },
          });
          toast.success(`เปลี่ยนสถานะสำเร็จ`);
          onRefresh();
        } catch (e: any) {
          Modal.error({
            title: "เกิดข้อผิดพลาด",
            content: errorMessageTranform(
              e?.response?.data?.errors ??
                e.response?.data?.message ??
                e.message
            ),
          });
        }
      },
    });
  }, [status, personals]);

  return (
    <>
      <PageHeader
        title={`รายละเอียดข้อมูลแจ้งเหตุ`}
        titleIcon={<StatusIcon status={data?.status} />}
        routes={[
          { title: `รายชื่อข้อมูลแจ้งเหตุ`, path: "/inform" },
          { title: `รหัสแจ้งเหตุ ${data?.code || ``}` },
          { title: `รหัสตู้ ${data?.box?.code || ``}` },
        ]}
        back={{
          title: "รายชื่อข้อมูลแจ้งเหตุ",
          path: "/inform",
        }}
      />

      <Box flexGrow={1}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card sx={{ mb: 4 }}>
              <CardHeader title="ข้อมูลแจ้งเหตุ" />
              <CardContent sx={{ pt: 2 }}>
                <Box flexGrow={1}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} lg={6}>
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="h6" color={"primary"} gutterBottom>
                          เรื่องที่ต้องการแจ้งเหตุ หรือ ร้องเรียน
                        </Typography>
                        <Typography variant="subtitle2" fontSize={16}>
                          {data?.subject?.join(`, `)}
                        </Typography>
                      </Box>
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="h6" color={"primary"} gutterBottom>
                          ลักษณะผู้ก่อเหตุ
                        </Typography>
                        <Typography variant="subtitle2" fontSize={16}>
                          {data?.caseOfAction ?? ""}
                        </Typography>
                      </Box>
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="h6" color={"primary"} gutterBottom>
                          พฤติกรรมผู้ก่อเหตุ
                        </Typography>
                        <Typography variant="subtitle2" fontSize={16}>
                          {data?.behavior ?? ""}
                        </Typography>
                      </Box>
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="h6" color={"primary"} gutterBottom>
                          ภาพประกอบ
                        </Typography>
                        <Typography variant="subtitle2" fontSize={16}>
                          {typeof data?.images !== `undefined` &&
                          data?.images?.length > 0 ? (
                            <Grid container spacing={2}>
                              {data?.images?.map((image) => {
                                return (
                                  <Grid item xs={6} lg={4}>
                                    <img
                                      src={image}
                                      alt={image}
                                      style={{
                                        width: `100%`,
                                        marginBottom: 10,
                                      }}
                                    />
                                  </Grid>
                                );
                              })}
                            </Grid>
                          ) : (
                            `ไม่มีภาพประกอบ`
                          )}
                        </Typography>
                      </Box>
                      <Box sx={{ mb: 2 }}>
                        <Typography
                          variant="h6"
                          color={"primary"}
                          mb={2}
                          gutterBottom
                        >
                          ผู้ก่อเหตุ
                        </Typography>
                        <Grid container alignItems={`center`} spacing={2}>
                          {ability.can("edit", "informInfo") && (
                            <>
                              <Grid item xs={12} lg={8}>
                                <AutoCompetePersonal
                                  handleSelect={handleOnSelectPersonal}
                                />
                              </Grid>
                              <Grid item xs={12} lg={2}>
                                <LoadingButton
                                  color={"primary"}
                                  variant={`contained`}
                                  size={`large`}
                                  loading={onLoading}
                                  onClick={() => onAddPersonal()}
                                >
                                  บันทึก
                                </LoadingButton>
                              </Grid>
                            </>
                          )}

                          <Grid item xs={12}>
                            {loading ? (
                              <Typography
                                sx={{
                                  display: `flex`,
                                  alignItems: `center`,
                                  justifyContent: `center`,
                                }}
                                variant="subtitle2"
                              >
                                <CircularProgress
                                  color="success"
                                  size={32}
                                  sx={{ mr: 2 }}
                                />{" "}
                                กำลังโหลดข้อมูล...
                              </Typography>
                            ) : (
                              <Grid container alignItems={`center`} spacing={2}>
                                {data?.personals?.map((personal) => {
                                  const name = `${personal?.firstName} ${personal?.lastName}`;
                                  return (
                                    <>
                                      <Grid item xs={12}>
                                        <Stack
                                          alignItems="center"
                                          direction="row"
                                          spacing={1}
                                        >
                                          <Avatar
                                            src={personal.image}
                                            sx={{
                                              height: 42,
                                              width: 42,
                                            }}
                                          >
                                            {getInitials(name)}
                                          </Avatar>
                                          <div>
                                            <Typography
                                              component={Link}
                                              target="_blank"
                                              to={`/personal/${personal._id}`}
                                              variant="subtitle2"
                                            >
                                              <u>{name}</u>
                                            </Typography>
                                          </div>
                                          <div>
                                            <IconButton
                                              onClick={() =>
                                                onRemovePersonal(personal._id)
                                              }
                                            >
                                              <FiTrash2 />
                                            </IconButton>
                                          </div>
                                        </Stack>
                                      </Grid>
                                    </>
                                  );
                                })}
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="h6" color={"primary"} gutterBottom>
                          ช่วงเวลาที่เกิดเหตุ
                        </Typography>
                        <Typography variant="subtitle2" fontSize={16}>
                          {data?.date ?? ``} &nbsp; {data?.time || `ไม่ระบุ`}
                        </Typography>
                      </Box>
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="h6" color={"primary"} gutterBottom>
                          จำนวนผู้ก่อเหตุ
                        </Typography>
                        <Typography variant="subtitle2" fontSize={16}>
                          {data?.amount ?? `ไม่ระบุ`}
                        </Typography>
                      </Box>
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="h6" color={"primary"} gutterBottom>
                          ตำแหน่งที่เกิดเหตุ
                        </Typography>
                        <Typography variant="subtitle2" fontSize={16}>
                          {`ต.${data?.subdistrict} อ.${data?.district}`}
                        </Typography>
                      </Box>
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="h6" color={"primary"} gutterBottom>
                          จุดสังเกตุเพิ่มเติม
                        </Typography>
                        <Typography variant="subtitle2" fontSize={16}>
                          {data?.note || `ไม่ระบุ`}
                        </Typography>
                      </Box>
                      <Box sx={{ mb: 2 }}>
                        {typeof data?.latitude !== `undefined` && (
                          <MapMarker
                            showMapOnly={true}
                            height={350}
                            position={{
                              lat: data?.latitude,
                              lng: data?.longitude,
                            }}
                          />
                        )}
                      </Box>
                      {ability.can("edit", "informInfo") && (
                        <Box sx={{ mb: 2 }}>
                          <Typography
                            variant="h6"
                            color={"primary"}
                            mb={2}
                            gutterBottom
                          >
                            เปลี่ยนสถานะ
                          </Typography>
                          <Grid container alignItems={`center`} spacing={2}>
                            <Grid item xs={12} lg={8}>
                              <TextField
                                select
                                fullWidth
                                label="สถานะ"
                                value={status || `new`}
                                onChange={(e) => setStatus(e.target.value)}
                              >
                                {INFORM_STATUS.map((option: any) => (
                                  <MenuItem
                                    key={option.code}
                                    value={option.code}
                                  >
                                    {option.name}
                                  </MenuItem>
                                ))}
                                {/*<MenuItem value="new">รอดำเนินการ</MenuItem>*/}
                                {/*<MenuItem value="inprogress">*/}
                                {/*  กำลังดำเนินการ*/}
                                {/*</MenuItem>*/}
                                {/*<MenuItem value="done">*/}
                                {/*  ดำเนินการเสร็จสิ้น*/}
                                {/*</MenuItem>*/}
                              </TextField>
                            </Grid>
                            <Grid item xs={12} lg={2}>
                              <LoadingButton
                                color={"primary"}
                                variant={`contained`}
                                size={`large`}
                                loading={onLoading}
                                onClick={() => onStatusChange()}
                              >
                                บันทึก
                              </LoadingButton>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Index;
