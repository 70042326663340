import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { createInjectorsEnhancer } from "redux-injectors";
import createSagaMiddleware from "redux-saga";
import { createReducer } from "./reducers";
import { appStateSlice } from "./features/appStateSlice";

// export function configureAppStore() {
//
//
//     return store;
// }

const reduxSagaMonitorOptions = {};
const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
const { run: runSaga } = sagaMiddleware;

// Create the store with saga middleware
const middlewares = [sagaMiddleware];

const enhancers = [
  createInjectorsEnhancer({
    createReducer,
    runSaga,
  }),
];

const rootReducer = combineReducers({
  ...createReducer(),
  appState: appStateSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware(), ...middlewares],
  devTools: process.env.NODE_ENV !== "production",
  enhancers,
});

export type RootState = ReturnType<typeof store.getState>;
