import {
  Box,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Scrollbar } from "../../../../../components/SimpleBar";
import { TableProps } from "../../../../../../interface";
import { LogsInterface } from "../../../../../../types/logs";
import moment from "moment";

const TableLists = (props: TableProps) => {
  const { items = [], onRefresh = () => {}, loading = false } = props;

  return (
    <Box sx={{ position: "relative" }}>
      <Scrollbar>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ p: 2 }} align="center" width={100}>
                Date
              </TableCell>
              <TableCell align="center" width={150}>
                Collection
              </TableCell>
              <TableCell align="center" width={150}>
                Action
              </TableCell>
              <TableCell align="center" width={150}>
                Username
              </TableCell>
              <TableCell align="center">Data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell align="center" colSpan={5} sx={{ py: 3 }}>
                  <Typography
                    sx={{
                      display: `flex`,
                      alignItems: `center`,
                      justifyContent: `center`,
                    }}
                    variant="subtitle2"
                  >
                    <CircularProgress
                      color="success"
                      size={32}
                      sx={{ mr: 2 }}
                    />{" "}
                    กำลังโหลดข้อมูล...
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              items.map((item: LogsInterface) => {
                return (
                  <TableRow hover key={item._id}>
                    <TableCell sx={{ verticalAlign: `top` }} align="center">
                      {moment(item?.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                    </TableCell>
                    <TableCell sx={{ verticalAlign: `top` }} align="center">
                      {item?.collection}
                    </TableCell>
                    <TableCell sx={{ verticalAlign: `top` }} align="center">
                      {item?.action}
                    </TableCell>
                    <TableCell sx={{ verticalAlign: `top` }} align="center">
                      {item?.username}
                    </TableCell>
                    <TableCell align="left">
                      <Box
                        sx={{
                          maxHeight: `250px`,
                          overflowY: `auto`,
                          overflowX: `hidden`,
                        }}
                      >
                        <pre style={{ fontSize: `11px`, lineHeight: 1.3 }}>
                          <code>{JSON.stringify(item?.data, null, 2)}</code>
                        </pre>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </Scrollbar>
    </Box>
  );
};

export default TableLists;
