import LogView from "../../pages/SettingPage/Logs/logs";
import { useContext, useEffect } from "react";
import { AbilityContext } from "../Ability";
import { IconButton, SvgIcon } from "@mui/material";
import { useDialog } from "../../hooks/useDialog";
import CustomDialog from "../CustomDialog";
import { GoInfo } from "react-icons/go";
import { useFetchLogs } from "../../hooks/useLogs";

const LogsViewIconButton = ({ id }: { id: string }) => {
  const ability = useContext(AbilityContext);
  const dialog = useDialog();

  const handleOpenModal = () => {
    dialog.handleOpen({ open: true });
  };

  // const data = useFetchLogs({ id });

  return (
    <>
      {ability.can("read", "setting") && (
        <>
          <CustomDialog
            size={"lg"}
            title="Logs"
            onClose={dialog.handleClose}
            open={dialog.open}
          >
            <LogView dialog={dialog} id={id} />
          </CustomDialog>
          <IconButton onClick={() => handleOpenModal()}>
            <SvgIcon>
              <GoInfo />
            </SvgIcon>
          </IconButton>
        </>
      )}
    </>
  );
};
export default LogsViewIconButton;
