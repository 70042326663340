import * as Yup from "yup";
import { useFormik } from "formik";
import { Box, Grid, MenuItem, TextField } from "@mui/material";
import { useFetchDepartments } from "../../../../../hooks/useDepartment";
import { DepartmentInterface } from "../../../../../../types/department";
import {
  PersonalInterface,
  PersonalViolateTypeInterface,
} from "../../../../../../types/personal";
import { useFetchViolateType } from "../../../../../hooks/usePersonal";
import request from "../../../../../../utils/request";
import toast from "react-hot-toast";
import { Modal } from "antd";
import { errorMessageTranform } from "../../../../../../utils/error";
import { LoadingButton } from "@mui/lab";
import { Save } from "@mui/icons-material";

interface Props {
  data?: PersonalInterface | undefined;
  loading?: boolean;
  onRefresh?: () => void;
  dialog: any;
}

const search = {
  all: true,
};

const behaviors = ["ทั่วไป", "เสี่ยงต่ำ", "เสี่ยง", "เสี่ยงสูง"];

const FormViolateType = (prop: Props) => {
  const { data, loading, onRefresh, dialog } = prop;
  const depts = useFetchDepartments(search);
  const violates = useFetchViolateType(search);

  const initialValues = {
    departmentId: "",
    behavior: "",
    violateTypeId: "",
    violate: "",
    jailed: false,
    confirm: false,
    date: "",
    personalId: "",
  };

  let yupObject = {
    departmentId: Yup.string().required(`กรุณากรอกข้อมูล`),
    behavior: Yup.string().required(`กรุณากรอกข้อมูล`),
    violateTypeId: Yup.string().required(`กรุณากรอกข้อมูล`),
    violate: Yup.string().required(`กรุณากรอกข้อมูล`),
    date: Yup.string().required(`กรุณากรอกข้อมูล`),
    // jailed: Yup.boolean().required(`กรุณากรอกข้อมูล`),
    // confirm: Yup.boolean().required(`กรุณากรอกข้อมูล`),
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object(yupObject),
    onSubmit: async (values) => {
      try {
        await request({
          url: `/api/personal/violate`,
          method: `POST`,
          data: {
            ...values,
            personalId: data?._id,
          },
        });
        toast.success("บันทึกข้อมูลสำเร็จ");
        dialog.handleClose();
        onRefresh?.();
      } catch (e: any) {
        Modal.error({
          title: "เกิดข้อผิดพลาด",
          content: errorMessageTranform(
            e?.response?.data?.errors ?? e.response?.data?.message ?? e.message
          ),
        });
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box></Box>
        <Grid container mt={0} spacing={2}>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              disabled={formik.isSubmitting}
              name="departmentId"
              label="แหล่งที่มา"
              value={formik.values.departmentId}
              onChange={formik.handleChange}
              error={
                formik.touched.departmentId &&
                Boolean(formik.errors.departmentId)
              }
              helperText={
                formik.touched.departmentId && formik.errors.departmentId
              }
              select
            >
              {depts?.data.map((item: DepartmentInterface) => (
                <MenuItem key={item?._id} value={item?._id}>
                  {item?.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              disabled={formik.isSubmitting}
              name="violateTypeId"
              label="ประเภทความผิด"
              value={formik.values.violateTypeId}
              onChange={formik.handleChange}
              error={
                formik.touched.violateTypeId &&
                Boolean(formik.errors.violateTypeId)
              }
              helperText={
                formik.touched.violateTypeId && formik.errors.violateTypeId
              }
              select
            >
              {violates?.data.map((item: PersonalViolateTypeInterface) => (
                <MenuItem key={item?._id} value={item?._id}>
                  {item?.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              disabled={formik.isSubmitting}
              name="behavior"
              label="พฤติกรรม"
              value={formik.values.behavior}
              onChange={formik.handleChange}
              error={formik.touched.behavior && Boolean(formik.errors.behavior)}
              helperText={formik.touched.behavior && formik.errors.behavior}
              select
            >
              {behaviors.map((item: any) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={formik.isSubmitting}
              name="violate"
              label="ความผิด"
              value={formik.values.violate}
              onChange={formik.handleChange}
              error={formik.touched.violate && Boolean(formik.errors.violate)}
              helperText={formik.touched.violate && formik.errors.violate}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              disabled={formik.isSubmitting}
              name="jailed"
              label="ต้องโทษในเรือนจำ"
              value={formik.values.jailed}
              onChange={formik.handleChange}
              error={formik.touched.jailed && Boolean(formik.errors.jailed)}
              helperText={formik.touched.jailed && formik.errors.jailed}
              select
            >
              <MenuItem value={`false`}>ไม่ใช่</MenuItem>
              <MenuItem value={`true`}>ใช่</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              disabled={formik.isSubmitting}
              name="confirm"
              label="การยืนยันข้อมูล"
              value={formik.values.confirm}
              onChange={formik.handleChange}
              error={formik.touched.confirm && Boolean(formik.errors.confirm)}
              helperText={formik.touched.confirm && formik.errors.confirm}
              select
            >
              <MenuItem value={`false`}>ไม่ใช่</MenuItem>
              <MenuItem value={`true`}>ใช่</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              disabled={formik.isSubmitting}
              name="date"
              label="วันที่"
              value={formik.values.date}
              onChange={formik.handleChange}
              error={formik.touched.date && Boolean(formik.errors.date)}
              helperText={formik.touched.date && formik.errors.date}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} justifyContent={`end`} textAlign={`end`}>
            <LoadingButton
              loading={formik.isSubmitting}
              disabled={formik.isSubmitting}
              type={"submit"}
              size={`small`}
              variant={`contained`}
              color={`primary`}
              startIcon={<Save />}
            >
              บันทึก
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default FormViolateType;
