import { ChangeEvent, useCallback, useState } from "react";
import { SearchProps } from "../../../interface";
import { useUpdateEffect } from "../../hooks/useUpdateEffect";
import {
  Box,
  Button,
  InputAdornment,
  OutlinedInput,
  Stack,
  SvgIcon,
} from "@mui/material";
import SearchMdIcon from "@untitled-ui/icons-react/build/esm/SearchMd";

const CustomSearchTableList = (props: SearchProps) => {
  const { onFiltersChange, placeholder } = props;
  const [filters, setFilters] = useState({});
  const [data, setData] = useState({
    search: "",
  });

  const handleFiltersUpdate = useCallback(() => {
    onFiltersChange?.(filters);
  }, [filters, onFiltersChange]);

  useUpdateEffect(() => {
    handleFiltersUpdate();
  }, [filters, handleFiltersUpdate]);

  const handleQueryChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      setFilters((prevState) => ({
        ...prevState,
        query: data?.search,
      }));
    },
    []
  );

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSearch = useCallback(() => {
    setFilters((prevState) => ({
      ...prevState,
      query: data?.search,
    }));
  }, [data?.search]);

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        flexWrap="wrap"
        spacing={3}
        sx={{ p: 3 }}
      >
        <Box component="form" onSubmit={handleQueryChange} sx={{ flexGrow: 1 }}>
          <OutlinedInput
            defaultValue=""
            fullWidth
            name={"search"}
            onChange={(e) => handleInputChange(e)}
            placeholder={`${placeholder || `ค้นหา`}`}
            startAdornment={
              <InputAdornment position="start">
                <SvgIcon>
                  <SearchMdIcon />
                </SvgIcon>
              </InputAdornment>
            }
          />
        </Box>
        <Button
          variant={"contained"}
          color={"primary"}
          size={"large"}
          startIcon={<SearchMdIcon />}
          type={"submit"}
          onClick={() => handleSearch()}
        >
          ค้นหา
        </Button>
      </Stack>
    </>
  );
};

export default CustomSearchTableList;
