import { useEffect, useState } from "react";
import { GoogleAPI, GoogleApiWrapper, Map, Marker } from "google-maps-react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { Autocomplete } from "@mui/lab";

const MapMarker = (props) => {
  const {
    google,
    handleLocation,
    position = {},
    height = 300,
    showMapOnly = false,
    viewOnly = false,
  } = props;
  const [markers, setMarkers] = useState({
    lat: position?.lat ?? 14.241365582910328,
    lng: position?.lng ?? 100.65296264241454,
    name: "Current Position",
    link: `https://maps.google.com/?q=${position?.lat ?? 14.241365582910328},${
      position?.lng ?? 100.65296264241454
    }`,
    info: {}, // ข้อมูลจาก google map
  });

  const [currentPosition, setCurrentPosition] = useState({
    lat: position?.lat ?? 14.241365582910328,
    lng: position?.lng ?? 100.65296264241454,
  });

  const [mapReady, setMapReady] = useState(null);
  const [timer, setTimer] = useState(null);
  const [places, setPlaces] = useState([]);
  const [center, setCenter] = useState(null);

  useEffect(() => {
    if (
      typeof position.lat !== "undefined" &&
      typeof position.lng !== "undefined"
    ) {
      setCurrentPosition({
        lat: position.lat,
        lng: position.lng,
      });
    }
  }, [position]);

  useEffect(() => {
    // alert(1)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // setCurrentPosition({ lat: latitude, lng: longitude });
          // setMarkers({
          //   ...markers,
          //   lat: latitude,
          //   lng: longitude,
          //   link: `https://maps.google.com/?q=${latitude},${longitude}`,
          // });
          // handleLocation({
          //   latitude: latitude,
          //   longitude: longitude,
          //   googleMapUrl: `https://maps.google.com/?q=${latitude},${longitude}`,
          // });
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  const handleMapClick = (mapProps, map, clickEvent) => {
    if (showMapOnly || viewOnly) return;
    const { latLng } = clickEvent;
    const shortUrl = `https://maps.google.com/?q=${latLng.lat()},${latLng.lng()}`;
    // console.log('Short link:', shortUrl);
    const location = {
      lat: latLng.lat(),
      lng: latLng.lng(),
    };
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK" && results[0]) {
        // console.log('Location:', results[0]?.formatted_address);
        setMarkers({
          ...location,
          name: results[0]?.formatted_address,
          link: shortUrl,
          info: results[0],
        });
        handleLocation({
          latitude: latLng.lat(),
          longitude: latLng.lng(),
          googleMapUrl: `https://maps.google.com/?q=${latLng.lat()},${latLng.lng()}`,
        });
        setTimeout(() => {
          setCurrentPosition({
            lat: latLng.lat(),
            lng: latLng.lng(),
          });
        }, 500);
      }
    });
  };

  const handleCurrentPosition = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const shortUrl = `https://maps.google.com/?q=${latitude},${longitude}`;
          setMarkers({
            lat: latitude,
            lng: longitude,
            name: `Current Position`,
            link: shortUrl,
            info: {},
          });
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const onSelectPlace = (e, value) => {
    const lat = value?.location?.lat;
    const lng = value?.location?.lng;
    const shortUrl = `https://maps.google.com/?q=${lat},${lng}`;

    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      {
        location: {
          lat: lat,
          lng: lng,
        },
      },
      (results, status) => {
        setMarkers({
          lat: lat,
          lng: lng,
          name: value.name,
          link: shortUrl,
          info: value.name,
        });
        handleLocation({
          latitude: lat,
          longitude: lng,
          googleMapUrl: shortUrl,
        });
        setCurrentPosition({
          lat: lat,
          lng: lng,
        });
        setCenter({
          lat: lat,
          lng: lng,
        });
      }
    );
  };

  const onMapReady = (mapProps, map) => {
    setMapReady({
      map,
      mapProps,
    });
  };

  const fetchPlaces = (value) => {
    if (mapReady) {
      const { map, mapProps } = mapReady;
      const { google } = mapProps;
      const request = {
        query: value,
        fields: ["name", "geometry"],
        language: "th",
      };
      const service = new google.maps.places.PlacesService(map);
      service.findPlaceFromQuery(request, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          const ps = results.map((result) => {
            return {
              name: result.name,
              location: {
                lat: result.geometry?.location?.lat(),
                lng: result.geometry?.location?.lng(),
              },
            };
          });
          // console.log(ps);
          setPlaces(ps);
        } else {
          console.error("Error fetching places:", status);
        }
      });
    }
  };

  const f = (e) => {
    if (mapReady) {
      // document.getElementsByClassName('pac-container').de
      const elementsToDelete = document.getElementsByClassName("pac-container");
      const elementsArray = Array.from(elementsToDelete);
      elementsArray.forEach((element) => {
        // element.remove();
      });
      const { map, mapProps } = mapReady;
      const { google } = mapProps;
      const center = { lat: 50.064192, lng: -130.605469 };
      const defaultBounds = {
        north: center.lat + 0.1,
        south: center.lat - 0.1,
        east: center.lng + 0.1,
        west: center.lng - 0.1,
      };
      const input = document.getElementById("search-place");
      const options = {
        bounds: defaultBounds,
        componentRestrictions: { country: "th" },
        language: "th",
        fields: ["address_components", "geometry", "icon", "name"],
        strictBounds: false,
        types: ["establishment"],
      };

      const autocomplete = new google.maps.places.Autocomplete(input, options);
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();

        const lat = place?.geometry?.location?.lat();
        const lng = place?.geometry?.location?.lng();
        const shortUrl = `https://maps.google.com/?q=${lat},${lng}`;

        const geocoder = new google.maps.Geocoder();
        geocoder.geocode(
          {
            location: {
              lat: lat,
              lng: lng,
            },
          },
          (results, status) => {
            setMarkers({
              lat: lat,
              lng: lng,
              name: place.name,
              link: shortUrl,
              info: place.name,
            });
            handleLocation({
              latitude: lat,
              longitude: lng,
              googleMapUrl: shortUrl,
            });
            setCurrentPosition({
              lat: lat,
              lng: lng,
            });
            setCenter({
              lat: lat,
              lng: lng,
            });
          }
        );
      });
    }
  };

  const handlePlaceSelect = () => {};

  const mapStyles = {
    position: "relative",
    width: "100%",
    height: height + "px",
  };

  // @ts-ignore
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box>
            <div id={`map`}></div>
            <Map
              onReady={onMapReady}
              google={google}
              zoom={10}
              containerStyle={mapStyles}
              style={mapStyles}
              onClick={handleMapClick}
              initialCenter={{
                lat: currentPosition?.lat,
                lng: currentPosition?.lng,
              }}
              center={center || {}}
            >
              <Marker position={markers} />
            </Map>
          </Box>
        </Grid>
        {!showMapOnly && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {!viewOnly && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      id={`search-place`}
                      fullWidth
                      label="ค้นหาสถานที่"
                      onChange={(e) => f(e)}
                    />
                    {/*<Autocomplete*/}
                    {/*  fullWidth*/}
                    {/*  options={places || []}*/}
                    {/*  autoHighlight*/}
                    {/*  getOptionLabel={(option) => option.name}*/}
                    {/*  onChange={(e, value) => {*/}
                    {/*    onSelectPlace(e, value);*/}
                    {/*  }}*/}
                    {/*  renderOption={(props, option) => (*/}
                    {/*    <Box*/}
                    {/*      component="li"*/}
                    {/*      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}*/}
                    {/*      {...props}*/}
                    {/*    >*/}
                    {/*      {option.name}*/}
                    {/*    </Box>*/}
                    {/*  )}*/}
                    {/*  renderInput={(params) => (*/}
                    {/*    <TextField*/}
                    {/*      onChange={(e) => {*/}
                    {/*        if (timer) {*/}
                    {/*          clearTimeout(timer);*/}
                    {/*          setTimer(null);*/}
                    {/*        }*/}
                    {/*        setTimer(*/}
                    {/*          setTimeout(() => {*/}
                    {/*            fetchPlaces(e.target.value);*/}
                    {/*          }, 1000)*/}
                    {/*        );*/}
                    {/*      }}*/}
                    {/*      {...params}*/}
                    {/*      label="ค้นหาสถานที่"*/}
                    {/*      inputProps={{*/}
                    {/*        ...params.inputProps,*/}
                    {/*        autoComplete: "new-password", // disable autocomplete and autofill*/}
                    {/*      }}*/}
                    {/*    />*/}
                    {/*  )}*/}
                    {/*/>*/}
                    {/*<TextField fullWidth label="Localtion" variant="outlined" />*/}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth label="Name" value={markers.name} />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <TextField
                  className={viewOnly ? "text-label" : ""}
                  InputProps={{
                    readOnly: viewOnly,
                  }}
                  fullWidth
                  label="Link"
                  value={markers.link}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

// export default MapMarker;

export default GoogleApiWrapper({
  apiKey: "AIzaSyBdurME0gbZyqKv0qv7yV0034VDRiFoq-Q",
})(MapMarker);
