import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import request from "../../../../../../utils/request";
import toast from "react-hot-toast";
import { Modal } from "antd";
import { errorMessageTranform } from "../../../../../../utils/error";
import { useFetchVillages } from "../../../../../hooks/useVillage";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Save } from "@mui/icons-material";
import { PersonalInterface } from "../../../../../../types/personal";
import { useFetchPrefixes } from "../../../../../hooks/usePrefix";
import { PrefixInterface } from "../../../../../../types/prefix";
import { useFetchPersonalType } from "../../../../../hooks/usePersonalType";
import { PeraonalTypeInterface } from "../../../../../../types/personalType";
import { VillageInterface } from "../../../../../../types/village";
import MapMarker from "../../../../../components/MapMarker";
import FileInput from "../../../../../components/FileInput";
import AddressAuto from "../../../../../components/AddressAuto";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import OverwriteMomentBE from "../../../../../../utils/OverwriteMomentBE";
import moment from "moment";
import { MONTHS } from "../../../../../constants";

interface Props {
  data?: PersonalInterface | undefined;
  loading?: boolean;
  onRefresh: () => void;
  dialog: any;
}

const search = {
  all: true,
};

const Form = (props: Props) => {
  const { data, loading, onRefresh, dialog } = props;
  const [isUpdate] = useState(typeof data?._id !== "undefined");
  // const [sameAddress, setSameAddress] = useState(false);
  // const depts = useFetchDepartments(search)
  const villages = useFetchVillages(search);
  const prefixes = useFetchPrefixes(search);
  const personalTypes = useFetchPersonalType(search);

  const initialValues = {
    taxId: data?.taxId ?? "",
    firstName: data?.firstName ?? "",
    lastName: data?.lastName ?? "",
    prefix: data?.prefix ?? "",
    nickName: data?.nickName ?? "",
    birthDate: data?.birthDate ?? "",
    birthDateDay: moment(`${data?.birthDate}`, "YYYY-MM-DD").format("D"),
    birthDateMonth: moment(`${data?.birthDate}`, "YYYY-MM-DD").format("M"),
    birthDateYear:
      parseInt(moment(`${data?.birthDate}`, "YYYY-MM-DD").format("YYYY")) + 543,
    gender: data?.gender ?? "",
    nationality: data?.nationality ?? "",
    personalType: data?.personalType ?? "",
    sameRegisterAddress: data?.sameRegisterAddress ?? false,
    addressRegistration: {
      villageId: data?.addressRegistration?.villageId ?? "",
      address: data?.addressRegistration?.address ?? "",
      subdistrict: data?.addressRegistration?.subdistrict ?? "",
      district: data?.addressRegistration?.district ?? "",
      province: data?.addressRegistration?.province ?? "",
    },
    addressCurrent: {
      villageId: data?.addressCurrent?.villageId ?? "",
      address: data?.addressCurrent?.address ?? "",
      subdistrict: data?.addressCurrent?.subdistrict ?? "",
      district: data?.addressCurrent?.district ?? "",
      province: data?.addressCurrent?.province ?? "",
    },
    addressPosition: {
      latitude: data?.addressPosition?.latitude ?? "",
      longitude: data?.addressPosition?.longitude ?? "",
      googleMapUrl: data?.addressPosition?.googleMapUrl ?? "",
    },
    image: "",
  };

  let yupObject = {
    taxId: Yup.string().length(13).required("กรุณากรอกเลขประจำตัวประชาชน"),
    firstName: Yup.string().required("กรุณากรอกชื่อ"),
    lastName: Yup.string().required("กรุณากรอกนามสกุล"),
    prefix: Yup.string().required("กรุณาเลือกคำนำหน้า"),
    nickName: Yup.string().required("กรุณากรอกชื่อเล่น"),
    // birthDate: Yup.string().required("กรุณาเลือกวันเกิด"),
    gender: Yup.string().required("กรุณาเลือกเพศ"),
    nationality: Yup.string().required("กรุณาเลือกสัญชาติ"),
    personalType: Yup.string().required("กรุณาเลือกประเภทบุคคล"),
    // addressRegistration: Yup.object().shape({
    //   villageId: Yup.string().required("กรุณาเลือกหมู่บ้าน"),
    //   address: Yup.string().required("กรุณากรอกบ้านเลขที่"),
    //   subdistrict: Yup.string().required("กรุณากรอกตำบล"),
    //   district: Yup.string().required("กรุณากรอกอำเภอ"),
    //   province: Yup.string().required("กรุณากรอกจังหวัด"),
    // }),
    addressPosition: Yup.object().shape({
      // latitude: Yup.string().required("กรุณากรอกละติจูด"),
      // longitude: Yup.string().required("กรุณากรอกลองติจูด"),
      // googleMapUrl: Yup.string().required("กรุณากรอกลิงค์แผนที่"),
    }),
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object(yupObject),
    onSubmit: async (values) => {
      if (values.sameRegisterAddress) {
        values.addressCurrent = values.addressRegistration;
      }

      values.birthDate = `${values.birthDateYear - 543}-${moment(
        values.birthDateMonth,
        "MM"
      ).format(`MM`)}-${moment(values.birthDateDay, "DD").format(`DD`)}`;

      const formData = new FormData();
      const flattenObjectToFormData = (obj: any, parentKey = "") => {
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            const newKey = parentKey ? `${parentKey}[${key}]` : key;
            if (value instanceof File) {
              formData.append(newKey, value);
            } else if (value instanceof Object) {
              flattenObjectToFormData(value, newKey);
            } else {
              formData.append(newKey, value);
            }
          }
        }
      };
      flattenObjectToFormData(values);

      let options = {
        url: "/api/personal",
        method: "POST",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      if (typeof data?._id !== "undefined") {
        options.url = `${options.url}/${data?._id}`;
      }
      try {
        await request(options);
        toast.success("บันทึกข้อมูลสำเร็จ");
        onRefresh();
        dialog.handleClose();
      } catch (e: any) {
        Modal.error({
          title: "เกิดข้อผิดพลาด",
          content: errorMessageTranform(
            e?.response?.data?.errors ?? e.response?.data?.message ?? e.message
          ),
        });
      }
    },
  });

  const handleSetSameAddress = () => {
    if (!formik.values.sameRegisterAddress) {
      formik.setFieldValue("addressCurrent", formik.values.addressRegistration);
    }
    formik.setFieldValue(
      "sameRegisterAddress",
      !formik.values.sameRegisterAddress
    );
  };

  const handleLocation = (location: {
    latitude: number;
    longitude: number;
    googleMapUrl: string;
  }) => {
    formik.setFieldValue("addressPosition.latitude", `${location.latitude}`);
    formik.setFieldValue("addressPosition.longitude", `${location.longitude}`);
    formik.setFieldValue("addressPosition.googleMapUrl", location.googleMapUrl);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box>
        <Grid container alignItems={`center`} spacing={2}>
          <Grid item xs={6}>
            <Typography variant={`h6`}>ข้อมูลส่วนตัว</Typography>
          </Grid>
          <Grid item xs={6} justifyContent={`end`} textAlign={`end`}>
            <LoadingButton
              loading={formik.isSubmitting || loading}
              disabled={formik.isSubmitting || loading}
              type={"submit"}
              size={`small`}
              variant={`contained`}
              color={`primary`}
              startIcon={<Save />}
            >
              บันทึก
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
      <Grid container mt={0} spacing={2}>
        <Grid item xs={12} lg={12}>
          <TextField
            fullWidth
            disabled={formik.isSubmitting || loading}
            name="taxId"
            label="เลขประจำตัวประชาชน"
            value={formik.values.taxId}
            onChange={formik.handleChange}
            error={formik.touched.taxId && Boolean(formik.errors.taxId)}
            helperText={formik.touched.taxId && formik.errors.taxId}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            fullWidth
            disabled={formik.isSubmitting || loading || prefixes?.loading}
            name="prefix"
            label="คำนำหน้า"
            value={formik.values.prefix}
            onChange={formik.handleChange}
            error={formik.touched.prefix && Boolean(formik.errors.prefix)}
            helperText={formik.touched.prefix && formik.errors.prefix}
            select
          >
            {prefixes?.data.map((item: PrefixInterface) => (
              <MenuItem key={item._id} value={item._id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            fullWidth
            disabled={formik.isSubmitting || loading}
            name="firstName"
            label="ชื่อ"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            fullWidth
            disabled={formik.isSubmitting || loading}
            name="lastName"
            label="นามสกุล"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <TextField
            fullWidth
            disabled={formik.isSubmitting || loading}
            name="nickName"
            label="ชื่อเล่น"
            value={formik.values.nickName}
            onChange={formik.handleChange}
            error={formik.touched.nickName && Boolean(formik.errors.nickName)}
            helperText={formik.touched.nickName && formik.errors.nickName}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          {/*<MuiPickersUtilsProvider utils={OverwriteMomentBE} locale="th">*/}
          {/*  <DatePicker*/}
          {/*    format="DD/MM/YYYY"*/}
          {/*    value={formik.values.birthDate}*/}
          {/*    onChange={formik.handleChange}*/}
          {/*  />*/}
          {/*</MuiPickersUtilsProvider>*/}
          <Grid container spacing={1}>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                disabled={formik.isSubmitting || loading}
                name="birthDateDay"
                label="วันเกิด"
                value={formik.values.birthDateDay}
                onChange={formik.handleChange}
                error={
                  formik.touched.birthDateDay &&
                  Boolean(formik.errors.birthDateDay)
                }
                helperText={
                  formik.touched.birthDateDay && formik.errors.birthDateDay
                }
                select
              >
                {Array.from(Array(31).keys()).map((item: number) => (
                  <MenuItem key={item} value={item + 1}>
                    {item + 1}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                disabled={formik.isSubmitting || loading}
                name="birthDateMonth"
                label="เดือนเกิด"
                value={formik.values.birthDateMonth}
                onChange={formik.handleChange}
                error={
                  formik.touched.birthDateMonth &&
                  Boolean(formik.errors.birthDateMonth)
                }
                helperText={
                  formik.touched.birthDateMonth && formik.errors.birthDateMonth
                }
                select
              >
                {Array.from(Array(12).keys()).map((item: number) => (
                  <MenuItem key={item} value={item + 1}>
                    {MONTHS?.[
                      parseInt(moment(`${item + 1}`, "MM").format("M")) - 1
                    ]?.name ?? ""}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                disabled={formik.isSubmitting || loading}
                name="birthDateYear"
                label="ปีเกิด"
                value={formik.values.birthDateYear}
                onChange={formik.handleChange}
                error={
                  formik.touched.birthDateYear &&
                  Boolean(formik.errors.birthDateYear)
                }
                helperText={
                  formik.touched.birthDateYear && formik.errors.birthDateYear
                }
                select
              >
                {Array.from(Array(100).keys()).map((item: number) => (
                  <MenuItem key={item} value={item + 1950 + 543}>
                    {item + 1950 + 543}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          {/*<TextField*/}
          {/*  fullWidth*/}
          {/*  disabled={formik.isSubmitting || loading}*/}
          {/*  name="birthDate"*/}
          {/*  label="วันเกิด"*/}
          {/*  value={formik.values.birthDate}*/}
          {/*  onChange={formik.handleChange}*/}
          {/*  error={formik.touched.birthDate && Boolean(formik.errors.birthDate)}*/}
          {/*  helperText={formik.touched.birthDate && formik.errors.birthDate}*/}
          {/*  type="date"*/}
          {/*  InputLabelProps={{*/}
          {/*    shrink: true,*/}
          {/*  }}*/}
          {/*/>*/}
        </Grid>
        <Grid item xs={12} lg={3}>
          <TextField
            fullWidth
            disabled={formik.isSubmitting || loading}
            name="gender"
            label="เพศ"
            value={formik.values.gender}
            onChange={formik.handleChange}
            error={formik.touched.gender && Boolean(formik.errors.gender)}
            helperText={formik.touched.gender && formik.errors.gender}
            select
          >
            <MenuItem value={`male`}>ชาย</MenuItem>
            <MenuItem value={`female`}>หญิง</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            fullWidth
            disabled={formik.isSubmitting || loading || personalTypes.loading}
            name="personalType"
            label="ประเภทบุคคล"
            value={formik.values.personalType}
            onChange={formik.handleChange}
            error={
              formik.touched.personalType && Boolean(formik.errors.personalType)
            }
            helperText={
              formik.touched.personalType && formik.errors.personalType
            }
            select
          >
            {personalTypes?.data.map((item: PeraonalTypeInterface) => (
              <MenuItem key={item._id} value={item._id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            fullWidth
            disabled={formik.isSubmitting || loading}
            name="nationality"
            label="สัญชาติ"
            value={formik.values.nationality}
            onChange={formik.handleChange}
            error={
              formik.touched.nationality && Boolean(formik.errors.nationality)
            }
            helperText={formik.touched.nationality && formik.errors.nationality}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <FileInput
            placeholder={`เลือกรูปภาพ`}
            name={`image`}
            formik={formik}
          />
        </Grid>
      </Grid>

      <Box sx={{ pt: 4 }}>
        <Typography variant={`h6`}>ข้อมูลที่อยู่ตามทะเบียนราษฏร์</Typography>
      </Box>
      <Grid container mt={0} spacing={2}>
        <Grid item xs={12} lg={4}>
          <TextField
            fullWidth
            disabled={formik.isSubmitting || loading || villages.loading}
            value={formik.values.addressRegistration.villageId}
            label={`เลือกหมู่บ้าน`}
            onChange={formik.handleChange}
            name={`addressRegistration.villageId`}
            error={
              formik.touched.addressRegistration?.villageId &&
              Boolean(formik.errors.addressRegistration?.villageId)
            }
            helperText={
              formik.touched.addressRegistration?.villageId &&
              formik.errors.addressRegistration?.villageId
            }
            select
          >
            {villages?.data.map((item: VillageInterface) => (
              <MenuItem key={item._id} value={item._id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={8}>
          <TextField
            fullWidth
            disabled={formik.isSubmitting || loading}
            value={formik.values.addressRegistration.address}
            label={`ที่อยู่`}
            onChange={formik.handleChange}
            name={`addressRegistration.address`}
            error={
              formik.touched.addressRegistration?.address &&
              Boolean(formik.errors.addressRegistration?.address)
            }
            helperText={
              formik.touched.addressRegistration?.address &&
              formik.errors.addressRegistration?.address
            }
          />
        </Grid>
        <AddressAuto
          formik={formik}
          disabled={formik.isSubmitting || loading}
          name={{
            province: `addressRegistration.province`,
            district: `addressRegistration.district`,
            subdistrict: `addressRegistration.subdistrict`,
          }}
          value={{
            province: formik.values.addressRegistration.province,
            district: formik.values.addressRegistration.district,
            subdistrict: formik.values.addressRegistration.subdistrict,
          }}
          touched={{
            province: formik.touched.addressRegistration?.province,
            district: formik.touched.addressRegistration?.district,
            subdistrict: formik.touched.addressRegistration?.subdistrict,
          }}
          errors={{
            province: formik.errors.addressRegistration?.province,
            district: formik.errors.addressRegistration?.district,
            subdistrict: formik.errors.addressRegistration?.subdistrict,
          }}
        />
        {/*<Grid item xs={12} lg={4}>*/}
        {/*  <TextField*/}
        {/*    fullWidth*/}
        {/*    disabled={formik.isSubmitting || loading}*/}
        {/*    value={formik.values.addressRegistration.subdistrict}*/}
        {/*    label={`ตำบล`}*/}
        {/*    onChange={formik.handleChange}*/}
        {/*    name={`addressRegistration.subdistrict`}*/}
        {/*    error={*/}
        {/*      formik.touched.addressRegistration?.subdistrict &&*/}
        {/*      Boolean(formik.errors.addressRegistration?.subdistrict)*/}
        {/*    }*/}
        {/*    helperText={*/}
        {/*      formik.touched.addressRegistration?.subdistrict &&*/}
        {/*      formik.errors.addressRegistration?.subdistrict*/}
        {/*    }*/}
        {/*  />*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12} lg={4}>*/}
        {/*  <TextField*/}
        {/*    fullWidth*/}
        {/*    disabled={formik.isSubmitting || loading}*/}
        {/*    value={formik.values.addressRegistration.district}*/}
        {/*    label={`อำเภอ`}*/}
        {/*    onChange={formik.handleChange}*/}
        {/*    name={`addressRegistration.district`}*/}
        {/*    error={*/}
        {/*      formik.touched.addressRegistration?.district &&*/}
        {/*      Boolean(formik.errors.addressRegistration?.district)*/}
        {/*    }*/}
        {/*    helperText={*/}
        {/*      formik.touched.addressRegistration?.district &&*/}
        {/*      formik.errors.addressRegistration?.district*/}
        {/*    }*/}
        {/*  />*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12} lg={4}>*/}
        {/*  <TextField*/}
        {/*    fullWidth*/}
        {/*    disabled={formik.isSubmitting || loading}*/}
        {/*    value={formik.values.addressRegistration.province}*/}
        {/*    label={`จังหวัด`}*/}
        {/*    onChange={formik.handleChange}*/}
        {/*    name={`addressRegistration.province`}*/}
        {/*    error={*/}
        {/*      formik.touched.addressRegistration?.province &&*/}
        {/*      Boolean(formik.errors.addressRegistration?.province)*/}
        {/*    }*/}
        {/*    helperText={*/}
        {/*      formik.touched.addressRegistration?.province &&*/}
        {/*      formik.errors.addressRegistration?.province*/}
        {/*    }*/}
        {/*  />*/}
        {/*</Grid>*/}
      </Grid>

      <Box sx={{ pt: 4 }}>
        <Grid container alignItems={`center`} spacing={2}>
          <Grid item xs={6}>
            <Typography variant={`h6`}>ข้อมูลที่อยู่ปัจจุบัน</Typography>
          </Grid>
          <Grid item xs={6} justifyContent={`end`} textAlign={`end`}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.sameRegisterAddress}
                  onChange={() => handleSetSameAddress()}
                />
              }
              label="เหมือนที่อยู่ตามทะเบียนราษฏร์"
            />
          </Grid>
        </Grid>
      </Box>
      <Grid container mt={0} spacing={2}>
        <Grid item xs={12} lg={4}>
          <TextField
            fullWidth
            disabled={
              formik.isSubmitting ||
              loading ||
              formik.values.sameRegisterAddress ||
              villages.loading
            }
            value={formik.values.addressCurrent.villageId}
            label={`เลือกหมู่บ้าน`}
            onChange={formik.handleChange}
            name={`addressCurrent.villageId`}
            error={
              formik.touched.addressCurrent?.villageId &&
              Boolean(formik.errors.addressCurrent?.villageId)
            }
            helperText={
              formik.touched.addressCurrent?.villageId &&
              formik.errors.addressCurrent?.villageId
            }
            select
          >
            {villages?.data.map((item: VillageInterface) => (
              <MenuItem key={item._id} value={item._id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={8}>
          <TextField
            fullWidth
            disabled={
              formik.isSubmitting ||
              loading ||
              formik.values.sameRegisterAddress
            }
            value={formik.values.addressCurrent.address}
            label={`ที่อยู่`}
            onChange={formik.handleChange}
            name={`addressCurrent.address`}
            error={
              formik.touched.addressCurrent?.address &&
              Boolean(formik.errors.addressCurrent?.address)
            }
            helperText={
              formik.touched.addressCurrent?.address &&
              formik.errors.addressCurrent?.address
            }
          />
        </Grid>
        <AddressAuto
          formik={formik}
          disabled={
            formik.isSubmitting || loading || formik.values.sameRegisterAddress
          }
          name={{
            province: `addressCurrent.province`,
            district: `addressCurrent.district`,
            subdistrict: `addressCurrent.subdistrict`,
          }}
          value={{
            province: formik.values.addressCurrent.province,
            district: formik.values.addressCurrent.district,
            subdistrict: formik.values.addressCurrent.subdistrict,
          }}
          touched={{
            province: formik.touched.addressCurrent?.province,
            district: formik.touched.addressCurrent?.district,
            subdistrict: formik.touched.addressCurrent?.subdistrict,
          }}
          errors={{
            province: formik.errors.addressCurrent?.province,
            district: formik.errors.addressCurrent?.district,
            subdistrict: formik.errors.addressCurrent?.subdistrict,
          }}
        />
        {/*<Grid item xs={12} lg={4}>*/}
        {/*  <TextField*/}
        {/*    fullWidth*/}
        {/*    disabled={*/}
        {/*      formik.isSubmitting ||*/}
        {/*      loading ||*/}
        {/*      formik.values.sameRegisterAddress*/}
        {/*    }*/}
        {/*    value={formik.values.addressCurrent.subdistrict}*/}
        {/*    label={`ตำบล`}*/}
        {/*    onChange={formik.handleChange}*/}
        {/*    name={`addressCurrent.subdistrict`}*/}
        {/*    error={*/}
        {/*      formik.touched.addressCurrent?.subdistrict &&*/}
        {/*      Boolean(formik.errors.addressCurrent?.subdistrict)*/}
        {/*    }*/}
        {/*    helperText={*/}
        {/*      formik.touched.addressCurrent?.subdistrict &&*/}
        {/*      formik.errors.addressCurrent?.subdistrict*/}
        {/*    }*/}
        {/*  />*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12} lg={4}>*/}
        {/*  <TextField*/}
        {/*    fullWidth*/}
        {/*    disabled={*/}
        {/*      formik.isSubmitting ||*/}
        {/*      loading ||*/}
        {/*      formik.values.sameRegisterAddress*/}
        {/*    }*/}
        {/*    value={formik.values.addressCurrent.district}*/}
        {/*    label={`อำเภอ`}*/}
        {/*    onChange={formik.handleChange}*/}
        {/*    name={`addressCurrent.district`}*/}
        {/*    error={*/}
        {/*      formik.touched.addressCurrent?.district &&*/}
        {/*      Boolean(formik.errors.addressCurrent?.district)*/}
        {/*    }*/}
        {/*    helperText={*/}
        {/*      formik.touched.addressCurrent?.district &&*/}
        {/*      formik.errors.addressCurrent?.district*/}
        {/*    }*/}
        {/*  />*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12} lg={4}>*/}
        {/*  <TextField*/}
        {/*    fullWidth*/}
        {/*    disabled={*/}
        {/*      formik.isSubmitting ||*/}
        {/*      loading ||*/}
        {/*      formik.values.sameRegisterAddress*/}
        {/*    }*/}
        {/*    value={formik.values.addressCurrent.province}*/}
        {/*    label={`จังหวัด`}*/}
        {/*    onChange={formik.handleChange}*/}
        {/*    name={`addressCurrent.province`}*/}
        {/*    error={*/}
        {/*      formik.touched.addressCurrent?.province &&*/}
        {/*      Boolean(formik.errors.addressCurrent?.province)*/}
        {/*    }*/}
        {/*    helperText={*/}
        {/*      formik.touched.addressCurrent?.province &&*/}
        {/*      formik.errors.addressCurrent?.province*/}
        {/*    }*/}
        {/*  />*/}
        {/*</Grid>*/}
      </Grid>

      <Box sx={{ pt: 4 }}>
        <Typography variant={`h6`}>ตำแหน่งที่อยู่</Typography>
      </Box>
      <Grid container mt={0} spacing={2}>
        <Grid item xs={12} lg={6}>
          <MapMarker
            height={350}
            position={
              isUpdate
                ? {
                    lat: formik.values.addressPosition?.latitude,
                    lng: formik.values.addressPosition?.longitude,
                  }
                : {}
            }
            handleLocation={handleLocation}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={formik.isSubmitting || loading}
                name="addressPosition.latitude"
                label="Latitude"
                value={formik.values.addressPosition?.latitude}
                onChange={formik.handleChange}
                error={
                  formik.touched.addressPosition?.latitude &&
                  Boolean(formik.errors.addressPosition?.latitude)
                }
                helperText={
                  formik.touched.addressPosition?.latitude &&
                  formik.errors.addressPosition?.latitude
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={formik.isSubmitting || loading}
                name="addressPosition.longitude"
                label="Longitude"
                value={formik.values.addressPosition?.longitude}
                onChange={formik.handleChange}
                error={
                  formik.touched.addressPosition?.longitude &&
                  Boolean(formik.errors.addressPosition?.longitude)
                }
                helperText={
                  formik.touched.addressPosition?.longitude &&
                  formik.errors.addressPosition?.longitude
                }
              />
            </Grid>
            <Grid item xs={12} justifyContent={`end`} textAlign={`end`}>
              <LoadingButton
                loading={formik.isSubmitting || loading}
                disabled={formik.isSubmitting || loading}
                type={"submit"}
                size={`small`}
                variant={`contained`}
                color={`primary`}
                startIcon={<Save />}
              >
                บันทึก
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
