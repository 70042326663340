import { Box, Button, Card } from "@mui/material";
import PageHeader from "../../components/PageHeader";
import { PlusOutlined } from "@ant-design/icons";
import CustomDialog from "../../components/CustomDialog";
import { useDialog } from "../../hooks/useDialog";
import CustomSearchTableList from "../../components/CustomSearchTableList";
import Form from "./Conmponents/Modal/Form";
import TableLists from "./Features/TableLists";
import { useSearch } from "../../hooks/useSearch";
import { useFetchPersonals } from "../../hooks/usePersonal";
import { useCallback, useContext, useEffect } from "react";
import { AbilityContext } from "../../components/Ability";
import { FiDownload } from "react-icons/fi";
import { Modal } from "antd";
import { downloadExcel } from "../../../utils/excel";
import TablePaginate from "../../components/TablePaginate";

const Personal = () => {
  const ability = useContext(AbilityContext);
  const {
    handleRefresh,
    handleFiltersChange,
    state,
    handleSetPage,
    handleSetPerPage,
  } = useSearch();
  const data = useFetchPersonals(state);
  const dialog = useDialog();

  const handleDownload = useCallback(async () => {
    try {
      await downloadExcel(state.filters, "personal");
    } catch (e) {
      console.log(e);
      Modal.error({
        title: "เกิดข้อผิดพลาด",
        content: "ไม่สามารถดาวน์โหลดได้",
      });
    }
  }, [state]);

  return (
    <>
      <PageHeader
        title={`ข้อมูลบุคคล`}
        routes={[{ title: `ข้อมูลบุคคล` }]}
        createButton={
          <>
            {ability.can("create", "userInfo") && (
              <>
                <Button
                  onClick={() => dialog.handleOpen({ open: true })}
                  startIcon={<PlusOutlined />}
                  variant="contained"
                >
                  สร้างข้อมูลบุคคลใหม่
                </Button>
                <CustomDialog
                  size={"md"}
                  title="สร้างข้อมูลบุคคลใหม่"
                  onClose={dialog.handleClose}
                  open={dialog.open}
                >
                  <Form dialog={dialog} onRefresh={handleRefresh} />
                </CustomDialog>
              </>
            )}
            <Button
              onClick={() => handleDownload()}
              startIcon={<FiDownload />}
              variant="contained"
              color={"success"}
            >
              ดาวน์โหลด Excel
            </Button>
          </>
        }
      />
      <Box sx={{ flexGrow: 1 }}>
        <Card>
          <CustomSearchTableList
            placeholder={`ค้นหา`}
            onFiltersChange={handleFiltersChange}
          />
          <TableLists
            count={data.count}
            items={data.data}
            loading={data.loading}
            onRefresh={handleRefresh}
            page={data.page}
          />
        </Card>
      </Box>
      <Box py={2}>
        <TablePaginate
          handleSetPage={handleSetPage}
          handleSetPerPage={handleSetPerPage}
          count={data.count}
          perPage={data.perPage}
          currentPage={data.page}
        />
      </Box>
    </>
  );
};

export default Personal;
