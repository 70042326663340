import { useContext } from "react";
import { AbilityContext } from "../../components/Ability";
import { Outlet } from "react-router-dom";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import sizeConfigs from "../../../theme/sidebar/sizeConfig";
import colorConfigs from "../../../theme/sidebar/colorConfig";
import MenuIcon from "@mui/icons-material/Menu";
import AccountPopupOver from "../../components/AccountPopupOver";
import TopNav from "../../components/TopNav";
import { useMobileNav } from "../../hooks/useMobileNav";
import SidebarNav from "../../components/Sidebar/SidebarNav";
import { styled } from "@mui/material/styles";
import useSections from "../../../routes/section";
import MobileNav from "../../components/Sidebar/Mobile/MobileNav";

const Topbar = ({
  handleToggleCollapse,
  collapsed,
}: {
  handleToggleCollapse: any;
  collapsed: boolean;
}) => {
  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${
          collapsed
            ? sizeConfigs.sidebar.widthCollapsed
            : sizeConfigs.sidebar.width
        })`,
        ml: sizeConfigs.sidebar.width,
        boxShadow: "unset",
        backgroundColor: colorConfigs.topbar.bg,
        color: colorConfigs.topbar.color,
      }}
    >
      <Toolbar
        sx={{
          display: `flex`,
          justifyContent: `space-between`,
          alignItems: `center`,
        }}
      >
        <Typography variant="h6">
          <IconButton onClick={() => handleToggleCollapse()}>
            <MenuIcon />
          </IconButton>
        </Typography>
        <AccountPopupOver />
      </Toolbar>
    </AppBar>
  );
};

export const DashboardLayout = () => {
  const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up("lg"));
  const ability = useContext(AbilityContext);

  const mobileNav = useMobileNav();
  const sections = useSections();

  return (
    <>
      <TopNav onMobileNavOpen={mobileNav.handleOpen} />

      {lgUp ? (
        <SidebarNav sections={sections} />
      ) : (
        <MobileNav
          sections={sections}
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
        />
      )}

      <VerticalLayoutRoot>
        <VerticalLayoutContainer>
          <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
            <Container maxWidth="xl">
              <Stack spacing={4}>
                <Outlet />
              </Stack>
            </Container>
          </Box>
        </VerticalLayoutContainer>
      </VerticalLayoutRoot>
    </>
  );
};

const VerticalLayoutRoot = styled("section")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 280,
  },
}));

const VerticalLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});
